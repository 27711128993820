import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import * as constants from 'redux/constants/roles.constants';
import { getAll, getForm, getOne, save, softDelete } from 'services/roles.services';

export function* rolesDelete(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    const { data }: any = yield call(softDelete, id);
    yield put({
      type: constants.ROLES_ON_DELETE_SUCCEEDED,
      data: { ...data, id },
    });
  } catch (error) {
    yield put({ type: constants.ROLES_ON_DELETE_FAILED, error });
  }
}

export function* rolesGetAll(action: AnyAction): Generator {
  try {
    const { limit, page, search, sortKey, sortType } = action;
    const { data }: any = yield call(getAll, limit, page, search, sortKey, sortType);
    yield put({ type: constants.ROLES_ON_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.ROLES_ON_GET_ALL_FAILED, error });
  }
}

export function* rolesGetForm(): Generator {
  try {
    const { data }: any = yield call(getForm);
    yield put({ type: constants.ROLES_ON_GET_FORM_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.ROLES_ON_GET_FORM_FAILED, error });
  }
}

export function* rolesGetOne(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    if (id) {
      const { data }: any = yield call(getOne, id);
      yield put({ type: constants.ROLES_ON_GET_ONE_SUCCEEDED, data });
    }
  } catch (error) {
    yield put({ type: constants.ROLES_ON_GET_ONE_FAILED, error });
  }
}

export function* rolesSave(action: AnyAction): Generator {
  try {
    const { formData, id } = action;
    const { data }: any = yield call(save, formData, id);
    yield put({ type: constants.ROLES_ON_SAVE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.ROLES_ON_SAVE_FAILED, error });
  }
}

export function* rolesRefresh(): Generator {
  const table: any = yield select((state) => state.roles.table);
  yield put({ type: constants.ROLES_ON_GET_ALL_REQUESTED, ...table });
}

export function* watchRoles(): Generator {
  yield all([
    takeLatest(constants.ROLES_ON_DELETE_REQUESTED, rolesDelete),
    takeLatest(constants.ROLES_ON_GET_ALL_REQUESTED, rolesGetAll),
    takeLatest(constants.ROLES_ON_GET_FORM_REQUESTED, rolesGetForm),
    takeLatest(constants.ROLES_ON_GET_ONE_REQUESTED, rolesGetOne),
    takeLatest(constants.ROLES_ON_SAVE_REQUESTED, rolesSave),
    takeLatest(
      [constants.ROLES_ON_DELETE_SUCCEEDED, constants.ROLES_ON_SAVE_SUCCEEDED],
      rolesRefresh,
    ),
  ]);
}
