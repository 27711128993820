import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'BLOCKS_VIEW', {
  TITLE: 'Blocks',
  NAME: 'Name',
  ACTIONS: 'Actions',
  SHOW: 'Show',
  EDIT: 'Edit',
  DELETE: 'Delete',
  DELETE_DIALOG_TITLE: 'Delete block',
  DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete the block {{ name }}?',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'BLOCKS_VIEW', {
  TITLE: 'Manzanas',
  NAME: 'Nombre',
  ACTIONS: 'Acciones',
  SHOW: 'Ver',
  EDIT: 'Editar',
  DELETE: 'Eliminar',
  DELETE_DIALOG_TITLE: 'Eliminar manzana',
  DELETE_DIALOG_MESSAGE: 'Estás seguro de querer eliminar la manzana {{ name }}?',
});
