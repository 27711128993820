import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { proceduresShowSelector } from 'redux/selectors/procedures.selector';
import { onGetOne } from 'redux/actions/procedures.actions';

import LayoutContainer from 'containers/LayoutContainer';
import ProceduresShow from 'views/ProceduresView/components/ProceduresShow';
import Loading from 'components/commons/Loading';

import 'views/ProceduresView/components/ProceduresShow/i18n';

const ProceduresShowContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id = '' } = useParams<{ id?: string }>();
  const { loading } = useTypedSelector((state) => state.procedures);
  const item = useTypedSelector(proceduresShowSelector);

  useEffect(() => {
    if (id) dispatch(onGetOne(id));
    return () => {
      dispatch(onGetOne(''));
    };
  }, [dispatch, id]);

  return (
    <LayoutContainer auth title={i18next.t<string>('PROCEDURES_SHOW:TITLE')}>
      <ProceduresShow item={item} />
      {loading && <Loading isModalMode message={i18next.t<string>('PROCEDURES_SHOW:LOADING')} />}
    </LayoutContainer>
  );
};

export default ProceduresShowContainer;
