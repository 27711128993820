import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

export const FormGroup = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 24px;

  .MuiFormControlLabel-root {
    font-family: 'Montserrat', Helvetica, Arial, serif;
    margin-left: 0;
  }
`;

export const ErrorBlock = styled.div`
  width: 100%;
  position: absolute;
  color: ${COLORS.red};
  font-size: 12px;
  line-height: 1.5;
  text-align: left;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const HelpText = styled.div`
  width: 100%;
  margin-top: 8px;
  color: ${COLORS.grayLynch};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  &::first-letter {
    text-transform: uppercase;
  }
`;
