import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { IGravesFormData } from 'views/GravesView/components/GravesForm/types';
import { onGetOne as onGetOneCementery } from 'redux/actions/cementeries.actions';
import { onGetOne as onGetOneBlock } from 'redux/actions/blocks.actions';
import { onDelete, onGetForm, onGetOne, onSave } from 'redux/actions/graves.actions';
import { gravesFormSelector, gravesStatesSelector } from 'redux/selectors/graves.selector';

import LayoutContainer from 'containers/LayoutContainer';
import GravesForm from 'views/GravesView/components/GravesForm';
import Dialog from 'components/commons/Dialog';
import Loading from 'components/commons/Loading';

import 'views/GravesView/components/GravesForm/i18n';

const GravesFormContainer: FunctionComponent = () => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const {
    cementeryId = '',
    blockId = '',
    id = '',
  } = useParams<{
    cementeryId?: string;
    blockId?: string;
    id?: string;
  }>();
  const initialValues = useTypedSelector(gravesFormSelector);
  const { loading } = useTypedSelector((state) => state.graves);
  const block = useTypedSelector((state) => state.blocks.selectedItem);
  const states = useTypedSelector(gravesStatesSelector);
  const isEditing = !!id;

  useEffect(() => {
    if (cementeryId) dispatch(onGetOneCementery(cementeryId));
    return () => {
      dispatch(onGetOneCementery(''));
    };
  }, [dispatch, cementeryId]);

  useEffect(() => {
    if (blockId) dispatch(onGetOneBlock(cementeryId, blockId));
    return () => {
      dispatch(onGetOneBlock(cementeryId, ''));
    };
  }, [dispatch, cementeryId, blockId]);

  useEffect(() => {
    dispatch(onGetForm(cementeryId, blockId));
    if (id) dispatch(onGetOne(cementeryId, blockId, id));
    return () => {
      dispatch(onGetOne(cementeryId, blockId, ''));
    };
  }, [dispatch, cementeryId, blockId, id]);

  const onSubmit = (formData: IGravesFormData) =>
    dispatch(onSave(cementeryId, blockId, formData, id));

  const onDeleteClick = (isConfirmed = false) => {
    if (isConfirmed && isEditing) dispatch(onDelete(cementeryId, blockId, id));
    setShowDeleteModal(false);
  };

  return (
    <LayoutContainer auth title={block?.name || i18next.t<string>('GRAVES_FORM:TITLE')}>
      <GravesForm
        initialValues={initialValues}
        isEditing={isEditing}
        onDelete={() => setShowDeleteModal(true)}
        onSubmit={onSubmit}
        states={states}
      />
      <Dialog
        message={i18next.t<string>('GRAVES_FORM:DELETE_DIALOG_MESSAGE')}
        onCancelClick={() => onDeleteClick()}
        onConfirmClick={() => onDeleteClick(true)}
        open={showDeleteModal}
        title={i18next.t<string>('GRAVES_FORM:DELETE_DIALOG_TITLE')}
      />
      {loading && <Loading isModalMode message={i18next.t<string>('GRAVES_FORM:LOADING')} />}
    </LayoutContainer>
  );
};

export default GravesFormContainer;
