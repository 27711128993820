import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'TABLE', {
  SEARCH_PLACEHOLDER: 'Search...',
  CREATE: 'Create',
  PREVIOUS: 'Previous',
  NEXT: 'Next',
  FETCHING_DATA: 'Fetching data...',
  NO_MATCH: 'Sorry, no matching records found',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'TABLE', {
  SEARCH_PLACEHOLDER: 'Buscar...',
  CREATE: 'Crear',
  PREVIOUS: 'Anterior',
  NEXT: 'Siguiente',
  FETCHING_DATA: 'Obteniendo datos...',
  NO_MATCH: 'Lo sentimos, no se encontraron registros disponibles',
});
