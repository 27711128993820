import React, { FunctionComponent } from 'react';
import { Redirect, Switch, SwitchProps } from 'react-router-dom';

import { ROLES } from 'helpers/roles';

import Route from 'containers/RouteContainer';
import LoginContainer from 'containers/LoginContainer';
import BlocksContainer from 'containers/BlocksContainer';
import BlocksFormContainer from 'containers/BlocksFormContainer';
import BlocksShowContainer from 'containers/BlocksShowContainer';
import CategoryPricesContainer from 'containers/CategoryPricesContainer';
import CategoryPricesFormContainer from 'containers/CategoryPricesFormContainer';
import CementeriesContainer from 'containers/CementeriesContainer';
import CementeriesFormContainer from 'containers/CementeriesFormContainer';
import DashboardContainer from 'containers/DashboardContainer';
import DocumentsContainer from 'containers/DocumentsContainer';
import DocumentsFormContainer from 'containers/DocumentsFormContainer';
import DocumentsShowContainer from 'containers/DocumentsShowContainer';
import DocumentsTypesContainer from 'containers/DocumentsTypesContainer';
import DocumentsTypesFormContainer from 'containers/DocumentsTypesFormContainer';
import DocumentsTypesShowContainer from 'containers/DocumentsTypesShowContainer';
import GravesFormContainer from 'containers/GravesFormContainer';
import PermissionsContainer from 'containers/PermissionsContainer';
import PermissionsFormContainer from 'containers/PermissionsFormContainer';
import PersonsContainer from 'containers/PersonsContainer';
import PersonsFormContainer from 'containers/PersonsFormContainer';
import PersonsShowContainer from 'containers/PersonsShowContainer';
import ProceduresContainer from 'containers/ProceduresContainer';
import ProceduresFormContainer from 'containers/ProceduresFormContainer';
import ProceduresShowContainer from 'containers/ProceduresShowContainer';
import ProceduresUrlFormContainer from 'containers/ProceduresUrlFormContainer';
import ProceduresUrlFormSuccess from 'views/ProceduresView/components/ProceduresUrlFormSuccess';
import RolesContainer from 'containers/RolesContainer';
import RolesFormContainer from 'containers/RolesFormContainer';
import UsersContainer from 'containers/UsersContainer';
import UsersFormContainer from 'containers/UsersFormContainer';
import ErrorView from 'views/ErrorView';

const NavigationContainer: FunctionComponent<SwitchProps> = () => (
  <Switch>
    <Redirect exact from="/" to="/dashboard" />
    {/* Public Routes */}
    <Route exact path="/login" component={LoginContainer} restricted />
    <Route exact path="/procedures/url/:id" component={ProceduresUrlFormContainer} restricted />
    <Route
      exact
      path="/procedures/url/:id/success"
      component={ProceduresUrlFormSuccess}
      restricted
    />
    {/* Private Routes */}
    <Route
      exact
      path="/category-prices"
      component={CategoryPricesContainer}
      roles={[ROLES.superadmin, ROLES.directorio]}
      authenticated
    />
    <Route
      exact
      path="/category-prices/create"
      component={CategoryPricesFormContainer}
      roles={[ROLES.superadmin, ROLES.directorio]}
      authenticated
    />
    <Route
      exact
      path="/category-prices/:id/edit"
      component={CategoryPricesFormContainer}
      roles={[ROLES.superadmin, ROLES.directorio]}
      authenticated
    />
    <Route
      exact
      path="/cementeries"
      component={CementeriesContainer}
      roles={[ROLES.superadmin, ROLES.directorio]}
      authenticated
    />
    <Route
      exact
      path="/cementeries/create"
      component={CementeriesFormContainer}
      roles={[ROLES.superadmin, ROLES.directorio]}
      authenticated
    />
    <Route
      exact
      path="/cementeries/:id/edit"
      component={CementeriesFormContainer}
      roles={[ROLES.superadmin, ROLES.directorio]}
      authenticated
    />
    <Route
      exact
      path="/cementeries/:cementeryId/blocks"
      component={BlocksContainer}
      roles={[ROLES.superadmin, ROLES.directorio]}
      authenticated
    />
    <Route
      exact
      path="/cementeries/:cementeryId/blocks/create"
      component={BlocksFormContainer}
      roles={[ROLES.superadmin, ROLES.directorio]}
      authenticated
    />
    <Route
      exact
      path="/cementeries/:cementeryId/blocks/:id/edit"
      component={BlocksFormContainer}
      roles={[ROLES.superadmin, ROLES.directorio]}
      authenticated
    />
    <Route
      exact
      path="/cementeries/:cementeryId/blocks/:id/show"
      component={BlocksShowContainer}
      roles={[ROLES.superadmin, ROLES.directorio]}
      authenticated
    />
    <Route
      exact
      path="/cementeries/:cementeryId/blocks/:blockId/graves/create"
      component={GravesFormContainer}
      roles={[ROLES.superadmin, ROLES.directorio]}
      authenticated
    />
    <Route
      exact
      path="/cementeries/:cementeryId/blocks/:blockId/graves/:id/edit"
      component={GravesFormContainer}
      roles={[ROLES.superadmin, ROLES.directorio]}
      authenticated
    />
    <Route exact path="/dashboard" component={DashboardContainer} authenticated />
    <Route
      exact
      path="/documents/list"
      component={DocumentsContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route
      exact
      path="/documents/create"
      component={DocumentsFormContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route
      exact
      path="/documents/:id/edit"
      component={DocumentsFormContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route
      exact
      path="/documents/:id/show"
      component={DocumentsShowContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route
      exact
      path="/documents/types/list"
      component={DocumentsTypesContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route
      exact
      path="/documents/types/create"
      component={DocumentsTypesFormContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route
      exact
      path="/documents/types/:id/edit"
      component={DocumentsTypesFormContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route
      exact
      path="/documents/types/:id/show"
      component={DocumentsTypesShowContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route
      exact
      path="/persons"
      component={PersonsContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route
      exact
      path="/persons/create"
      component={PersonsFormContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route
      exact
      path="/persons/:id/edit"
      component={PersonsFormContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route
      exact
      path="/persons/:id/show"
      component={PersonsShowContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route
      exact
      path="/procedures"
      component={ProceduresContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route
      exact
      path="/procedures/create"
      component={ProceduresFormContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route
      exact
      path="/procedures/:id/edit"
      component={ProceduresFormContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route
      exact
      path="/procedures/:id/show"
      component={ProceduresShowContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route
      exact
      path="/users/list"
      component={UsersContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route
      exact
      path="/users/create"
      component={UsersFormContainer}
      roles={[ROLES.superadmin, ROLES.rabinato]}
      authenticated
    />
    <Route exact path="/users/:id/edit" component={UsersFormContainer} authenticated />
    <Route
      exact
      path="/users/permissions/list"
      component={PermissionsContainer}
      roles={[ROLES.superadmin]}
      authenticated
    />
    <Route
      exact
      path="/users/permissions/create"
      component={PermissionsFormContainer}
      roles={[ROLES.superadmin]}
      authenticated
    />
    <Route
      exact
      path="/users/permissions/:id/edit"
      component={PermissionsFormContainer}
      roles={[ROLES.superadmin]}
      authenticated
    />
    <Route
      exact
      path="/users/roles/list"
      component={RolesContainer}
      roles={[ROLES.superadmin]}
      authenticated
    />
    <Route
      exact
      path="/users/roles/create"
      component={RolesFormContainer}
      roles={[ROLES.superadmin]}
      authenticated
    />
    <Route
      exact
      path="/users/roles/:id/edit"
      component={RolesFormContainer}
      roles={[ROLES.superadmin]}
      authenticated
    />
    {/* Default Route */}
    <Route component={ErrorView} />
  </Switch>
);

export default NavigationContainer;
