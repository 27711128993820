import { createSelector } from 'reselect';

import { IUsersState } from 'types/users.types';

const getItems = (state: { users: IUsersState }) => state.users.items;
const getSelectedItem = (state: { users: IUsersState }) => state.users.selectedItem;
const getRoles = (state: { users: IUsersState }) => state.users.roles;

export const usersSelector = createSelector([getItems], (users) =>
  users.map((user) => ({
    ...user,
    name: `${user.firstName} ${user.lastName}`,
  })),
);

export const usersFormSelector = createSelector([getSelectedItem], (user) => ({
  firstName: user?.firstName || '',
  lastName: user?.lastName || '',
  email: user?.email || '',
  roles: user?.roles?.map((role) => role.name) || [],
}));

export const usersRolesSelector = createSelector([getRoles], (roles) =>
  roles.map((role) => ({
    label: role.name,
    value: role.name,
  })),
);
