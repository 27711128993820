import React, { ChangeEvent, FunctionComponent } from 'react';
import { InputAdornment } from '@mui/material';

import { ReactComponent as SearchIcon } from './assets/searchIcon.svg';

import { ISearchInputProps } from './types';
import { FormGroup, CustomInput } from './styles';

const SearchInput: FunctionComponent<ISearchInputProps> = (props: ISearchInputProps) => {
  const { disabled = false, input, size = 'small', variant = 'filled' } = props;
  const { name, onChange, placeholder } = input;

  return (
    <FormGroup>
      <CustomInput
        {...input}
        disabled={disabled}
        id={name}
        onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
        size={size}
        data-test-id="search"
        placeholder={placeholder}
        variant={variant}
        type="search"
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </FormGroup>
  );
};

export default SearchInput;
