import styled from 'styled-components';
import { Menu as MUIMenu, MenuItem as MUIMenuItem } from '@mui/material';

import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const ActionButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${COLORS.grayLynch};

  button {
    padding: 0;
  }

  @media (max-width: ${SIZES.muiTableChange}px) {
    justify-content: flex-start;
  }
`;

export const Menu = styled(MUIMenu)`
  && {
    .MuiPaper-root {
      padding: 16px;
      gap: 8px;
      background: ${COLORS.white};

      border: 1px solid ${COLORS.grayIron};
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
    }

    .MuiList-root {
      display: flex;
      flex-direction: column;
      padding: 0;
      gap: 8px;

      li {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
`;

export const MenuItem = styled(MUIMenuItem)`
  && {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${({ color }) => color || COLORS.grayCloudBurst};
  }
`;
