import React, { FunctionComponent, MouseEvent, useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';

import { COLORS } from 'helpers/enums/colors';

import { INavbarProps } from './types';
import {
  NavbarContainer,
  NavbarContent,
  NavbarMenuButton,
  NavbarAccountButton,
  NavbarProfileAvatar,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuItemLabel,
} from './styles';

const Navbar: FunctionComponent<INavbarProps> = (props: INavbarProps) => {
  const { initials, items, onOpenMenu, title } = props;
  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);
  const EndIcon = !menuElement ? KeyboardArrowDown : KeyboardArrowUp;

  const onCloseAccountMenu = () => setMenuElement(null);

  return (
    <NavbarContainer auth data-testid="navbar">
      <NavbarContent auth>
        <NavbarMenuButton data-testid="navbar-menu-button" onClick={onOpenMenu} />
        {title}
        <NavbarAccountButton
          aria-haspopup="true"
          data-testid="navbar-account-button"
          endIcon={<EndIcon color="action" />}
          onClick={(event: MouseEvent<HTMLButtonElement>) => setMenuElement(event.currentTarget)}
        >
          <NavbarProfileAvatar alt={initials}>{initials}</NavbarProfileAvatar>
        </NavbarAccountButton>
        <NavbarMenu anchorEl={menuElement} onClose={onCloseAccountMenu} open={!!menuElement}>
          {items.map(({ Icon, key, label, onClick }) => (
            <NavbarMenuItem
              data-testid={`navbar-menu-item-${key}`}
              key={key}
              onClick={() => {
                onClick();
                onCloseAccountMenu();
              }}
            >
              {Icon && <Icon fontSize="small" htmlColor={COLORS.grayScorpion} />}
              <NavbarMenuItemLabel>{label}</NavbarMenuItemLabel>
            </NavbarMenuItem>
          ))}
        </NavbarMenu>
      </NavbarContent>
    </NavbarContainer>
  );
};

export default Navbar;
