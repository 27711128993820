import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import LoginForm from 'views/LoginView/components/LoginForm';

import { ViewContainer, ViewContent, Title } from 'styles/view';

import { ILoginViewProps } from './types';
import './i18n';

const LoginView: FunctionComponent<ILoginViewProps> = (props: ILoginViewProps) => {
  const { onSubmit } = props;

  return (
    <ViewContainer data-testid="login-view">
      <ViewContent>
        <Title>{i18next.t<string>('LOGIN_VIEW:TITLE')}</Title>
        <LoginForm onSubmit={onSubmit} />
      </ViewContent>
    </ViewContainer>
  );
};

export default LoginView;
