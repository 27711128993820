import styled, { css } from 'styled-components';
import { TextField } from '@mui/material';

import { COLORS } from 'helpers/enums/colors';

export const CustomInput = styled(TextField)`
  && {
    width: 100%;
    height: 100%;
    margin-bottom: 0px !important;

    label {
      color: ${({ error }) => (error ? COLORS.redMonza : COLORS.grayManatee)};
      font-family: 'Montserrat', Helvetica, Arial, serif;
      font-size: 16px;
      left: 10px;
      padding-right: 50px;

      ${({ error, type }) =>
        type === 'file' &&
        css`
          color: ${error ? COLORS.redMonza : COLORS.blueRibbon};
          font-size: 12px;
          font-weight: 700;
          transform: translate(12px, 7px) scale(0.75);
        `}
    }

    .MuiFormLabel-root.Mui-focused,
    .MuiFormLabel-filled {
      color: ${({ error }) => (error ? COLORS.redMonza : COLORS.grayLynch)};
      font-size: 12px;
      font-weight: 700;
    }

    .MuiInputBase-input {
      padding: 18px 20px 8px;
      color: ${COLORS.grayCloudBurst};
      font-family: 'Montserrat', Helvetica, Arial, serif;
      font-size: 18px;
      border-radius: 6px;

      ${({ type }) =>
        type === 'textarea' &&
        css`
          padding: 0 0 0 8px;
        `}

      &:-webkit-autofill,
      &:-webkit-autofill:active,
      &:-webkit-autofill:hover,
      &:-webkit-autofill::first-line,
      &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px white inset !important;
        background-color: ${COLORS.white} !important;
        background-clip: content-box !important;
      }
    }

    .MuiFilledInput-root {
      background-color: ${COLORS.white};
      border: 2px solid ${({ error }) => (error ? COLORS.redMonza : COLORS.grayManatee)};
      border-radius: 6px;
      height: 100%;

      :before,
      :after {
        content: none;
      }
    }

    .MuiInputBase-input.Mui-disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;

  a {
    color: ${COLORS.blueRibbon};
  }
`;
