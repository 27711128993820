import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'BLOCKS_GRAVES_VIEW', {
  PRICE: 'Price',
  STATE: 'State',
  PERSON: 'Person',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'BLOCKS_GRAVES_VIEW', {
  PRICE: 'Precio',
  STATE: 'Estado',
  PERSON: 'Persona',
});
