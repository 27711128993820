import { createSelector } from 'reselect';

import { IRolesState } from 'types/roles.types';

const getItems = (state: { roles: IRolesState }) => state.roles.items;
const getSelectedItem = (state: { roles: IRolesState }) => state.roles.selectedItem;
const getPermissions = (state: { roles: IRolesState }) => state.roles.permissions;

export const rolesSelector = createSelector([getItems], (roles) => roles);

export const rolesFormSelector = createSelector([getSelectedItem], (role) => ({
  name: role?.name || '',
  guardName: role?.guardName || '',
  permissions: role?.permissions?.map((permission) => permission.name) || [],
}));

export const rolesPermissionsSelector = createSelector([getPermissions], (permissions) =>
  permissions.map((permission) => ({
    label: permission.name,
    value: permission.name,
  })),
);
