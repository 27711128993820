import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import * as constants from 'redux/constants/documents.constants';
import { getAll, getForm, getOne, save, softDelete } from 'services/documents.services';

export function* documentsDelete(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    const { data }: any = yield call(softDelete, id);
    yield put({
      type: constants.DOCUMENTS_ON_DELETE_SUCCEEDED,
      data: { ...data, id },
    });
  } catch (error) {
    yield put({ type: constants.DOCUMENTS_ON_DELETE_FAILED, error });
  }
}

export function* documentsGetAll(action: AnyAction): Generator {
  try {
    const { limit, page, search, sortKey, sortType } = action;
    const { data }: any = yield call(getAll, limit, page, search, sortKey, sortType);
    yield put({ type: constants.DOCUMENTS_ON_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.DOCUMENTS_ON_GET_ALL_FAILED, error });
  }
}

export function* documentsGetForm(): Generator {
  try {
    const { data }: any = yield call(getForm);
    yield put({ type: constants.DOCUMENTS_ON_GET_FORM_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.DOCUMENTS_ON_GET_FORM_FAILED, error });
  }
}

export function* documentsGetOne(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    if (id) {
      const { data }: any = yield call(getOne, id);
      yield put({ type: constants.DOCUMENTS_ON_GET_ONE_SUCCEEDED, data });
    }
  } catch (error) {
    yield put({ type: constants.DOCUMENTS_ON_GET_ONE_FAILED, error });
  }
}

export function* documentsSave(action: AnyAction): Generator {
  try {
    const { formData, id } = action;
    const { data }: any = yield call(save, formData, id);
    yield put({ type: constants.DOCUMENTS_ON_SAVE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.DOCUMENTS_ON_SAVE_FAILED, error });
  }
}

export function* documentsRefresh(): Generator {
  const table: any = yield select((state) => state.documents.table);
  yield put({ type: constants.DOCUMENTS_ON_GET_ALL_REQUESTED, ...table });
}

export function* watchDocuments(): Generator {
  yield all([
    takeLatest(constants.DOCUMENTS_ON_DELETE_REQUESTED, documentsDelete),
    takeLatest(constants.DOCUMENTS_ON_GET_ALL_REQUESTED, documentsGetAll),
    takeLatest(constants.DOCUMENTS_ON_GET_FORM_REQUESTED, documentsGetForm),
    takeLatest(constants.DOCUMENTS_ON_GET_ONE_REQUESTED, documentsGetOne),
    takeLatest(constants.DOCUMENTS_ON_SAVE_REQUESTED, documentsSave),
    takeLatest(
      [constants.DOCUMENTS_ON_DELETE_SUCCEEDED, constants.DOCUMENTS_ON_SAVE_SUCCEEDED],
      documentsRefresh,
    ),
  ]);
}
