import { AxiosResponse } from 'axios';

import { get, post, put, remove } from 'services/api';
import { IUsersFormData } from 'views/UsersView/components/UsersForm/types';

export const getAll = (
  limit: number,
  page: number,
  search: string,
  sortKey: string,
  sortType: string,
): Promise<AxiosResponse> =>
  get(`users?limit=${limit}&page=${page}&search=${search}&sortKey=${sortKey}&sortType=${sortType}`);

export const getForm = (): Promise<AxiosResponse> => get('users/form');

export const getOne = (id: string): Promise<AxiosResponse> => get(`users/${id}`);

export const save = (formData: IUsersFormData, id: string): Promise<AxiosResponse> =>
  !id ? post('users', formData) : put(`users/${id}`, formData);

export const softDelete = (id: string): Promise<AxiosResponse> => remove(`users/${id}`);
