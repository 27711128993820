import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'GRAVES_FORM', {
  TITLE: 'Graves',
  STATE: 'State',
  ROW: 'Row',
  COLUMN: 'Column',
  PRICE: 'Price',
  COLOR: 'Color',
  PERSON: 'Person',
  SUBMIT_BUTTON: 'Save',
  DELETE_DIALOG_TITLE: 'Delete grave',
  DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete the grave?',
  LOADING: 'Loading...',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'GRAVES_FORM', {
  TITLE: 'Sepulturas',
  STATE: 'Estado',
  ROW: 'Fila',
  COLUMN: 'Columna',
  PRICE: 'Precio',
  COLOR: 'Color',
  PERSON: 'Persona',
  SUBMIT_BUTTON: 'Guardar',
  DELETE_DIALOG_TITLE: 'Eliminar sepultura',
  DELETE_DIALOG_MESSAGE: 'Estás seguro de querer eliminar la sepultura?',
  LOADING: 'Cargando...',
});
