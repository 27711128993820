import React, { FunctionComponent } from 'react';
import { Form, Field } from 'react-final-form';
import { ArrowBackOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import { history } from 'helpers/history';
import {
  composeValidators,
  requiredArrayValidation,
  requiredValidation,
  emailValidation,
  passwordValidation,
  passwordConfirmationValidation,
} from 'helpers/validations';

import Input from 'components/inputs/Input';
import SelectInput from 'components/inputs/SelectInput';

import { ViewContainer, ViewContent, TitleContainer, Title, CustomIconButton } from 'styles/view';
import { FormContent, FormButtonContainer, FormSubmitButton } from 'styles/form';

import { IUsersFormProps } from './types';
import './i18n';

const UsersForm: FunctionComponent<IUsersFormProps> = (props: IUsersFormProps) => {
  const { initialValues, isEditing, isEditingCurrentUser, onSubmit, roles } = props;

  return (
    <ViewContainer auth data-testid="users-form">
      <ViewContent auth>
        <TitleContainer>
          <CustomIconButton onClick={history.goBack}>
            <ArrowBackOutlined />
          </CustomIconButton>
          <Title>{i18next.t<string>('USERS_FORM:TITLE')}</Title>
          <div />
        </TitleContainer>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, pristine }) => (
            <FormContent onSubmit={handleSubmit}>
              <Field
                label={i18next.t<string>('USERS_FORM:FIRST_NAME')}
                name="firstName"
                render={Input}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('USERS_FORM:LAST_NAME')}
                name="lastName"
                render={Input}
                validate={requiredValidation}
              />
              <Field
                disabled={isEditingCurrentUser}
                label={i18next.t<string>('USERS_FORM:EMAIL')}
                name="email"
                render={Input}
                type="email"
                validate={composeValidators(requiredValidation, emailValidation)}
              />
              <Field
                label={i18next.t<string>('USERS_FORM:PASSWORD')}
                name="password"
                render={Input}
                type="password"
                validate={
                  isEditing
                    ? passwordValidation
                    : composeValidators(requiredValidation, passwordValidation)
                }
              />
              <Field
                label={i18next.t<string>('USERS_FORM:PASSWORD_CONFIRMATION')}
                name="passwordConfirmation"
                render={Input}
                type="password"
                validate={
                  isEditing
                    ? passwordValidation
                    : composeValidators(requiredValidation, passwordConfirmationValidation)
                }
              />
              <Field
                label={i18next.t<string>('USERS_FORM:ROLES')}
                multiple
                name="roles"
                options={roles}
                render={SelectInput}
                validate={requiredArrayValidation}
              />
              <FormButtonContainer>
                <FormSubmitButton disabled={pristine || submitting} type="submit">
                  {i18next.t<string>('USERS_FORM:SUBMIT_BUTTON')}
                </FormSubmitButton>
              </FormButtonContainer>
            </FormContent>
          )}
        />
      </ViewContent>
    </ViewContainer>
  );
};

export default UsersForm;
