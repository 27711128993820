import { AxiosResponse } from 'axios';

import { get, post, put, remove } from 'services/api';
import { IProceduresFormData } from 'views/ProceduresView/components/ProceduresForm/types';
import { IProceduresUrlFormData } from 'views/ProceduresView/components/ProceduresUrlForm/types';

export const getAll = (
  limit: number,
  page: number,
  search: string,
  sortKey: string,
  sortType: string,
): Promise<AxiosResponse> =>
  get(
    `/procedures?limit=${limit}&page=${page}&search=${search}&sortKey=${sortKey}&sortType=${sortType}`,
  );

export const getForm = (): Promise<AxiosResponse> => get('/procedures/form');

export const getOne = (id: string): Promise<AxiosResponse> => get(`/procedures/${id}`);

export const save = (formData: IProceduresFormData, id: string): Promise<AxiosResponse> => {
  return !id ? post('/procedures', formData) : put(`/procedures/${id}`, formData);
};

export const saveUrlForm = (
  id: string,
  expires: string,
  signature: string,
  data: IProceduresUrlFormData,
): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('first_name', data.firstName);
  formData.append('last_name', data.lastName);
  if (data.lastNameMother) formData.append('last_name_mother', data.lastNameMother);
  formData.append('id_card', data.idCard);
  formData.append('email', `${data.email}`);
  formData.append('phone', `${data.phone}`);
  formData.append('civil_state', data.civilState);
  formData.append('birthdate', data.birthdate);
  formData.append('address', data.address);
  formData.append('tribe', data.tribe);
  formData.append('country', data.country);
  if (data.image) formData.append('image', data.image);
  formData.append('alive', data.alive.toString());
  if (data.cementery) formData.append('cementery', data.cementery);
  if (data.relations) {
    const relations = data.relations.map(({ person, personState }) => ({
      state: personState,
      id: person.value,
    }));
    formData.append('relations', JSON.stringify(relations));
  }
  if (data.documents) {
    const documents = data.documents.map((document, i) => {
      if (document.file) formData.append(`file${i}`, document.file);
      return {
        ...document,
        date: document.date || undefined,
        document_type_id: document.documentTypeId,
        file: undefined,
        persons: document.persons?.map(({ person }) => person.value).join(','),
      };
    });
    formData.append('documents', JSON.stringify(documents));
  }
  return post(`/procedures/url/${id}?expires=${expires}&signature=${signature}`, formData, {
    transformRequest: () => formData,
  });
};

export const softDelete = (id: string): Promise<AxiosResponse> => remove(`/procedures/${id}`);

export const validateUrlForm = (
  id: string,
  expires: string,
  signature: string,
): Promise<AxiosResponse> => get(`/procedures/url/${id}?expires=${expires}&signature=${signature}`);
