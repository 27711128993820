import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'DOCUMENTS_SHOW', {
  TITLE: 'Document',
  NAME: 'Name',
  DATE: 'Date',
  DOCUMENT_TYPE: 'Document type',
  PERSONS: 'Persons',
  LOADING: 'Loading...',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'DOCUMENTS_SHOW', {
  TITLE: 'Documento',
  NAME: 'Nombre',
  DATE: 'Fecha',
  DOCUMENT_TYPE: 'Tipo de documento',
  PERSONS: 'Personas',
  LOADING: 'Cargando...',
});
