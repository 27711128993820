import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { ICategoryPricesFormData } from 'views/CategoryPricesView/components/CategoryPricesForm/types';
import { onGetForm, onGetOne, onSave } from 'redux/actions/categoryPrices.actions';
import { categoryPricesFormSelector } from 'redux/selectors/categoryPrices.selector';

import LayoutContainer from 'containers/LayoutContainer';
import CategoryPricesForm from 'views/CategoryPricesView/components/CategoryPricesForm';
import Loading from 'components/commons/Loading';

import 'views/CategoryPricesView/components/CategoryPricesForm/i18n';

const CategoryPricesFormContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id = '' } = useParams<{ id?: string }>();
  const initialValues = useTypedSelector(categoryPricesFormSelector);
  const { loading, selectedItem: item } = useTypedSelector((state) => state.categoryPrices);
  const isEditing = !!item?.id;

  useEffect(() => {
    dispatch(onGetForm());
    if (id) dispatch(onGetOne(id));
    return () => {
      dispatch(onGetOne(''));
    };
  }, [dispatch, id]);

  const onSubmit = (formData: ICategoryPricesFormData) => dispatch(onSave(formData, id));

  return (
    <LayoutContainer auth title={i18next.t<string>('CATEGORY_PRICES_FORM:TITLE')}>
      <CategoryPricesForm initialValues={initialValues} isEditing={isEditing} onSubmit={onSubmit} />
      {loading && (
        <Loading isModalMode message={i18next.t<string>('CATEGORY_PRICES_FORM:LOADING')} />
      )}
    </LayoutContainer>
  );
};

export default CategoryPricesFormContainer;
