import { createSelector } from 'reselect';

import { ICategoryPricesState } from 'types/categoryPrices.types';

const getItems = (state: { categoryPrices: ICategoryPricesState }) => state.categoryPrices.items;
const getSelectedItem = (state: { categoryPrices: ICategoryPricesState }) =>
  state.categoryPrices.selectedItem;

export const categoryPricesSelector = createSelector(
  [getItems],
  (categoryPrices) => categoryPrices,
);

export const categoryPricesFormSelector = createSelector([getSelectedItem], (categoryPrice) => ({
  name: categoryPrice?.name || '',
  price: categoryPrice?.price.toString() || '',
}));
