import React, { FunctionComponent, HTMLAttributes, SyntheticEvent, useState } from 'react';
import { Autocomplete, CircularProgress, createFilterOptions } from '@mui/material';
import { AxiosResponse } from 'axios';
import i18next from 'i18next';

import { IOption } from 'types/common.types';
import { FormGroup, ErrorBlock } from 'styles/input';

import { IAutoCompleteProps } from './types';
import { CustomFormControl, CustomInput } from './styles';
import './i18n';

const filter = createFilterOptions<IOption | string>();

const AutoComplete: FunctionComponent<IAutoCompleteProps> = (props: IAutoCompleteProps) => {
  const {
    apiCall,
    apiCallKey,
    className = '',
    isCreatable = false,
    newPlaceholder,
    disabled = false,
    input,
    itemLabel,
    itemValue,
    label,
    meta: { touched, error },
    onChangeCallback,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<Array<IOption>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { name, onChange } = input;
  const hasError = !!touched && !!error;

  const onInputChange = async (_event: SyntheticEvent | undefined, text: string) => {
    try {
      setLoading(true);
      const response: AxiosResponse = await apiCall(text);
      const items = response.data[apiCallKey].map((item: never) => ({
        label: itemLabel(item),
        value: item[itemValue],
        data: item,
      }));
      setOptions(items);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const getLabel = (value: IOption | string) => {
    if (typeof value === 'string') return value;
    return value.inputValue ? value.inputValue : value.label;
  };

  const renderOption = (renderProps: HTMLAttributes<HTMLLIElement>, option: IOption | string) => (
    <li {...renderProps}>{typeof option === 'string' ? option : option.label}</li>
  );

  return (
    <FormGroup data-testid="auto-complete" className={className}>
      <CustomFormControl disabled={disabled} variant="filled">
        <Autocomplete
          {...input}
          autoComplete
          data-testid="auto-complete-input"
          filterOptions={(optionsToFilter, params) => {
            if (isCreatable) {
              const filtered = filter(optionsToFilter, params);
              const { inputValue } = params;
              const isExisting = options.some(
                (option) => inputValue === ((option as IOption)?.label || option),
              );
              if (inputValue && !isExisting) {
                filtered.push({
                  label: `${inputValue} ${newPlaceholder}`,
                  value: '',
                  inputValue,
                });
              }
              return filtered;
            }
            return optionsToFilter;
          }}
          filterSelectedOptions={false}
          getOptionLabel={getLabel}
          id={name}
          isOptionEqualToValue={(option, value) => getLabel(option) === getLabel(value)}
          noOptionsText={
            <div data-testid="auto-complete-no-options">
              {i18next.t<string>('AUTO_COMPLETE:NO_OPTIONS')}
            </div>
          }
          options={options}
          renderOption={renderOption}
          onChange={(_event: SyntheticEvent, value?: IOption | string | null) => {
            onChange(value);
            if (onChangeCallback)
              onChangeCallback(
                typeof value !== 'string' && value?.inputValue
                  ? { ...value, label: value.inputValue }
                  : value,
              );
          }}
          onClose={() => setOpen(false)}
          onInputChange={onInputChange}
          onOpen={() => {
            setOpen(true);
            onInputChange(undefined, '');
          }}
          open={open}
          disabled={disabled}
          renderInput={(params) => (
            <CustomInput
              {...params}
              disabled={disabled}
              error={hasError}
              id={name}
              label={label}
              name={name}
              variant="filled"
              InputProps={{
                ...params.InputProps,
                'aria-label': name,
                endAdornment: (
                  <>
                    {loading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </CustomFormControl>
      {hasError && <ErrorBlock data-testid="error-block">{error}</ErrorBlock>}
    </FormGroup>
  );
};

export default AutoComplete;
