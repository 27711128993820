import React, { FunctionComponent } from 'react';
import { Box, Chip, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import { Cancel } from '@mui/icons-material';

import { FormGroup, ErrorBlock } from 'styles/input';

import { ISelectInputProps } from './types';
import { CustomFormControl, CustomMenuItem } from './styles';

const SelectInput: FunctionComponent<ISelectInputProps> = (props: ISelectInputProps) => {
  const {
    disabled = false,
    defaultValue = '',
    className = '',
    label,
    input,
    meta: { touched, error },
    options = [],
  } = props;
  const { multiple = false, name, onChange } = input;
  const hasError = !!touched && !!error;

  const getLabelByValue = (value: string) =>
    options.find((option) => option.value === value)?.label;

  const onInputChange = (event: SelectChangeEvent<string | string[]>) =>
    onChange(event.target.value);

  const onDelete = (event: MouseEvent, value: string) => {
    event.preventDefault();
    onChange((input.value as Array<string>).filter((option) => option !== value));
  };

  return (
    <FormGroup data-testid="select" className={className}>
      <CustomFormControl disabled={disabled} variant="filled" error={hasError}>
        <InputLabel id="select-input-label">{label}</InputLabel>
        <Select
          {...input}
          defaultValue={defaultValue}
          disabled={disabled}
          error={hasError}
          id={name}
          inputProps={{
            'aria-label': name,
            'data-testid': `select-input-${name}`,
          }}
          label={label}
          labelId="select-input-label"
          multiple={multiple}
          name={name}
          onChange={onInputChange}
          renderValue={(selected: string | Array<string>) =>
            Array.isArray(selected) ? (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    deleteIcon={<Cancel onMouseDown={(event) => event.stopPropagation()} />}
                    key={value}
                    label={getLabelByValue(value)}
                    onDelete={(event) => onDelete(event, value)}
                  />
                ))}
              </Box>
            ) : (
              getLabelByValue(selected)
            )
          }
        >
          {options.map((option) => (
            <CustomMenuItem key={option.value} value={option.value}>
              {option.label}
            </CustomMenuItem>
          ))}
        </Select>
      </CustomFormControl>
      {hasError && <ErrorBlock data-testid="error-block">{error}</ErrorBlock>}
    </FormGroup>
  );
};

export default SelectInput;
