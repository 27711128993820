import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { IPersonsFormData } from 'views/PersonsView/components/PersonsForm/types';
import { onGetForm, onGetOne, onSave } from 'redux/actions/persons.actions';
import {
  personsFormSelector,
  personsCivilStatesSelector,
  personsPersonStatesSelector,
  personsTribesSelector,
  personsFilesSelector,
} from 'redux/selectors/persons.selector';

import LayoutContainer from 'containers/LayoutContainer';
import PersonsForm from 'views/PersonsView/components/PersonsForm';
import Loading from 'components/commons/Loading';

import 'views/PersonsView/components/PersonsForm/i18n';

const PersonsFormContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id = '' } = useParams<{ id?: string }>();
  const initialValues = useTypedSelector(personsFormSelector);
  const { loading } = useTypedSelector((state) => state.persons);
  const civilStates = useTypedSelector(personsCivilStatesSelector);
  const personStates = useTypedSelector(personsPersonStatesSelector);
  const tribes = useTypedSelector(personsTribesSelector);
  const files = useTypedSelector(personsFilesSelector);

  useEffect(() => {
    dispatch(onGetForm());
    if (id) dispatch(onGetOne(id));
    return () => {
      dispatch(onGetOne(''));
    };
  }, [dispatch, id]);

  const onSubmit = (formData: IPersonsFormData) => dispatch(onSave(formData, id));

  return (
    <LayoutContainer auth title={i18next.t<string>('PERSONS_FORM:TITLE')}>
      <PersonsForm
        civilStates={civilStates}
        files={files}
        initialValues={initialValues}
        onSubmit={onSubmit}
        personStates={personStates}
        tribes={tribes}
      />
      {loading && <Loading isModalMode message={i18next.t<string>('PERSONS_FORM:LOADING')} />}
    </LayoutContainer>
  );
};

export default PersonsFormContainer;
