import { Reducer } from 'redux';

import { IUsersState } from 'types/users.types';
import * as authConstants from 'redux/constants/auth.constants';
import * as constants from 'redux/constants/users.constants';

export const defaultState: IUsersState = {
  count: 0,
  items: [],
  loading: false,
  roles: [],
  selectedItem: null,
  table: {
    page: 1,
    limit: 10,
    search: '',
    sortKey: 'firstName',
    sortType: 'asc',
  },
};

const usersReducer: Reducer = (state = defaultState, action) => {
  const { data, type } = action;
  switch (type) {
    case constants.USERS_ON_DELETE_REQUESTED:
    case constants.USERS_ON_GET_ALL_REQUESTED:
    case constants.USERS_ON_GET_FORM_REQUESTED:
    case constants.USERS_ON_SAVE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.USERS_ON_GET_ONE_REQUESTED:
      return {
        ...state,
        loading: !!action.id,
        selectedItem: null,
      };
    case constants.USERS_ON_GET_ALL_SUCCEEDED:
      return {
        ...state,
        count: data.count,
        items: data.items,
        loading: false,
        table: data.table,
      };
    case constants.USERS_ON_GET_FORM_SUCCEEDED:
      return {
        ...state,
        loading: false,
        roles: data.roles,
      };
    case constants.USERS_ON_GET_ONE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        selectedItem: data.item,
      };
    case constants.USERS_ON_DELETE_SUCCEEDED:
    case constants.USERS_ON_SAVE_SUCCEEDED:
    case constants.USERS_ON_DELETE_FAILED:
    case constants.USERS_ON_GET_ALL_FAILED:
    case constants.USERS_ON_SAVE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case constants.USERS_ON_GET_FORM_FAILED:
      return {
        ...state,
        loading: false,
        roles: [],
      };
    case constants.USERS_ON_GET_ONE_FAILED:
      return {
        ...state,
        loading: false,
        selectedItem: null,
      };
    case authConstants.AUTH_ON_LOGOUT_SUCCEEDED:
      return defaultState;
    default:
      return state;
  }
};

export default usersReducer;
