import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'CATEGORY_PRICES_VIEW', {
  TITLE: 'Category prices',
  NAME: 'Name',
  PRICE: 'Price',
  ACTIONS: 'Actions',
  EDIT: 'Edit',
  DELETE: 'Delete',
  DELETE_DIALOG_TITLE: 'Delete category price',
  DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete the category price {{ name }}?',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'CATEGORY_PRICES_VIEW', {
  TITLE: 'Precios de categoría',
  NAME: 'Nombre',
  PRICE: 'Precio',
  ACTIONS: 'Acciones',
  EDIT: 'Editar',
  DELETE: 'Eliminar',
  DELETE_DIALOG_TITLE: 'Eliminar el precio de categoría',
  DELETE_DIALOG_MESSAGE: 'Estás seguro de querer eliminar el precio de categoría {{ name }}?',
});
