import React, { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { onDelete, onGetAll } from 'redux/actions/users.actions';
import { usersSelector } from 'redux/selectors/users.selector';
import { history } from 'helpers/history';
import { COLORS } from 'helpers/enums/colors';

import LayoutContainer from 'containers/LayoutContainer';
import UsersView from 'views/UsersView';
import ActionButtons from 'components/commons/Table/components/ActionButtons';
import Dialog from 'components/commons/Dialog';

import { IUser } from 'types/users.types';
import { ITableUpdate } from 'components/commons/Table/types';

import 'views/UsersView/i18n';

const UsersContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [deleteItem, setDeleteItem] = useState<IUser | undefined>();
  const { count, loading, table } = useTypedSelector((state) => state.users);
  const items = useTypedSelector(usersSelector);

  const actions = [
    {
      label: i18next.t<string>('USERS_VIEW:EDIT'),
      onClick: (id: string) => history.push(`/users/${id}/edit`),
    },
    {
      label: i18next.t<string>('USERS_VIEW:DELETE'),
      onClick: (id: string) => setDeleteItem(items.find((item: IUser) => item.id === Number(id))),
      color: COLORS.redMonza,
    },
  ];

  const columns = [
    {
      name: 'firstName',
      label: i18next.t<string>('USERS_VIEW:FIRST_NAME'),
    },
    {
      name: 'lastName',
      label: i18next.t<string>('USERS_VIEW:LAST_NAME'),
    },
    {
      name: 'email',
      label: i18next.t<string>('USERS_VIEW:EMAIL'),
    },
    {
      name: 'id',
      label: i18next.t<string>('USERS_VIEW:ACTIONS'),
      options: {
        customBodyRender: (value: string) => <ActionButtons id={value} items={actions} />,
        sort: false,
      },
    },
  ];

  const onTableUpdate = useCallback((data: ITableUpdate) => dispatch(onGetAll(data)), [dispatch]);

  const onDeleteClick = (isConfirmed = false) => {
    if (isConfirmed && deleteItem) dispatch(onDelete(deleteItem.id.toString()));
    setDeleteItem(undefined);
  };

  return (
    <LayoutContainer auth title={i18next.t<string>('USERS_VIEW:TITLE')}>
      <UsersView
        columns={columns}
        count={count}
        data={items}
        loading={loading}
        onAddClick={() => history.push('/users/create')}
        onTableUpdate={onTableUpdate}
        table={table}
      />
      <Dialog
        message={i18next.t<string>('USERS_VIEW:DELETE_DIALOG_MESSAGE', {
          name: `${deleteItem?.firstName} ${deleteItem?.lastName}`,
        })}
        onCancelClick={() => onDeleteClick()}
        onConfirmClick={() => onDeleteClick(true)}
        open={!!deleteItem}
        title={i18next.t<string>('USERS_VIEW:DELETE_DIALOG_TITLE')}
      />
    </LayoutContainer>
  );
};

export default UsersContainer;
