import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import * as constants from 'redux/constants/users.constants';
import { getAll, getForm, getOne, save, softDelete } from 'services/users.services';

export function* usersDelete(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    const { data }: any = yield call(softDelete, id);
    yield put({
      type: constants.USERS_ON_DELETE_SUCCEEDED,
      data: { ...data, id },
    });
  } catch (error) {
    yield put({ type: constants.USERS_ON_DELETE_FAILED, error });
  }
}

export function* usersGetAll(action: AnyAction): Generator {
  try {
    const { limit, page, search, sortKey, sortType } = action;
    const { data }: any = yield call(getAll, limit, page, search, sortKey, sortType);
    yield put({ type: constants.USERS_ON_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USERS_ON_GET_ALL_FAILED, error });
  }
}

export function* usersGetForm(): Generator {
  try {
    const { data }: any = yield call(getForm);
    yield put({ type: constants.USERS_ON_GET_FORM_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USERS_ON_GET_FORM_FAILED, error });
  }
}

export function* usersGetOne(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    if (id) {
      const { data }: any = yield call(getOne, id);
      yield put({ type: constants.USERS_ON_GET_ONE_SUCCEEDED, data });
    }
  } catch (error) {
    yield put({ type: constants.USERS_ON_GET_ONE_FAILED, error });
  }
}

export function* usersSave(action: AnyAction): Generator {
  try {
    const { formData, id } = action;
    const { data }: any = yield call(save, formData, id);
    yield put({ type: constants.USERS_ON_SAVE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.USERS_ON_SAVE_FAILED, error });
  }
}

export function* usersRefresh(): Generator {
  const table: any = yield select((state) => state.users.table);
  yield put({ type: constants.USERS_ON_GET_ALL_REQUESTED, ...table });
}

export function* watchUsers(): Generator {
  yield all([
    takeLatest(constants.USERS_ON_DELETE_REQUESTED, usersDelete),
    takeLatest(constants.USERS_ON_GET_ALL_REQUESTED, usersGetAll),
    takeLatest(constants.USERS_ON_GET_FORM_REQUESTED, usersGetForm),
    takeLatest(constants.USERS_ON_GET_ONE_REQUESTED, usersGetOne),
    takeLatest(constants.USERS_ON_SAVE_REQUESTED, usersSave),
    takeLatest(
      [constants.USERS_ON_DELETE_SUCCEEDED, constants.USERS_ON_SAVE_SUCCEEDED],
      usersRefresh,
    ),
  ]);
}
