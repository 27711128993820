import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'SIDEBAR', {
  CATEGORY_PRICES: 'Category prices',
  CEMENTERIES: 'Cementeries',
  DASHBOARD: 'Dashboard',
  DOCUMENTS: 'Documents',
  DOCUMENTS_CREATE: 'Create document',
  DOCUMENTS_TYPES: 'Documents types',
  PERSONS: 'Persons',
  PERSONS_LIST: 'Persons list',
  PERSONS_STATES: 'Persons states',
  PROCEDURES: 'Procedures',
  USERS: 'Usuarios',
  USERS_LIST: 'Users list',
  USERS_ROLES: 'Roles',
  USERS_PERMISSIONS: 'Permissions',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'SIDEBAR', {
  CATEGORY_PRICES: 'Precios de categoría',
  CEMENTERIES: 'Cementerios',
  DASHBOARD: 'Dashboard',
  DOCUMENTS: 'Documentos',
  DOCUMENTS_CREATE: 'Crear documento',
  DOCUMENTS_TYPES: 'Tipos de documentos',
  PERSONS: 'Personas',
  PERSONS_LIST: 'Listado de personas',
  PERSONS_STATES: 'Estados de personas',
  PROCEDURES: 'Trámites',
  USERS: 'Usuarios',
  USERS_LIST: 'Listado de usuarios',
  USERS_ROLES: 'Roles',
  USERS_PERMISSIONS: 'Permisos',
});
