import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'PROCEDURES_VIEW', {
  TITLE: 'Procedures',
  NAME: 'Name',
  STATE: 'State',
  URL: 'Link',
  COPY_URL: 'Copy link',
  ACTIONS: 'Actions',
  SHOW: 'Show',
  EDIT: 'Edit',
  DELETE: 'Delete',
  DELETE_DIALOG_TITLE: 'Delete procedure',
  DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete the procedure {{ name }}?',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'PROCEDURES_VIEW', {
  TITLE: 'Trámites',
  NAME: 'Nombre',
  STATE: 'Estado',
  URL: 'Link',
  COPY_URL: 'Copiar link',
  ACTIONS: 'Acciones',
  SHOW: 'Ver',
  EDIT: 'Editar',
  DELETE: 'Eliminar',
  DELETE_DIALOG_TITLE: 'Eliminar trámite',
  DELETE_DIALOG_MESSAGE: 'Estás seguro de querer eliminar el trámite {{ name }}?',
});
