import { AxiosResponse } from 'axios';

import { get, post, put, remove } from 'services/api';
import { ICategoryPricesFormData } from 'views/CategoryPricesView/components/CategoryPricesForm/types';

export const getAll = (
  limit: number,
  page: number,
  search: string,
  sortKey: string,
  sortType: string,
): Promise<AxiosResponse> =>
  get(
    `category-prices?limit=${limit}&page=${page}&search=${search}&sortKey=${sortKey}&sortType=${sortType}`,
  );

export const getOne = (id: string): Promise<AxiosResponse> => get(`category-prices/${id}`);

export const save = (formData: ICategoryPricesFormData, id: string): Promise<AxiosResponse> =>
  !id ? post('category-prices', formData) : put(`category-prices/${id}`, formData);

export const softDelete = (id: string): Promise<AxiosResponse> => remove(`category-prices/${id}`);
