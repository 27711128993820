import React, { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { onDelete, onGetAll } from 'redux/actions/documentsTypes.actions';
import { documentsTypesSelector } from 'redux/selectors/documentsTypes.selector';
import { history } from 'helpers/history';
import { COLORS } from 'helpers/enums/colors';

import LayoutContainer from 'containers/LayoutContainer';
import DocumentsTypesView from 'views/DocumentsTypesView';
import ActionButtons from 'components/commons/Table/components/ActionButtons';
import Dialog from 'components/commons/Dialog';

import { IDocumentType } from 'types/documentsTypes.types';
import { ITableUpdate } from 'components/commons/Table/types';

import 'views/DocumentsTypesView/i18n';

const DocumentsTypesContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [deleteItem, setDeleteItem] = useState<IDocumentType | undefined>();
  const { count, loading, table } = useTypedSelector((state) => state.documentsTypes);
  const items = useTypedSelector(documentsTypesSelector);

  const actions = [
    {
      label: i18next.t<string>('DOCUMENTS_TYPES_VIEW:SHOW'),
      onClick: (id: string) => history.push(`/documents/types/${id}/show`),
    },
    {
      label: i18next.t<string>('DOCUMENTS_TYPES_VIEW:EDIT'),
      onClick: (id: string) => history.push(`/documents/types/${id}/edit`),
    },
    {
      label: i18next.t<string>('DOCUMENTS_TYPES_VIEW:DELETE'),
      onClick: (id: string) =>
        setDeleteItem(items.find((item: IDocumentType) => item.id === Number(id))),
      color: COLORS.redMonza,
    },
  ];

  const columns = [
    {
      name: 'name',
      label: i18next.t<string>('DOCUMENTS_TYPES_VIEW:NAME'),
    },
    {
      name: 'id',
      label: i18next.t<string>('DOCUMENTS_TYPES_VIEW:ACTIONS'),
      options: {
        customBodyRender: (value: string) => <ActionButtons id={value} items={actions} />,
        sort: false,
      },
    },
  ];

  const onTableUpdate = useCallback((data: ITableUpdate) => dispatch(onGetAll(data)), [dispatch]);

  const onDeleteClick = (isConfirmed = false) => {
    if (isConfirmed && deleteItem) dispatch(onDelete(deleteItem.id.toString()));
    setDeleteItem(undefined);
  };

  return (
    <LayoutContainer auth title={i18next.t<string>('DOCUMENTS_TYPES_VIEW:TITLE')}>
      <DocumentsTypesView
        columns={columns}
        count={count}
        data={items}
        loading={loading}
        onAddClick={() => history.push('/documents/types/create')}
        onTableUpdate={onTableUpdate}
        table={table}
      />
      <Dialog
        message={i18next.t<string>('DOCUMENTS_TYPES_VIEW:DELETE_DIALOG_MESSAGE', {
          name: deleteItem?.name,
        })}
        onCancelClick={() => onDeleteClick()}
        onConfirmClick={() => onDeleteClick(true)}
        open={!!deleteItem}
        title={i18next.t<string>('DOCUMENTS_TYPES_VIEW:DELETE_DIALOG_TITLE')}
      />
    </LayoutContainer>
  );
};

export default DocumentsTypesContainer;
