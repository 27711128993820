import styled from 'styled-components';
import { Dialog, DialogContent, DialogTitle as TitleContainer, DialogActions } from '@mui/material';
import { Close } from '@mui/icons-material';

import { COLORS } from 'helpers/enums/colors';

import Button from '../Button';

export const DialogContainer = styled(Dialog)`
  && {
    [class*='MuiPaper-root'] {
      border-radius: 6px;
    }
  }
`;

export const DialogContentContainer = styled(DialogContent)`
  && {
    & > .MuiPaper-root {
      overflow-y: hidden;
    }

    .MuiPaper-root {
      overflow-y: scroll;
      max-height: calc(100vh - 270px);
    }
  }
`;

export const DialogTitleContainer = styled(TitleContainer)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const DialogTitle = styled.span`
  display: flex;
  align-items: center;
  color: ${COLORS.grayCloudBurst};
  font-size: 20px;
`;

export const DialogCloseButton = styled(Close)`
  && {
    color: ${COLORS.grayScorpion};
    cursor: pointer;

    &:hover {
      color: ${COLORS.blackEbony};
    }
  }
`;

export const DialogMessage = styled.span`
  color: ${COLORS.grayCloudBurst};
  font-size: 16px;
`;

export const DialogButtonsContainer = styled(DialogActions)`
  && {
    padding: 20px 24px;
  }
`;

export const DialogButton = styled(Button)`
  && {
    margin-left: 16px !important;
    padding: 8px 16px;
    font-family: 'Montserrat', Helvetica, Arial, serif;
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    text-transform: none;
  }
`;

export const DialogFooterContainer = styled.div`
  margin-top: 16px;
`;
