import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { IPermissionsFormData } from 'views/PermissionsView/components/PermissionsForm/types';
import { onGetOne, onSave } from 'redux/actions/permissions.actions';
import { permissionsFormSelector } from 'redux/selectors/permissions.selector';

import LayoutContainer from 'containers/LayoutContainer';
import PermissionsForm from 'views/PermissionsView/components/PermissionsForm';
import Loading from 'components/commons/Loading';

import 'views/PermissionsView/components/PermissionsForm/i18n';

const PermissionsFormContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id = '' } = useParams<{ id?: string }>();
  const initialValues = useTypedSelector(permissionsFormSelector);
  const { loading } = useTypedSelector((state) => state.permissions);
  const isEditing = !!id;

  useEffect(() => {
    if (id) dispatch(onGetOne(id));
    return () => {
      dispatch(onGetOne(''));
    };
  }, [dispatch, id]);

  const onSubmit = (formData: IPermissionsFormData) => dispatch(onSave(formData, id));

  return (
    <LayoutContainer auth title={i18next.t<string>('PERMISSIONS_FORM:TITLE')}>
      <PermissionsForm initialValues={initialValues} isEditing={isEditing} onSubmit={onSubmit} />
      {loading && <Loading isModalMode message={i18next.t<string>('PERMISSIONS_FORM:LOADING')} />}
    </LayoutContainer>
  );
};

export default PermissionsFormContainer;
