import { createSelector } from 'reselect';

import { IPerson, IPersonsState } from 'types/persons.types';

const getItems = (state: { persons: IPersonsState }) => state.persons.items;
const getSelectedItem = (state: { persons: IPersonsState }) => state.persons.selectedItem;
const getCivilStates = (state: { persons: IPersonsState }) => state.persons.civilStates;
const getPersonStates = (state: { persons: IPersonsState }) => state.persons.personStates;
const getTribes = (state: { persons: IPersonsState }) => state.persons.tribes;

export const personsSelector = createSelector([getItems], (persons) => persons);

export const personsFormSelector = createSelector([getSelectedItem], (person) => ({
  id: person?.id,
  firstName: person?.firstName || '',
  lastName: person?.lastName || '',
  lastNameMother: person?.lastNameMother || '',
  idCard: person?.idCard || '',
  email: person?.email || '',
  phone: person?.phone || '',
  civilState: person?.civilState || '',
  birthdate: person?.birthdate || '',
  address: person?.address || '',
  tribe: person?.tribe || '',
  country: person?.country || '',
  comments: person?.comments || '',
  alive: person?.alive ?? true,
  cementery: person?.cementery || '',
  relations: [
    ...(person?.relations1?.map((relation) => ({
      person: {
        label: `${relation.person2?.firstName} ${relation.person2?.lastName} (${relation.person2?.idCard})`,
        value: relation.person2Id.toString(),
      },
      personState: relation.personState,
    })) || []),
    /* ...(person?.relations2?.map((relation) => ({
      person: {
        label: `${relation.person1?.firstName} ${relation.person1?.lastName}`,
        value: relation.person1Id.toString(),
      },
      personState: relation.personState,
    })) || []), */
  ],
}));

export const personsCivilStatesSelector = createSelector([getCivilStates], (civilStates) =>
  Object.keys(civilStates).map((key) => ({
    label: civilStates[key],
    value: key,
  })),
);

export const personsPersonStatesSelector = createSelector([getPersonStates], (personStates) =>
  Object.keys(personStates).map((key) => ({
    label: personStates[key],
    value: key,
  })),
);

export const personsTribesSelector = createSelector([getTribes], (tribes) =>
  Object.keys(tribes).map((key) => ({
    label: tribes[key],
    value: key,
  })),
);

export const personsFilesSelector = createSelector([getSelectedItem], (person) => ({
  image: person?.image || '',
}));

export const personsShowSelector = createSelector(
  [getSelectedItem],
  (person) =>
    person && {
      ...person,
      siblings: person?.parents
        ?.reduce((a: Array<IPerson>, b: IPerson) => [...a, ...(b?.childs ?? [])], [])
        .filter(({ id }) => id !== person.id),
      grandParents: person?.parents?.reduce(
        (a: Array<IPerson>, b: IPerson) => [...a, ...(b?.parents ?? [])],
        [],
      ),
    },
);
