import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'CEMENTERIES_FORM', {
  TITLE: 'Cementeries',
  NAME: 'Name',
  BLOCKS: 'Blocks',
  SUBMIT_BUTTON: 'Save',
  LOADING: 'Loading...',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'CEMENTERIES_FORM', {
  TITLE: 'Cementerios',
  NAME: 'Nombre',
  BLOCKS: 'Manzanas',
  SUBMIT_BUTTON: 'Guardar',
  LOADING: 'Cargando...',
});
