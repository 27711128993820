import React, { Fragment, FunctionComponent, useState } from 'react';
import { ArrowBackOutlined, EditOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import { history } from 'helpers/history';
import { FILE_TYPES } from 'helpers/validations/constants';
import { base64PDFToBlobUrl } from 'helpers/utils';

import {
  ViewContainer,
  ViewContent,
  TitleContainer,
  Title,
  TableContainer,
  CustomIconButton,
  FilePreview,
} from 'styles/view';

import { IPersonsShowProps } from './types';
import { DocumentsDropdown, PageLink } from './styles';
import './i18n';

const PersonsShow: FunctionComponent<IPersonsShowProps> = (props: IPersonsShowProps) => {
  const { item } = props;
  const [selectedDocument, setSelectedDocument] = useState<string | string[]>('');

  const document = item?.documents?.find(({ id }) => id.toString() === selectedDocument);

  return (
    <ViewContainer auth data-testid="persons-show">
      <ViewContent auth>
        <TitleContainer>
          <CustomIconButton onClick={history.goBack}>
            <ArrowBackOutlined />
          </CustomIconButton>
          <Title>{i18next.t<string>('PERSONS_SHOW:TITLE')}</Title>
          <CustomIconButton onClick={() => history.push(`/persons/${item?.id}/edit`)}>
            <EditOutlined />
          </CustomIconButton>
        </TitleContainer>
        {item && (
          <>
            <TableContainer>
              <tbody>
                <tr>
                  <td>{i18next.t<string>('PERSONS_SHOW:ID')}</td>
                  <td>{item?.id}</td>
                </tr>
                <tr>
                  <td>{i18next.t<string>('PERSONS_SHOW:FIRST_NAME')}</td>
                  <td>{item?.firstName}</td>
                </tr>
                <tr>
                  <td>{i18next.t<string>('PERSONS_SHOW:LAST_NAME')}</td>
                  <td>{item?.lastName}</td>
                </tr>
                {!!item?.lastNameMother && (
                  <tr>
                    <td>{i18next.t<string>('PERSONS_SHOW:LAST_NAME_MOTHER')}</td>
                    <td>{item.lastNameMother}</td>
                  </tr>
                )}
                <tr>
                  <td>{i18next.t<string>('PERSONS_SHOW:ID_CARD')}</td>
                  <td>{item?.idCard}</td>
                </tr>
                {!!item?.email && (
                  <tr>
                    <td>{i18next.t<string>('PERSONS_SHOW:EMAIL')}</td>
                    <td>{item.email}</td>
                  </tr>
                )}
                {!!item?.phone && (
                  <tr>
                    <td>{i18next.t<string>('PERSONS_SHOW:PHONE')}</td>
                    <td>{item.phone}</td>
                  </tr>
                )}
                <tr>
                  <td>{i18next.t<string>('PERSONS_SHOW:CIVIL_STATE')}</td>
                  <td>{item?.civilStateName}</td>
                </tr>
                <tr>
                  <td>{i18next.t<string>('PERSONS_SHOW:BIRTHDATE')}</td>
                  <td>{item?.birthdate}</td>
                </tr>
                <tr>
                  <td>{i18next.t<string>('PERSONS_SHOW:ADDRESS')}</td>
                  <td>{item?.address}</td>
                </tr>
                <tr>
                  <td>{i18next.t<string>('PERSONS_SHOW:TRIBE')}</td>
                  <td>{item?.tribeName}</td>
                </tr>
                <tr>
                  <td>{i18next.t<string>('PERSONS_SHOW:COUNTRY')}</td>
                  <td>{item?.country}</td>
                </tr>
                {!!item?.image && (
                  <tr>
                    <td>{i18next.t<string>('PERSONS_SHOW:IMAGE')}</td>
                    <td>
                      <a href={item.image} rel="noreferrer" target="_blank">
                        <img alt="preview" height="50" src={item.image} />
                      </a>
                    </td>
                  </tr>
                )}
                {!!item?.comments && (
                  <tr>
                    <td>{i18next.t<string>('PERSONS_SHOW:COMMENTS')}</td>
                    <td>{item.comments}</td>
                  </tr>
                )}
                <tr>
                  <td>{i18next.t<string>('PERSONS_SHOW:ALIVE')}</td>
                  <td>
                    {item?.alive
                      ? i18next.t<string>('PERSONS_SHOW:YES')
                      : i18next.t<string>('PERSONS_SHOW:NO')}
                  </td>
                </tr>
                {!item?.alive && !!item?.cementery && (
                  <tr>
                    <td>{i18next.t<string>('PERSONS_SHOW:CEMENTERY')}</td>
                    <td>{item.cementery}</td>
                  </tr>
                )}
                {(!!item?.relations1?.length || !!item?.relations2?.length) && (
                  <>
                    <tr>
                      <td>
                        <strong>{i18next.t<string>('PERSONS_SHOW:PERSONS_RELATIONS')}</strong>
                      </td>
                      <td />
                    </tr>
                    {!!item?.parents?.length && (
                      <tr>
                        <td>{i18next.t<string>('PERSONS_SHOW:PARENTS')}</td>
                        <td>
                          {item.parents.map((person, index) => (
                            <Fragment key={`person-${person.id}`}>
                              <PageLink to={`/persons/${person.id}/show`}>
                                {`${person.firstName} ${person.lastName}`}
                              </PageLink>
                              {index === (item.parents ?? []).length - 1 ? '' : ', '}
                            </Fragment>
                          ))}
                        </td>
                      </tr>
                    )}
                    {!!item?.childs?.length && (
                      <tr>
                        <td>{i18next.t<string>('PERSONS_SHOW:CHILDS')}</td>
                        <td>
                          {item.childs.map((person, index) => (
                            <Fragment key={`person-${person.id}`}>
                              <PageLink to={`/persons/${person.id}/show`}>
                                {`${person.firstName} ${person.lastName}`}
                              </PageLink>
                              {index === (item.childs ?? []).length - 1 ? '' : ', '}
                            </Fragment>
                          ))}
                        </td>
                      </tr>
                    )}
                    {!!item?.siblings?.length && (
                      <tr>
                        <td>{i18next.t<string>('PERSONS_SHOW:SIBLINGS')}</td>
                        <td>
                          {item.siblings.map((person, index) => (
                            <Fragment key={`person-${person.id}`}>
                              <PageLink to={`/persons/${person.id}/show`}>
                                {`${person.firstName} ${person.lastName}`}
                              </PageLink>
                              {index === (item.siblings ?? []).length - 1 ? '' : ', '}
                            </Fragment>
                          ))}
                        </td>
                      </tr>
                    )}
                    {!!item?.grandParents?.length && (
                      <tr>
                        <td>{i18next.t<string>('PERSONS_SHOW:GRAND_PARENTS')}</td>
                        <td>
                          {item.grandParents.map((person, index) => (
                            <Fragment key={`person-${person.id}`}>
                              <PageLink to={`/persons/${person.id}/show`}>
                                {`${person.firstName} ${person.lastName}`}
                              </PageLink>
                              {index === (item.grandParents ?? []).length - 1 ? '' : ', '}
                            </Fragment>
                          ))}
                        </td>
                      </tr>
                    )}
                    {item?.relations1
                      ?.filter(({ personState }) => personState !== 'CHILD')
                      ?.map(({ id, personStateName, person2 }) => (
                        <tr key={id}>
                          <td>{personStateName}</td>
                          <td>
                            <PageLink to={`/persons/${person2?.id}/show`}>
                              {`${person2?.firstName} ${person2?.lastName}`}
                            </PageLink>
                          </td>
                        </tr>
                      ))}
                    {item?.relations2
                      ?.filter(({ personState }) => personState !== 'CHILD')
                      ?.map(({ id, personStateName, person1 }) => (
                        <tr key={id}>
                          <td>{personStateName}</td>
                          <td>
                            <PageLink to={`/persons/${person1?.id}/show`}>
                              {`${person1?.firstName} ${person1?.lastName}`}
                            </PageLink>
                          </td>
                        </tr>
                      ))}
                  </>
                )}
              </tbody>
            </TableContainer>
            {!!item?.documents?.length && (
              <>
                <DocumentsDropdown
                  input={{
                    name: 'documents',
                    onChange: setSelectedDocument,
                    value: selectedDocument,
                  }}
                  label={i18next.t<string>('PERSONS_SHOW:DOCUMENTS')}
                  meta={{}}
                  options={item.documents.map(({ id, name }) => ({
                    label: name,
                    value: id.toString(),
                  }))}
                />
                {!!document && (
                  <>
                    <TableContainer>
                      <tbody>
                        <tr>
                          <td>{i18next.t<string>('PERSONS_SHOW:DOCUMENT_NAME')}</td>
                          <td>{document.name}</td>
                        </tr>
                        {!!document?.date && (
                          <tr>
                            <td>{i18next.t<string>('PERSONS_SHOW:DOCUMENT_DATE')}</td>
                            <td>{document.date}</td>
                          </tr>
                        )}
                        <tr>
                          <td>{i18next.t<string>('PERSONS_SHOW:DOCUMENT_TYPE')}</td>
                          <td>{document.documentType?.name}</td>
                        </tr>
                        <tr>
                          <td>{i18next.t<string>('PERSONS_SHOW:ACTION')}</td>
                          <td>
                            <PageLink to={`/documents/${document.id}/edit`}>
                              {i18next.t<string>('PERSONS_SHOW:EDIT_DOCUMENT')}
                            </PageLink>
                          </td>
                        </tr>
                      </tbody>
                    </TableContainer>
                    <FilePreview
                      src={
                        document.file.includes(FILE_TYPES.pdf)
                          ? base64PDFToBlobUrl(document.file)
                          : document.file
                      }
                      title="file"
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </ViewContent>
    </ViewContainer>
  );
};

export default PersonsShow;
