import React, { FunctionComponent } from 'react';
import { Form, Field } from 'react-final-form';
import { ArrowBackOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import { history } from 'helpers/history';
import { requiredValidation } from 'helpers/validations';

import Input from 'components/inputs/Input';

import { ViewContainer, ViewContent, TitleContainer, Title, CustomIconButton } from 'styles/view';
import { FormContent, FormButtonContainer, FormSubmitButton } from 'styles/form';

import { ICementeriesFormProps } from './types';
import './i18n';

const CementeriesForm: FunctionComponent<ICementeriesFormProps> = (
  props: ICementeriesFormProps,
) => {
  const { initialValues, isEditing, onSubmit } = props;

  return (
    <ViewContainer auth data-testid="cementeries-form">
      <ViewContent auth>
        <TitleContainer>
          <CustomIconButton onClick={history.goBack}>
            <ArrowBackOutlined />
          </CustomIconButton>
          <Title>{i18next.t<string>('CEMENTERIES_FORM:TITLE')}</Title>
          <div />
        </TitleContainer>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, pristine }) => (
            <FormContent onSubmit={handleSubmit}>
              <Field
                label={i18next.t<string>('CEMENTERIES_FORM:NAME')}
                name="name"
                render={Input}
                validate={requiredValidation}
              />
              {!isEditing && (
                <Field
                  label={i18next.t<string>('CEMENTERIES_FORM:BLOCKS')}
                  name="blocks"
                  render={Input}
                  type="number"
                  validate={requiredValidation}
                />
              )}
              <FormButtonContainer>
                <FormSubmitButton disabled={pristine || submitting} type="submit">
                  {i18next.t<string>('CEMENTERIES_FORM:SUBMIT_BUTTON')}
                </FormSubmitButton>
              </FormButtonContainer>
            </FormContent>
          )}
        />
      </ViewContent>
    </ViewContainer>
  );
};

export default CementeriesForm;
