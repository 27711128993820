import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { onGetOne } from 'redux/actions/documents.actions';

import LayoutContainer from 'containers/LayoutContainer';
import DocumentsShow from 'views/DocumentsView/components/DocumentsShow';
import Loading from 'components/commons/Loading';

import 'views/DocumentsView/components/DocumentsShow/i18n';

const DocumentsShowContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id = '' } = useParams<{ id?: string }>();
  const { loading, selectedItem: item } = useTypedSelector((state) => state.documents);

  useEffect(() => {
    if (id) dispatch(onGetOne(id));
    return () => {
      dispatch(onGetOne(''));
    };
  }, [dispatch, id]);

  return (
    <LayoutContainer auth title={i18next.t<string>('DOCUMENTS_SHOW:TITLE')}>
      <DocumentsShow item={item} />
      {loading && <Loading isModalMode message={i18next.t<string>('DOCUMENTS_SHOW:LOADING')} />}
    </LayoutContainer>
  );
};

export default DocumentsShowContainer;
