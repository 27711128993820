import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'DOCUMENTS_FORM', {
  TITLE: 'Documents',
  NAME: 'Name',
  DATE: 'Date',
  DOCUMENT_TYPE: 'Document type',
  FILE: 'File',
  ADD_PERSON: 'Add person',
  PERSON: 'Person',
  SUBMIT_BUTTON: 'Save',
  DELETE_DIALOG_TITLE: 'Delete document',
  DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete the document {{ name }}?',
  LOADING: 'Loading...',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'DOCUMENTS_FORM', {
  TITLE: 'Documentos',
  NAME: 'Nombre',
  DATE: 'Fecha',
  DOCUMENT_TYPE: 'Tipo de documento',
  FILE: 'Archivo',
  ADD_PERSON: 'Agregar persona',
  PERSON: 'Persona',
  SUBMIT_BUTTON: 'Guardar',
  DELETE_DIALOG_TITLE: 'Eliminar documento',
  DELETE_DIALOG_MESSAGE: 'Estás seguro de querer eliminar el documento {{ name }}?',
  LOADING: 'Cargando...',
});
