import { ENVIRONMENT } from 'configs/configs';

export const ENVIRONMENTS = {
  local: 'local',
  development: 'development',
  staging: 'staging',
  production: 'production',
};

export const isLocalEnvironment = ENVIRONMENT === ENVIRONMENTS.local;
export const isDevelopmentEnvironment = ENVIRONMENT === ENVIRONMENTS.development;
export const isStagingEnvironment = ENVIRONMENT === ENVIRONMENTS.staging;
export const isProductionEnvironment = ENVIRONMENT === ENVIRONMENTS.production;
