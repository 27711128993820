import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { COLORS } from 'helpers/enums/colors';

export const ForgotPasswordLink = styled(Link)`
  && {
    margin-top: 16px;
    color: ${COLORS.blueRibbon};
    font-size: 16px;
  }
`;
