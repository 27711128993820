import React, { FunctionComponent } from 'react';
import { ArrowBackOutlined, EditOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import { history } from 'helpers/history';

import {
  ViewContainer,
  ViewContent,
  TitleContainer,
  Title,
  TableContainer,
  CustomIconButton,
  FilePreview,
} from 'styles/view';

import { IDocumentsShowProps } from './types';
import './i18n';

const DocumentsShow: FunctionComponent<IDocumentsShowProps> = (props: IDocumentsShowProps) => {
  const { item } = props;

  return (
    <ViewContainer auth data-testid="documents-show">
      <ViewContent auth>
        <TitleContainer>
          <CustomIconButton onClick={history.goBack}>
            <ArrowBackOutlined />
          </CustomIconButton>
          <Title>{i18next.t<string>('DOCUMENTS_SHOW:TITLE')}</Title>
          <CustomIconButton onClick={() => history.push(`/documents/${item?.id}/edit`)}>
            <EditOutlined />
          </CustomIconButton>
        </TitleContainer>
        {item && (
          <>
            <TableContainer>
              <tbody>
                <tr>
                  <td>{i18next.t<string>('DOCUMENTS_TYPES_SHOW:ID')}</td>
                  <td>{item?.id}</td>
                </tr>
                <tr>
                  <td>{i18next.t<string>('DOCUMENTS_SHOW:NAME')}</td>
                  <td>{item?.name}</td>
                </tr>
                {!!item?.date && (
                  <tr>
                    <td>{i18next.t<string>('DOCUMENTS_SHOW:DATE')}</td>
                    <td>{item.date}</td>
                  </tr>
                )}
                <tr>
                  <td>{i18next.t<string>('DOCUMENTS_SHOW:DOCUMENT_TYPE')}</td>
                  <td>{item?.documentType?.name}</td>
                </tr>
                {!!item?.persons?.length && (
                  <tr>
                    <td>{i18next.t<string>('DOCUMENTS_SHOW:PERSONS')}</td>
                    <td>
                      {item.persons
                        .map((person) => `${person.firstName} ${person.lastName}`)
                        .join(', ')}
                    </td>
                  </tr>
                )}
              </tbody>
            </TableContainer>
            <FilePreview src={item?.file} title="file" />
          </>
        )}
      </ViewContent>
    </ViewContainer>
  );
};

export default DocumentsShow;
