import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'PERSONS_FORM', {
  TITLE: 'Persons',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  LAST_NAME_MOTHER: "Mother's last name",
  ID_CARD: 'ID card',
  EMAIL: 'Email',
  PHONE: 'Phone',
  CIVIL_STATE: 'Civil state',
  BIRTHDATE: 'Birthdate',
  ADDRESS: 'Address',
  TRIBE: 'Tribe',
  COUNTRY: 'Country',
  IMAGE: 'Image',
  COMMENTS: 'Comments',
  ALIVE: 'Is alive?',
  CEMENTERY: 'Cementery',
  PERSON_RELATION: 'Person relation',
  PERSON: 'Person',
  PERSON_STATE: 'Person state',
  PERSON_RELATION_BUTTON: 'Add person relation',
  SUBMIT_BUTTON: 'Save',
  LOADING: 'Loading...',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'PERSONS_FORM', {
  TITLE: 'Personas',
  FIRST_NAME: 'Nombre',
  LAST_NAME: 'Apellido',
  LAST_NAME_MOTHER: 'Apellido materno',
  ID_CARD: 'DNI',
  EMAIL: 'Email',
  PHONE: 'Celular',
  CIVIL_STATE: 'Estado civil',
  BIRTHDATE: 'Fecha de nacimiento',
  ADDRESS: 'Domicilio',
  TRIBE: 'Tribu',
  COUNTRY: 'País',
  IMAGE: 'Imagen',
  COMMENTS: 'Observaciones',
  ALIVE: 'Está vivo?',
  CEMENTERY: 'Cementerio',
  PERSON_RELATION: 'Relación personal',
  PERSON: 'Persona',
  PERSON_STATE: 'Estado de la persona',
  PERSON_RELATION_BUTTON: 'Agregar relación personal',
  SUBMIT_BUTTON: 'Guardar',
  LOADING: 'Cargando...',
});
