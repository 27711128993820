import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'DOCUMENTS_TYPES_VIEW', {
  TITLE: 'Documents types',
  NAME: 'Name',
  ACTIONS: 'Actions',
  SHOW: 'Show',
  EDIT: 'Edit',
  DELETE: 'Delete',
  DELETE_DIALOG_TITLE: 'Delete document type',
  DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete the document type {{ name }}?',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'DOCUMENTS_TYPES_VIEW', {
  TITLE: 'Tipos de documentos',
  NAME: 'Nombre',
  ACTIONS: 'Acciones',
  SHOW: 'Ver',
  EDIT: 'Editar',
  DELETE: 'Eliminar',
  DELETE_DIALOG_TITLE: 'Eliminar tipo de documento',
  DELETE_DIALOG_MESSAGE: 'Estás seguro de querer eliminar el tipo de documento {{ name }}?',
});
