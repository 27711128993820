import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { IRolesFormData } from 'views/RolesView/components/RolesForm/types';
import { onGetForm, onGetOne, onSave } from 'redux/actions/roles.actions';
import { rolesFormSelector, rolesPermissionsSelector } from 'redux/selectors/roles.selector';

import LayoutContainer from 'containers/LayoutContainer';
import RolesForm from 'views/RolesView/components/RolesForm';
import Loading from 'components/commons/Loading';

import 'views/RolesView/components/RolesForm/i18n';

const RolesFormContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id = '' } = useParams<{ id?: string }>();
  const initialValues = useTypedSelector(rolesFormSelector);
  const { loading } = useTypedSelector((state) => state.roles);
  const permissions = useTypedSelector(rolesPermissionsSelector);
  const isEditing = !!id;

  useEffect(() => {
    dispatch(onGetForm());
    if (id) dispatch(onGetOne(id));
    return () => {
      dispatch(onGetOne(''));
    };
  }, [dispatch, id]);

  const onSubmit = (formData: IRolesFormData) => dispatch(onSave(formData, id));

  return (
    <LayoutContainer auth title={i18next.t<string>('ROLES_FORM:TITLE')}>
      <RolesForm
        initialValues={initialValues}
        isEditing={isEditing}
        onSubmit={onSubmit}
        permissions={permissions}
      />
      {loading && <Loading isModalMode message={i18next.t<string>('ROLES_FORM:LOADING')} />}
    </LayoutContainer>
  );
};

export default RolesFormContainer;
