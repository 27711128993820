import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { IDocumentsTypesFormData } from 'views/DocumentsTypesView/components/DocumentsTypesForm/types';
import { onGetOne, onSave } from 'redux/actions/documentsTypes.actions';
import { documentsTypesFormSelector } from 'redux/selectors/documentsTypes.selector';

import LayoutContainer from 'containers/LayoutContainer';
import DocumentsTypesForm from 'views/DocumentsTypesView/components/DocumentsTypesForm';
import Loading from 'components/commons/Loading';

import 'views/DocumentsTypesView/components/DocumentsTypesForm/i18n';

const DocumentsTypesFormContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id = '' } = useParams<{ id?: string }>();
  const initialValues = useTypedSelector(documentsTypesFormSelector);
  const { loading } = useTypedSelector((state) => state.documentsTypes);
  const isEditing = !!id;

  useEffect(() => {
    if (id) dispatch(onGetOne(id));
    return () => {
      dispatch(onGetOne(''));
    };
  }, [dispatch, id]);

  const onSubmit = (formData: IDocumentsTypesFormData) => dispatch(onSave(formData, id));

  return (
    <LayoutContainer auth title={i18next.t<string>('DOCUMENTS_TYPES_FORM:TITLE')}>
      <DocumentsTypesForm initialValues={initialValues} isEditing={isEditing} onSubmit={onSubmit} />
      {loading && (
        <Loading isModalMode message={i18next.t<string>('DOCUMENTS_TYPES_FORM:LOADING')} />
      )}
    </LayoutContainer>
  );
};

export default DocumentsTypesFormContainer;
