import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'DOCUMENTS_VIEW', {
  TITLE: 'Documents',
  NAME: 'Name',
  DOCUMENT_TYPE: 'Document type',
  FILE: 'File',
  DOWNLOAD_FILE: 'Download file',
  ACTIONS: 'Actions',
  SHOW: 'Show',
  EDIT: 'Edit',
  DELETE: 'Delete',
  DELETE_DIALOG_TITLE: 'Delete document',
  DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete the document {{ name }}?',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'DOCUMENTS_VIEW', {
  TITLE: 'Documentos',
  NAME: 'Nombre',
  DOCUMENT_TYPE: 'Tipo de documento',
  FILE: 'Archivo',
  DOWNLOAD_FILE: 'Descargar archivo',
  ACTIONS: 'Acciones',
  SHOW: 'Ver',
  EDIT: 'Editar',
  DELETE: 'Eliminar',
  DELETE_DIALOG_TITLE: 'Eliminar documento',
  DELETE_DIALOG_MESSAGE: 'Estás seguro de querer eliminar el documento {{ name }}?',
});
