import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import LayoutContainer from 'containers/LayoutContainer';
import DashboardView from 'views/DashboardView';

import 'views/DashboardView/i18n';

const DashboardContainer: FunctionComponent = () => (
  <LayoutContainer auth title={i18next.t<string>('DASHBOARD_VIEW:TITLE')}>
    <DashboardView />
  </LayoutContainer>
);

export default DashboardContainer;
