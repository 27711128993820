import { createSelector } from 'reselect';

import { IGravesState } from 'types/graves.types';

const getItems = (state: { graves: IGravesState }) => state.graves.items;
const getSelectedItem = (state: { graves: IGravesState }) => state.graves.selectedItem;
const getStates = (state: { graves: IGravesState }) => state.graves.states;

export const gravesSelector = createSelector([getItems], (graves) => graves);

export const gravesFormSelector = createSelector([getSelectedItem], (grave) => ({
  state: grave?.state || '',
  row: grave?.row.toString() || '',
  column: grave?.column.toString() || '',
  price: grave?.price.toString() || '',
  color: grave?.color || '#ffffff',
  person: grave?.person
    ? {
        label: `${grave.person.firstName} ${grave.person.lastName} (${grave.person.idCard})`,
        value: grave.person.id.toString(),
      }
    : undefined,
}));

export const gravesStatesSelector = createSelector([getStates], (states) =>
  Object.keys(states).map((key) => ({
    label: states[key],
    value: key,
  })),
);
