import { toast, ToastOptions } from 'react-toastify';

const options: ToastOptions = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'colored',
};

export const showErrorNotification = (message: string, override = {}): void => {
  toast.error(message, { ...options, ...override });
};

export const showInfoNotification = (message: string, override = {}): void => {
  toast.info(message, { ...options, ...override });
};

export const showSuccessNotification = (message: string, override = {}): void => {
  toast.success(message, { ...options, ...override });
};

export const showWarningNotification = (message: string, override = {}): void => {
  toast.warning(message, { ...options, ...override });
};
