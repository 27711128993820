import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { personsShowSelector } from 'redux/selectors/persons.selector';
import { onGetOne } from 'redux/actions/persons.actions';

import LayoutContainer from 'containers/LayoutContainer';
import PersonsShow from 'views/PersonsView/components/PersonsShow';
import Loading from 'components/commons/Loading';

import 'views/PersonsView/components/PersonsShow/i18n';

const PersonsShowContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id = '' } = useParams<{ id?: string }>();
  const { loading } = useTypedSelector((state) => state.persons);
  const item = useTypedSelector(personsShowSelector);

  useEffect(() => {
    if (id) dispatch(onGetOne(id));
    return () => {
      dispatch(onGetOne(''));
    };
  }, [dispatch, id]);

  return (
    <LayoutContainer auth title={i18next.t<string>('PERSONS_SHOW:TITLE')}>
      <PersonsShow item={item} />
      {loading && <Loading isModalMode message={i18next.t<string>('PERSONS_SHOW:LOADING')} />}
    </LayoutContainer>
  );
};

export default PersonsShowContainer;
