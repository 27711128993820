import React, { Fragment, FunctionComponent, useState } from 'react';
import { ArrowBackOutlined, EditOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import { history } from 'helpers/history';
import { FILE_TYPES } from 'helpers/validations/constants';
import { base64PDFToBlobUrl } from 'helpers/utils';

import {
  ViewContainer,
  ViewContent,
  TitleContainer,
  Title,
  TableContainer,
  CustomIconButton,
  FilePreview,
} from 'styles/view';

import { IProceduresShowProps } from './types';
import { DocumentsDropdown, PageLink } from './styles';
import './i18n';

const ProceduresShow: FunctionComponent<IProceduresShowProps> = (props: IProceduresShowProps) => {
  const { item } = props;
  const [selectedDocument, setSelectedDocument] = useState<string | string[]>('');

  const person = item?.person;
  const document = item?.documents?.find(({ id }) => id.toString() === selectedDocument);

  return (
    <ViewContainer auth data-testid="procedures-show">
      <ViewContent auth>
        <TitleContainer>
          <CustomIconButton onClick={history.goBack}>
            <ArrowBackOutlined />
          </CustomIconButton>
          <Title>{i18next.t<string>('PROCEDURES_SHOW:TITLE')}</Title>
          <CustomIconButton onClick={() => history.push(`/procedures/${item?.id}/edit`)}>
            <EditOutlined />
          </CustomIconButton>
        </TitleContainer>
        {item && (
          <>
            <TableContainer>
              <tbody>
                <tr>
                  <td>{i18next.t<string>('PROCEDURES_SHOW:ID')}</td>
                  <td>{item?.id}</td>
                </tr>
                <tr>
                  <td>{i18next.t<string>('PROCEDURES_SHOW:NAME')}</td>
                  <td>{item?.name}</td>
                </tr>
                <tr>
                  <td>{i18next.t<string>('PROCEDURES_SHOW:STATE')}</td>
                  <td>{item?.stateName}</td>
                </tr>
              </tbody>
            </TableContainer>
            {person && (
              <>
                <Title>{i18next.t<string>('PROCEDURES_SHOW:PERSON_TITLE')}</Title>
                <TableContainer>
                  <tbody>
                    <tr>
                      <td>{i18next.t<string>('PROCEDURES_SHOW:ID')}</td>
                      <td>{person?.id}</td>
                    </tr>
                    <tr>
                      <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_FIRST_NAME')}</td>
                      <td>{person?.firstName}</td>
                    </tr>
                    <tr>
                      <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_LAST_NAME')}</td>
                      <td>{person?.lastName}</td>
                    </tr>
                    {!!person?.lastNameMother && (
                      <tr>
                        <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_LAST_NAME_MOTHER')}</td>
                        <td>{person.lastNameMother}</td>
                      </tr>
                    )}
                    <tr>
                      <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_ID_CARD')}</td>
                      <td>{person?.idCard}</td>
                    </tr>
                    {!!person?.email && (
                      <tr>
                        <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_EMAIL')}</td>
                        <td>{person.email}</td>
                      </tr>
                    )}
                    {!!person?.phone && (
                      <tr>
                        <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_PHONE')}</td>
                        <td>{person.phone}</td>
                      </tr>
                    )}
                    <tr>
                      <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_CIVIL_STATE')}</td>
                      <td>{person?.civilStateName}</td>
                    </tr>
                    <tr>
                      <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_BIRTHDATE')}</td>
                      <td>{person?.birthdate}</td>
                    </tr>
                    <tr>
                      <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_ADDRESS')}</td>
                      <td>{person?.address}</td>
                    </tr>
                    <tr>
                      <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_TRIBE')}</td>
                      <td>{person?.tribeName}</td>
                    </tr>
                    <tr>
                      <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_COUNTRY')}</td>
                      <td>{person?.country}</td>
                    </tr>
                    {!!person?.image && (
                      <tr>
                        <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_IMAGE')}</td>
                        <td>
                          <a href={person.image} rel="noreferrer" target="_blank">
                            <img alt="preview" height="50" src={person.image} />
                          </a>
                        </td>
                      </tr>
                    )}
                    {!!person?.comments && (
                      <tr>
                        <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_COMMENTS')}</td>
                        <td>{person.comments}</td>
                      </tr>
                    )}
                    <tr>
                      <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_ALIVE')}</td>
                      <td>
                        {person?.alive
                          ? i18next.t<string>('PROCEDURES_SHOW:YES')
                          : i18next.t<string>('PROCEDURES_SHOW:NO')}
                      </td>
                    </tr>
                    {!person?.alive && !!person?.cementery && (
                      <tr>
                        <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_CEMENTERY')}</td>
                        <td>{person.cementery}</td>
                      </tr>
                    )}
                    {(!!person?.relations1?.length || !!person?.relations2?.length) && (
                      <>
                        <tr>
                          <td>
                            <strong>{i18next.t<string>('PROCEDURES_SHOW:PERSON_RELATIONS')}</strong>
                          </td>
                          <td />
                        </tr>
                        {!!person?.parents?.length && (
                          <tr>
                            <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_PARENTS')}</td>
                            <td>
                              {person.parents.map((parent, index) => (
                                <Fragment key={`parent-${parent.id}`}>
                                  <PageLink to={`/persons/${parent.id}/show`}>
                                    {`${parent.firstName} ${parent.lastName}`}
                                  </PageLink>
                                  {index === (parent.parents ?? []).length - 1 ? '' : ', '}
                                </Fragment>
                              ))}
                            </td>
                          </tr>
                        )}
                        {!!person?.childs?.length && (
                          <tr>
                            <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_CHILDS')}</td>
                            <td>
                              {person.childs.map((child, index) => (
                                <Fragment key={`child-${child.id}`}>
                                  <PageLink to={`/persons/${child.id}/show`}>
                                    {`${child.firstName} ${child.lastName}`}
                                  </PageLink>
                                  {index === (child.childs ?? []).length - 1 ? '' : ', '}
                                </Fragment>
                              ))}
                            </td>
                          </tr>
                        )}
                        {!!person?.siblings?.length && (
                          <tr>
                            <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_SIBLINGS')}</td>
                            <td>
                              {person.siblings.map((sibling, index) => (
                                <Fragment key={`sibling-${sibling.id}`}>
                                  <PageLink to={`/persons/${sibling.id}/show`}>
                                    {`${sibling.firstName} ${sibling.lastName}`}
                                  </PageLink>
                                  {index === (sibling.siblings ?? []).length - 1 ? '' : ', '}
                                </Fragment>
                              ))}
                            </td>
                          </tr>
                        )}
                        {!!person?.grandParents?.length && (
                          <tr>
                            <td>{i18next.t<string>('PROCEDURES_SHOW:PERSON_GRAND_PARENTS')}</td>
                            <td>
                              {person.grandParents.map((grandParent, index) => (
                                <Fragment key={`grandParent-${grandParent.id}`}>
                                  <PageLink to={`/persons/${grandParent.id}/show`}>
                                    {`${grandParent.firstName} ${grandParent.lastName}`}
                                  </PageLink>
                                  {index === (grandParent.grandParents ?? []).length - 1
                                    ? ''
                                    : ', '}
                                </Fragment>
                              ))}
                            </td>
                          </tr>
                        )}
                        {person?.relations1
                          ?.filter(({ personState }) => personState !== 'CHILD')
                          ?.map(({ id, personStateName, person2 }) => (
                            <tr key={id}>
                              <td>{personStateName}</td>
                              <td>
                                <PageLink to={`/persons/${person2?.id}/show`}>
                                  {`${person2?.firstName} ${person2?.lastName}`}
                                </PageLink>
                              </td>
                            </tr>
                          ))}
                        {person?.relations2
                          ?.filter(({ personState }) => personState !== 'CHILD')
                          ?.map(({ id, personStateName, person1 }) => (
                            <tr key={id}>
                              <td>{personStateName}</td>
                              <td>
                                <PageLink to={`/persons/${person1?.id}/show`}>
                                  {`${person1?.firstName} ${person1?.lastName}`}
                                </PageLink>
                              </td>
                            </tr>
                          ))}
                      </>
                    )}
                  </tbody>
                </TableContainer>
              </>
            )}
            {!!item?.documents?.length && (
              <>
                <Title>{i18next.t<string>('PROCEDURES_SHOW:DOCUMENT_TITLE')}</Title>
                <DocumentsDropdown
                  input={{
                    name: 'documents',
                    onChange: setSelectedDocument,
                    value: selectedDocument,
                  }}
                  label={i18next.t<string>('PROCEDURES_SHOW:DOCUMENTS')}
                  meta={{}}
                  options={item.documents.map(({ id, name }) => ({
                    label: name,
                    value: id.toString(),
                  }))}
                />
                {!!document && (
                  <>
                    <TableContainer>
                      <tbody>
                        <tr>
                          <td>{i18next.t<string>('PROCEDURES_SHOW:DOCUMENT_NAME')}</td>
                          <td>{document.name}</td>
                        </tr>
                        {!!document?.date && (
                          <tr>
                            <td>{i18next.t<string>('PROCEDURES_SHOW:DOCUMENT_DATE')}</td>
                            <td>{document.date}</td>
                          </tr>
                        )}
                        <tr>
                          <td>{i18next.t<string>('PROCEDURES_SHOW:DOCUMENT_TYPE')}</td>
                          <td>{document.documentType?.name}</td>
                        </tr>
                        <tr>
                          <td>{i18next.t<string>('PROCEDURES_SHOW:ACTION')}</td>
                          <td>
                            <PageLink to={`/documents/${document.id}/edit`}>
                              {i18next.t<string>('PROCEDURES_SHOW:EDIT_DOCUMENT')}
                            </PageLink>
                          </td>
                        </tr>
                      </tbody>
                    </TableContainer>
                    <FilePreview
                      src={
                        document.file.includes(FILE_TYPES.pdf)
                          ? base64PDFToBlobUrl(document.file)
                          : document.file
                      }
                      title="file"
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </ViewContent>
    </ViewContainer>
  );
};

export default ProceduresShow;
