import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'USERS_VIEW', {
  TITLE: 'Users',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  EMAIL: 'Email',
  ACTIONS: 'Actions',
  EDIT: 'Edit',
  DELETE: 'Delete',
  DELETE_DIALOG_TITLE: 'Delete user',
  DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete the user {{ name }}?',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'USERS_VIEW', {
  TITLE: 'Usuarios',
  FIRST_NAME: 'Nombre',
  LAST_NAME: 'Apellido',
  EMAIL: 'Email',
  ACTIONS: 'Acciones',
  EDIT: 'Editar',
  DELETE: 'Eliminar',
  DELETE_DIALOG_TITLE: 'Eliminar usuario',
  DELETE_DIALOG_MESSAGE: 'Estás seguro de querer eliminar al usuario {{ name }}?',
});
