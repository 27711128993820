import { AnyAction } from 'redux';

import * as constants from 'redux/constants/categoryPrices.constants';
import { ITableUpdate } from 'types/table.types';
import { ICategoryPricesFormData } from 'views/CategoryPricesView/components/CategoryPricesForm/types';

export const onDelete = (id: string): AnyAction => ({
  type: constants.CATEGORY_PRICES_ON_DELETE_REQUESTED,
  id,
});

export const onGetAll = (data: ITableUpdate): AnyAction => ({
  type: constants.CATEGORY_PRICES_ON_GET_ALL_REQUESTED,
  ...data,
});

export const onGetForm = (): AnyAction => ({
  type: constants.CATEGORY_PRICES_ON_GET_FORM_REQUESTED,
});

export const onGetOne = (id: string): AnyAction => ({
  type: constants.CATEGORY_PRICES_ON_GET_ONE_REQUESTED,
  id,
});

export const onSave = (formData: ICategoryPricesFormData, id?: string): AnyAction => ({
  type: constants.CATEGORY_PRICES_ON_SAVE_REQUESTED,
  formData,
  id,
});
