import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'PROCEDURES_URL_FORM', {
  PERSON_TITLE: 'Person information',
  PERSON_FIRST_NAME: 'First name',
  PERSON_LAST_NAME: 'Last name',
  PERSON_LAST_NAME_MOTHER: "Mother's last name",
  PERSON_ID_CARD: 'ID card',
  PERSON_EMAIL: 'Email',
  PERSON_PHONE: 'Phone',
  PERSON_CIVIL_STATE: 'Civil state',
  PERSON_BIRTHDATE: 'Birthdate',
  PERSON_ADDRESS: 'Address',
  PERSON_TRIBE: 'Tribe',
  PERSON_COUNTRY: 'Country',
  PERSON_IMAGE: 'Image',
  PERSON_ALIVE: 'Is alive?',
  PERSON_CEMENTERY: 'Cementery',
  PERSON_RELATION: 'Person relation',
  PERSON: 'Person',
  PERSON_STATE: 'Person state',
  PERSON_RELATION_BUTTON: 'Add person relation',
  DOCUMENT_TITLE: 'Document information',
  DOCUMENT_BUTTON: 'Add documento',
  DOCUMENT: 'Document',
  DOCUMENT_NAME: 'Name',
  DOCUMENT_DATE: 'Date',
  DOCUMENT_TYPE: 'Document type',
  DOCUMENT_FILE: 'File',
  DOCUMENT_ADD_PERSON: 'Add person',
  SUBMIT_BUTTON: 'Save',
  LOADING: 'Loading...',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'PROCEDURES_URL_FORM', {
  PERSON_TITLE: 'Información de la persona',
  PERSON_FIRST_NAME: 'Nombre',
  PERSON_LAST_NAME: 'Apellido',
  PERSON_LAST_NAME_MOTHER: 'Apellido materno',
  PERSON_ID_CARD: 'DNI',
  PERSON_EMAIL: 'Email',
  PERSON_PHONE: 'Celular',
  PERSON_CIVIL_STATE: 'Estado civil',
  PERSON_BIRTHDATE: 'Fecha de nacimiento',
  PERSON_ADDRESS: 'Domicilio',
  PERSON_TRIBE: 'Tribu',
  PERSON_COUNTRY: 'País',
  PERSON_IMAGE: 'Imagen',
  PERSON_ALIVE: 'Está vivo?',
  PERSON_CEMENTERY: 'Cementerio',
  PERSON_RELATION: 'Relación personal',
  PERSON: 'Persona',
  PERSON_STATE: 'Estado de la persona',
  PERSON_RELATION_BUTTON: 'Agregar relación personal',
  DOCUMENT_TITLE: 'Información de los documentos',
  DOCUMENT_BUTTON: 'Agregar documento',
  DOCUMENT: 'Documento',
  DOCUMENT_NAME: 'Nombre',
  DOCUMENT_DATE: 'Fecha',
  DOCUMENT_TYPE: 'Tipo de documento',
  DOCUMENT_FILE: 'Archivo',
  DOCUMENT_ADD_PERSON: 'Agregar persona',
  SUBMIT_BUTTON: 'Guardar',
  LOADING: 'Cargando...',
});
