import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { useQuery } from 'hooks/useQuery';
import { IProceduresUrlFormData } from 'views/ProceduresView/components/ProceduresUrlForm/types';
import { onSaveUrlForm, onValidateUrlForm } from 'redux/actions/procedures.actions';
import {
  proceduresCivilStatesSelector,
  proceduresDocumentTypesSelector,
  proceduresPersonStatesSelector,
  proceduresTribesSelector,
  proceduresFormUrlSelector,
  proceduresFilesSelector,
} from 'redux/selectors/procedures.selector';

import ProceduresUrlForm from 'views/ProceduresView/components/ProceduresUrlForm';
import Loading from 'components/commons/Loading';

import 'views/ProceduresView/components/ProceduresUrlForm/i18n';

const ProceduresUrlFormContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id = '' } = useParams<{ id?: string }>();
  const initialValues = useTypedSelector(proceduresFormUrlSelector);
  const { isValidUrlForm, loading, selectedItem } = useTypedSelector((state) => state.procedures);
  const civilStates = useTypedSelector(proceduresCivilStatesSelector);
  const documentTypes = useTypedSelector(proceduresDocumentTypesSelector);
  const personStates = useTypedSelector(proceduresPersonStatesSelector);
  const tribes = useTypedSelector(proceduresTribesSelector);
  const files = useTypedSelector(proceduresFilesSelector);
  const query = useQuery();
  const documents = selectedItem?.documents || [];

  useEffect(() => {
    dispatch(onValidateUrlForm(id, `${query.get('expires')}`, `${query.get('signature')}`));
  }, [dispatch, id, query]);

  const onSubmit = (formData: IProceduresUrlFormData) =>
    dispatch(onSaveUrlForm(id, `${query.get('expires')}`, `${query.get('signature')}`, formData));

  return (
    <>
      <ProceduresUrlForm
        civilStates={civilStates}
        documents={documents}
        documentTypes={documentTypes}
        files={files}
        initialValues={initialValues}
        isValidUrlForm={isValidUrlForm}
        onSubmit={onSubmit}
        personStates={personStates}
        tribes={tribes}
      />
      {loading && <Loading isModalMode message={i18next.t<string>('PROCEDURES_FORM:LOADING')} />}
    </>
  );
};

export default ProceduresUrlFormContainer;
