import { AnyAction } from 'redux';

import * as constants from 'redux/constants/graves.constants';
import { ITableUpdate } from 'types/table.types';
import { IGravesFormData } from 'views/GravesView/components/GravesForm/types';

export const onDelete = (cementeryId: string, blockId: string, id: string): AnyAction => ({
  type: constants.GRAVES_ON_DELETE_REQUESTED,
  cementeryId,
  blockId,
  id,
});

export const onGetAll = (cementeryId: string, blockId: string, data: ITableUpdate): AnyAction => ({
  type: constants.GRAVES_ON_GET_ALL_REQUESTED,
  cementeryId,
  blockId,
  ...data,
});

export const onGetForm = (cementeryId: string, blockId: string): AnyAction => ({
  type: constants.GRAVES_ON_GET_FORM_REQUESTED,
  cementeryId,
  blockId,
});

export const onGetOne = (cementeryId: string, blockId: string, id: string): AnyAction => ({
  type: constants.GRAVES_ON_GET_ONE_REQUESTED,
  cementeryId,
  blockId,
  id,
});

export const onSave = (
  cementeryId: string,
  blockId: string,
  formData: IGravesFormData,
  id?: string,
): AnyAction => ({
  type: constants.GRAVES_ON_SAVE_REQUESTED,
  cementeryId,
  blockId,
  formData,
  id,
});
