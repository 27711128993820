import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'ROLES_FORM', {
  TITLE: 'Roles',
  NAME: 'Name',
  GUARD_NAME: 'Guard name',
  PERMISSIONS: 'Permissions',
  SUBMIT_BUTTON: 'Save',
  LOADING: 'Loading...',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'ROLES_FORM', {
  TITLE: 'Roles',
  NAME: 'Nombre',
  GUARD_NAME: 'Nombre de la guardia',
  PERMISSIONS: 'Permisos',
  SUBMIT_BUTTON: 'Guardar',
  LOADING: 'Cargando...',
});
