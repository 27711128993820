import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { CssBaseline } from '@mui/material';

import { onInitialize } from 'redux/actions/app.actions';

import ScrollToTopContainer from 'containers/ScrollToTopContainer';
import NavigationContainer from 'containers/NavigationContainer';

import { GlobalStyle } from 'styles/global';

import 'react-toastify/dist/ReactToastify.css';

const App: FunctionComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onInitialize());
  }, [dispatch]);

  return (
    <>
      <GlobalStyle />
      <CssBaseline />
      <ScrollToTopContainer />
      <ToastContainer />
      <NavigationContainer />
    </>
  );
};

export default App;
