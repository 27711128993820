import { AnyAction } from 'redux';

import * as constants from 'redux/constants/users.constants';
import { ITableUpdate } from 'types/table.types';
import { IUsersFormData } from 'views/UsersView/components/UsersForm/types';

export const onDelete = (id: string): AnyAction => ({
  type: constants.USERS_ON_DELETE_REQUESTED,
  id,
});

export const onGetAll = (data: ITableUpdate): AnyAction => ({
  type: constants.USERS_ON_GET_ALL_REQUESTED,
  ...data,
});

export const onGetForm = (): AnyAction => ({
  type: constants.USERS_ON_GET_FORM_REQUESTED,
});

export const onGetOne = (id: string): AnyAction => ({
  type: constants.USERS_ON_GET_ONE_REQUESTED,
  id,
});

export const onSave = (formData: IUsersFormData, id?: string): AnyAction => ({
  type: constants.USERS_ON_SAVE_REQUESTED,
  formData,
  id,
});
