import { AxiosResponse } from 'axios';

import { get, post, put, remove } from 'services/api';
import { IGravesFormData } from 'views/GravesView/components/GravesForm/types';

export const getAll = (
  cementeryId: number,
  blockId: number,
  limit: number,
  page: number,
  search: string,
  sortKey: string,
  sortType: string,
): Promise<AxiosResponse> =>
  get(
    `cementeries/${cementeryId}/blocks/${blockId}/graves?limit=${limit}&page=${page}&search=${search}&sortKey=${sortKey}&sortType=${sortType}`,
  );

export const getForm = (cementeryId: number, blockId: number): Promise<AxiosResponse> =>
  get(`cementeries/${cementeryId}/blocks/${blockId}/graves/form`);

export const getOne = (cementeryId: number, blockId: number, id: string): Promise<AxiosResponse> =>
  get(`cementeries/${cementeryId}/blocks/${blockId}/graves/${id}`);

export const save = (
  cementeryId: number,
  blockId: number,
  data: IGravesFormData,
  id: string,
): Promise<AxiosResponse> => {
  const formData = { ...data, person_id: data.person?.value || null };
  return !id
    ? post(`cementeries/${cementeryId}/blocks/${blockId}/graves`, formData)
    : put(`cementeries/${cementeryId}/blocks/${blockId}/graves/${id}`, formData);
};

export const softDelete = (
  cementeryId: number,
  blockId: number,
  id: string,
): Promise<AxiosResponse> => remove(`cementeries/${cementeryId}/blocks/${blockId}/graves/${id}`);
