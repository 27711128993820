import React, { FunctionComponent, SyntheticEvent, useState } from 'react';
import { ChevronRight } from '@mui/icons-material';

import Logo from 'components/commons/Logo';

import { ISidebarItem, ISidebarProps } from './types';
import {
  SidebarContainer,
  SidebarContent,
  SidebarToggleMenu,
  SidebarCloseButton,
  SidebarItem,
  SidebarItemArrow,
  SidebarItemSubMenu,
  SidebarOverlay,
} from './styles';

const MenuItem = ({ isMenuOpened, item }: { isMenuOpened: boolean; item: ISidebarItem }) => {
  const [showSubItems, setShowSubItems] = useState(false);
  const hasSubItems = !!item.items?.length;

  const onMenuItemClick = (event: SyntheticEvent) => {
    if (hasSubItems) {
      event.preventDefault();
      setShowSubItems(!showSubItems);
    }
  };

  return (
    <li key={item.key}>
      <SidebarItem
        activeClassName="active"
        data-testid={`sidebar-nav-link-${item.key}`}
        onClick={onMenuItemClick}
        open={isMenuOpened}
        to={item.to}
      >
        {item.icon && <item.icon />}
        <span>{item.label}</span>
        {hasSubItems && (
          <SidebarItemArrow rotated={showSubItems}>
            <ChevronRight />
          </SidebarItemArrow>
        )}
      </SidebarItem>
      <SidebarItemSubMenu show={showSubItems}>
        {item.items?.map((subItem) => (
          <li key={subItem.key}>
            <SidebarItem
              activeClassName="active"
              data-testid={`sidebar-nav-link-${subItem.key}`}
              isSubItem
              onClick={() => setShowSubItems(false)}
              open={isMenuOpened}
              to={subItem.to}
            >
              {subItem.icon && <subItem.icon />}
              <span>{subItem.label}</span>
            </SidebarItem>
          </li>
        ))}
      </SidebarItemSubMenu>
    </li>
  );
};

const Sidebar: FunctionComponent<ISidebarProps> = (props: ISidebarProps) => {
  const { isMenuOpened, isMobile, items, onCloseMenu } = props;

  return (
    <div data-testid="sidebar">
      <SidebarContainer open={isMenuOpened}>
        <SidebarContent>
          <SidebarToggleMenu key="menu">
            <Logo />
            {isMobile && (
              <SidebarCloseButton data-testid="sidebar-close-button" onClick={onCloseMenu} />
            )}
          </SidebarToggleMenu>
          {items.map((item) => (
            <MenuItem isMenuOpened={isMenuOpened} item={item} key={item.label} />
          ))}
        </SidebarContent>
      </SidebarContainer>
      {isMobile && isMenuOpened && (
        <SidebarOverlay data-testid="sidebar-overlay" onClick={onCloseMenu} />
      )}
    </div>
  );
};

export default Sidebar;
