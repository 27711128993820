import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'PERSONS_VIEW', {
  TITLE: 'Persons',
  FIRST_NAME: 'First name',
  LAST_NAME: 'Last name',
  ID_CARD: 'ID card',
  ACTIONS: 'Actions',
  SHOW: 'Show',
  EDIT: 'Edit',
  DELETE: 'Delete',
  DELETE_DIALOG_TITLE: 'Delete person',
  DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete the person {{ name }}?',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'PERSONS_VIEW', {
  TITLE: 'Personas',
  FIRST_NAME: 'Nombre',
  LAST_NAME: 'Apellido',
  ID_CARD: 'DNI',
  ACTIONS: 'Acciones',
  SHOW: 'Ver',
  EDIT: 'Editar',
  DELETE: 'Eliminar',
  DELETE_DIALOG_TITLE: 'Eliminar persona',
  DELETE_DIALOG_MESSAGE: 'Estás seguro de querer eliminar la persona {{ name }}?',
});
