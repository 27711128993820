import React, { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { onDelete, onGetAll } from 'redux/actions/categoryPrices.actions';
import { categoryPricesSelector } from 'redux/selectors/categoryPrices.selector';
import { history } from 'helpers/history';
import { COLORS } from 'helpers/enums/colors';

import LayoutContainer from 'containers/LayoutContainer';
import CategoryPricesView from 'views/CategoryPricesView';
import ActionButtons from 'components/commons/Table/components/ActionButtons';
import Dialog from 'components/commons/Dialog';

import { ICategoryPrice } from 'types/categoryPrices.types';
import { ITableUpdate } from 'components/commons/Table/types';

import 'views/CategoryPricesView/i18n';

const CategoryPricesContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [deleteItem, setDeleteItem] = useState<ICategoryPrice | undefined>();
  const { count, loading, table } = useTypedSelector((state) => state.categoryPrices);
  const items = useTypedSelector(categoryPricesSelector);

  const actions = [
    {
      label: i18next.t<string>('CATEGORY_PRICES_VIEW:EDIT'),
      onClick: (id: string) => history.push(`/category-prices/${id}/edit`),
    },
    {
      label: i18next.t<string>('CATEGORY_PRICES_VIEW:DELETE'),
      onClick: (id: string) =>
        setDeleteItem(items.find((item: ICategoryPrice) => item.id === Number(id))),
      color: COLORS.redMonza,
    },
  ];

  const columns = [
    {
      name: 'name',
      label: i18next.t<string>('CATEGORY_PRICES_VIEW:NAME'),
    },
    {
      name: 'price',
      label: i18next.t<string>('CATEGORY_PRICES_VIEW:PRICE'),
    },
    {
      name: 'id',
      label: i18next.t<string>('CATEGORY_PRICES_VIEW:ACTIONS'),
      options: {
        customBodyRender: (value: string) => <ActionButtons id={value} items={actions} />,
        sort: false,
      },
    },
  ];

  const onTableUpdate = useCallback((data: ITableUpdate) => dispatch(onGetAll(data)), [dispatch]);

  const onDeleteClick = (isConfirmed = false) => {
    if (isConfirmed && deleteItem) dispatch(onDelete(deleteItem.id.toString()));
    setDeleteItem(undefined);
  };

  return (
    <LayoutContainer auth title={i18next.t<string>('CATEGORY_PRICES_VIEW:TITLE')}>
      <CategoryPricesView
        columns={columns}
        count={count}
        data={items}
        loading={loading}
        onAddClick={() => history.push('/category-prices/create')}
        onTableUpdate={onTableUpdate}
        table={table}
      />
      <Dialog
        message={i18next.t<string>('CATEGORY_PRICES_VIEW:DELETE_DIALOG_MESSAGE', {
          name: deleteItem?.id,
        })}
        onCancelClick={() => onDeleteClick()}
        onConfirmClick={() => onDeleteClick(true)}
        open={!!deleteItem}
        title={i18next.t<string>('CATEGORY_PRICES_VIEW:DELETE_DIALOG_TITLE')}
      />
    </LayoutContainer>
  );
};

export default CategoryPricesContainer;
