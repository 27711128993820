import styled from 'styled-components';
import { FormControl, MenuItem } from '@mui/material';

import { COLORS } from 'helpers/enums/colors';

export const CustomFormControl = styled(FormControl)`
  && {
    width: 100%;
    height: 100%;
    margin-bottom: 0px !important;

    label {
      color: ${({ error }) => (error ? COLORS.redMonza : COLORS.grayManatee)};
      font-family: 'Montserrat', Helvetica, Arial, serif;
      font-size: 16px;
      left: 10px;

      span {
        display: none;
      }
    }

    .MuiFormLabel-filled {
      color: ${({ error }) => (error ? COLORS.redMonza : COLORS.grayLynch)};
      font-size: 12px;
      font-weight: 700;
    }

    .MuiInputBase-input {
      padding: 21px 20px 8px;
      background-color: ${COLORS.white} !important;
      color: ${COLORS.grayCloudBurst};
      font-family: 'Montserrat', Helvetica, Arial, serif;
      font-size: 18px;
      border: 2px solid ${({ error }) => (error ? COLORS.redPersian : COLORS.grayManatee)};
      border-radius: 6px;
      text-transform: capitalize;
    }

    .MuiInputBase-input.Mui-disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    .MuiFilledInput-root {
      :before,
      :after {
        content: none;
      }
    }
  }
`;

export const CustomMenuItem = styled(MenuItem)`
  && {
    font-size: 16px;
    color: ${COLORS.black};
    text-transform: capitalize;
  }
`;
