import React, { FunctionComponent } from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { IconButton } from '@mui/material';
import { ArrowBackOutlined, DeleteOutlined, DeleteOutlineOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import { history } from 'helpers/history';
import { composeValidators, fileValidation, requiredValidation } from 'helpers/validations';
import { getAll } from 'services/persons.services';

import Input from 'components/inputs/Input';
import SelectInput from 'components/inputs/SelectInput';
import AutoComplete from 'components/inputs/AutoComplete';

import { ViewContainer, ViewContent, TitleContainer, Title, CustomIconButton } from 'styles/view';
import {
  FormContent,
  FormButtonContainer,
  FormSubmitButton,
  FormRowContainer,
  FormAddCombinationButton,
} from 'styles/form';

import { IPerson } from 'types/persons.types';
import { IDocumentsFormProps } from './types';
import './i18n';

const personsField = {
  person: '',
};

const DocumentsForm: FunctionComponent<IDocumentsFormProps> = (props: IDocumentsFormProps) => {
  const { documentTypes, files, initialValues, isEditing, onDelete, onSubmit } = props;

  return (
    <ViewContainer auth data-testid="documents-form">
      <ViewContent auth>
        <TitleContainer>
          <CustomIconButton onClick={history.goBack}>
            <ArrowBackOutlined />
          </CustomIconButton>
          <Title>{i18next.t<string>('DOCUMENTS_FORM:TITLE')}</Title>
          {isEditing ? (
            <CustomIconButton danger onClick={onDelete}>
              <DeleteOutlineOutlined />
            </CustomIconButton>
          ) : (
            <div />
          )}
        </TitleContainer>
        <Form
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          onSubmit={onSubmit}
          render={({
            form: {
              mutators: { push, remove },
            },
            handleSubmit,
            submitting,
            pristine,
          }) => (
            <FormContent onSubmit={handleSubmit}>
              <Field
                label={i18next.t<string>('DOCUMENTS_FORM:NAME')}
                name="name"
                render={Input}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('DOCUMENTS_FORM:DATE')}
                name="date"
                parse={(value) => value}
                render={Input}
                type="date"
              />
              <Field
                label={i18next.t<string>('DOCUMENTS_FORM:DOCUMENT_TYPE')}
                name="documentTypeId"
                options={documentTypes}
                render={SelectInput}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('DOCUMENTS_FORM:FILE')}
                name="file"
                preview={files?.file}
                render={Input}
                type="file"
                validate={
                  isEditing ? fileValidation : composeValidators(requiredValidation, fileValidation)
                }
              />
              <FieldArray name="persons">
                {({ fields }) =>
                  fields.map((name, i) => (
                    <div key={name}>
                      <FormRowContainer>
                        <Field
                          label={`${i18next.t<string>('DOCUMENTS_FORM:PERSON')} ${i + 1}`}
                          name={`${name}.person`}
                          render={(customProps) => (
                            <AutoComplete
                              {...customProps}
                              apiCall={(search) => getAll(10, 1, search, 'firstName', 'asc')}
                              apiCallKey="items"
                              itemLabel={(item: IPerson) =>
                                `${item.firstName} ${item.lastName} (${item.idCard})`
                              }
                              itemValue="id"
                            />
                          )}
                          validate={requiredValidation}
                        />
                        <IconButton onClick={() => remove('persons', i)} style={{ height: 60 }}>
                          <DeleteOutlined />
                        </IconButton>
                      </FormRowContainer>
                    </div>
                  ))
                }
              </FieldArray>
              <FormAddCombinationButton
                onClick={() => push('persons', personsField)}
                variant="outlined"
              >
                {i18next.t<string>('DOCUMENTS_FORM:ADD_PERSON')}
              </FormAddCombinationButton>
              <FormButtonContainer>
                <FormSubmitButton disabled={pristine || submitting} type="submit">
                  {i18next.t<string>('DOCUMENTS_FORM:SUBMIT_BUTTON')}
                </FormSubmitButton>
              </FormButtonContainer>
            </FormContent>
          )}
        />
      </ViewContent>
    </ViewContainer>
  );
};

export default DocumentsForm;
