import React, { FunctionComponent } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import { history } from 'helpers/history';

import { CustomIconButton } from 'styles/view';

import { ReactComponent as GraveIcon } from './assets/grave.svg';

import { IBlocksGravesGridProps } from './types';
import { GravesContainer, GravesRowContainer, GraveContainer } from './styles';

import './i18n';

const BlocksGravesGrid: FunctionComponent<IBlocksGravesGridProps> = (
  props: IBlocksGravesGridProps,
) => {
  const { blockId, cementeryId, columns, graves, rows } = props;

  const onAddClick = () =>
    history.push(`/cementeries/${cementeryId}/blocks/${blockId}/graves/create`);

  const onGraveClick = (graveId: number) =>
    history.push(`/cementeries/${cementeryId}/blocks/${blockId}/graves/${graveId}/edit`);

  return (
    <GravesContainer>
      <thead>
        <GravesRowContainer>
          <th>
            <CustomIconButton nomarginbottom onClick={onAddClick}>
              <AddCircleOutlineOutlined />
            </CustomIconButton>
          </th>
          {columns.map((column) => (
            <th>C{column}</th>
          ))}
        </GravesRowContainer>
      </thead>
      <tbody>
        {rows.map((row) => (
          <GravesRowContainer>
            <td>F{row}</td>
            {columns.map((column) => {
              const grave = graves[row].find((item) => item.column === Number(column));
              return grave ? (
                <GraveContainer
                  color={grave.color}
                  data-tooltip-id={`grave-${grave.id}-tooltip`}
                  key={`grave-row-${grave.row}-column-${grave.column}`}
                >
                  <Tooltip
                    arrow
                    placement="top"
                    title={
                      <>
                        {i18next.t<string>('BLOCKS_GRAVES_VIEW:PRICE')}: ${grave.price}
                        <br />
                        {i18next.t<string>('BLOCKS_GRAVES_VIEW:STATE')}: {grave.stateName}
                        <br />
                        {!!grave.person &&
                          `${i18next.t<string>('BLOCKS_GRAVES_VIEW:PERSON')}: ${
                            grave.person.firstName
                          } ${grave.person.lastName}`}
                      </>
                    }
                  >
                    <IconButton data-testid="grave-button" onClick={() => onGraveClick(grave.id)}>
                      <GraveIcon />
                    </IconButton>
                  </Tooltip>
                </GraveContainer>
              ) : (
                <td> </td>
              );
            })}
          </GravesRowContainer>
        ))}
      </tbody>
    </GravesContainer>
  );
};

export default BlocksGravesGrid;
