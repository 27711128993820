import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { COLORS } from 'helpers/enums/colors';

import SelectInput from 'components/inputs/SelectInput';

export const PageLink = styled(Link)`
  && {
    color: ${COLORS.blueRibbon};
  }
`;

export const DocumentsDropdown = styled(SelectInput)`
  && {
    margin-top: 24px;
  }
`;
