import { IUser } from 'types/users.types';

export const ROLES = {
  superadmin: 'superadmin',
  rabinato: 'rabinato',
  directorio: 'directorio',
};

export const isSuperAdminRole = (user: IUser | null) =>
  !!user?.roles?.find(({ name }) => name === ROLES.superadmin);

export const isRabinatoRole = (user: IUser | null) =>
  !!user?.roles?.find(({ name }) => name === ROLES.rabinato);

export const isDirectorioRole = (user: IUser | null) =>
  !!user?.roles?.find(({ name }) => name === ROLES.directorio);
