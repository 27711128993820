import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { IBlocksFormData } from 'views/BlocksView/components/BlocksForm/types';
import { onGetOne as onGetOneCementery } from 'redux/actions/cementeries.actions';
import { onGetForm, onGetOne, onSave } from 'redux/actions/blocks.actions';
import {
  blocksCategoryPricesSelector,
  blocksFormSelector,
  blocksGravesByRowSelector,
  blocksGravesColumnsSelector,
  blocksGravesRowsSelector,
} from 'redux/selectors/blocks.selector';

import LayoutContainer from 'containers/LayoutContainer';
import BlocksForm from 'views/BlocksView/components/BlocksForm';
import Loading from 'components/commons/Loading';

import 'views/BlocksView/components/BlocksForm/i18n';

const BlocksFormContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { cementeryId = '', id = '' } = useParams<{ cementeryId?: string; id?: string }>();
  const initialValues = useTypedSelector(blocksFormSelector);
  const { loading } = useTypedSelector((state) => state.blocks);
  const cementery = useTypedSelector((state) => state.cementeries.selectedItem);
  const categoryPrices = useTypedSelector(blocksCategoryPricesSelector);
  const graves = useTypedSelector(blocksGravesByRowSelector);
  const columns = useTypedSelector(blocksGravesColumnsSelector);
  const rows = useTypedSelector(blocksGravesRowsSelector);
  const isEditing = !!id;

  useEffect(() => {
    if (cementeryId) dispatch(onGetOneCementery(cementeryId));
    return () => {
      dispatch(onGetOneCementery(''));
    };
  }, [dispatch, cementeryId]);

  useEffect(() => {
    dispatch(onGetForm(cementeryId));
    if (id) dispatch(onGetOne(cementeryId, id));
    return () => {
      dispatch(onGetOne(cementeryId, ''));
    };
  }, [dispatch, cementeryId, id]);

  const onSubmit = (formData: IBlocksFormData) => dispatch(onSave(cementeryId, formData, id));

  return (
    <LayoutContainer auth title={cementery?.name || i18next.t<string>('BLOCKS_FORM:TITLE')}>
      <BlocksForm
        blockId={id}
        categoryPrices={categoryPrices}
        cementeryId={cementeryId}
        columns={columns}
        graves={graves}
        initialValues={initialValues}
        isEditing={isEditing}
        onSubmit={onSubmit}
        rows={rows}
      />
      {loading && <Loading isModalMode message={i18next.t<string>('BLOCKS_FORM:LOADING')} />}
    </LayoutContainer>
  );
};

export default BlocksFormContainer;
