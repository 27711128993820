import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { IUsersFormData } from 'views/UsersView/components/UsersForm/types';
import { onGetForm, onGetOne, onSave } from 'redux/actions/users.actions';
import { userSelector } from 'redux/selectors/auth.selector';
import { usersFormSelector, usersRolesSelector } from 'redux/selectors/users.selector';

import LayoutContainer from 'containers/LayoutContainer';
import UsersForm from 'views/UsersView/components/UsersForm';
import Loading from 'components/commons/Loading';

import 'views/UsersView/components/UsersForm/i18n';

const UsersFormContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { id = '' } = useParams<{ id?: string }>();
  const initialValues = useTypedSelector(usersFormSelector);
  const { loading } = useTypedSelector((state) => state.users);
  const roles = useTypedSelector(usersRolesSelector);
  const user = useTypedSelector(userSelector);
  const isEditing = !!id;
  const isEditingCurrentUser = user?.id === Number(id);

  useEffect(() => {
    dispatch(onGetForm());
    if (id) dispatch(onGetOne(id));
    return () => {
      dispatch(onGetOne(''));
    };
  }, [dispatch, id]);

  const onSubmit = (formData: IUsersFormData) => dispatch(onSave(formData, id));

  return (
    <LayoutContainer auth title={i18next.t<string>('USERS_FORM:TITLE')}>
      <UsersForm
        initialValues={initialValues}
        isEditing={isEditing}
        isEditingCurrentUser={isEditingCurrentUser}
        onSubmit={onSubmit}
        roles={roles}
      />
      {loading && <Loading isModalMode message={i18next.t<string>('USERS_FORM:LOADING')} />}
    </LayoutContainer>
  );
};

export default UsersFormContainer;
