import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'PROCEDURE_URL_FORM_SUCCESS_VIEW', {
  TITLE: 'Procedure sent successfully',
  BUTTON: 'Go to the form',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'PROCEDURE_URL_FORM_SUCCESS_VIEW', {
  TITLE: 'Trámite enviado con éxito',
  BUTTON: 'Volver al formulario',
});
