import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'BLOCKS_FORM', {
  TITLE: 'Blocks',
  NAME: 'Name',
  PRICE: 'Price',
  ROW: 'Row',
  AMOUNT_OF_COLUMNS: 'Amount of columns',
  ADD_ROW: 'Add row',
  SUBMIT_BUTTON: 'Save',
  LOADING: 'Loading...',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'BLOCKS_FORM', {
  TITLE: 'Manzanas',
  NAME: 'Nombre',
  PRICE: 'Precio',
  ROW: 'Fila',
  AMOUNT_OF_COLUMNS: 'Cantidad de columnas',
  ADD_ROW: 'Agregar fila',
  SUBMIT_BUTTON: 'Guardar',
  LOADING: 'Cargando...',
});
