import { AxiosResponse } from 'axios';

import { get, post, remove } from 'services/api';
import { IDocumentsFormData } from 'views/DocumentsView/components/DocumentsForm/types';

export const getAll = (
  limit: number,
  page: number,
  search: string,
  sortKey: string,
  sortType: string,
): Promise<AxiosResponse> =>
  get(
    `documents?limit=${limit}&page=${page}&search=${search}&sortKey=${sortKey}&sortType=${sortType}`,
  );

export const getForm = (): Promise<AxiosResponse> => get('documents/form');

export const getOne = (id: string): Promise<AxiosResponse> => get(`documents/${id}`);

export const save = (data: IDocumentsFormData, id: string): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('name', data.name);
  if (data.date) formData.append('date', data.date);
  formData.append('document_type_id', data.documentTypeId.toString());
  if (data.file) formData.append('file', data.file);
  if (data.persons) {
    formData.append('persons', data.persons.map(({ person }) => person.value).join(','));
  }
  if (id) formData.append('_method', 'PUT');
  return post(`documents${!id ? '' : `/${id}`}`, formData, { transformRequest: () => formData });
};

export const softDelete = (id: string): Promise<AxiosResponse> => remove(`documents/${id}`);
