import React, { FunctionComponent, useLayoutEffect } from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Divider, IconButton } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import {
  composeValidators,
  emailValidation,
  fileValidation,
  phoneNumberValidation,
  requiredValidation,
} from 'helpers/validations';
import { base64ToFile, getBase64FilenameWithExtension } from 'helpers/utils';
import { getAll } from 'services/persons.services';

import Input from 'components/inputs/Input';
import SelectInput from 'components/inputs/SelectInput';
import Checkbox from 'components/inputs/Checkbox';
import AutoComplete from 'components/inputs/AutoComplete';

import { ViewContainer, ViewContent, Title } from 'styles/view';
import {
  FormContent,
  FormFieldset,
  FormButtonContainer,
  FormSubmitButton,
  FormRowContainer,
  FormAddCombinationButton,
} from 'styles/form';

import { IPerson } from 'types/persons.types';
import { IProceduresUrlFormProps } from './types';
import './i18n';

declare global {
  interface Window {
    setFormValue: Function;
  }
}

const relationsField = {
  person: '',
  personState: '',
};

const documentsField = {
  persons: [],
};

const documentPersonsField = {
  person: '',
};

const ProceduresUrlForm: FunctionComponent<IProceduresUrlFormProps> = (
  props: IProceduresUrlFormProps,
) => {
  const {
    civilStates,
    documents,
    documentTypes,
    files,
    initialValues,
    isValidUrlForm,
    onSubmit,
    personStates,
    tribes,
  } = props;

  useLayoutEffect(() => {
    setTimeout(() => {
      documents.forEach(({ file: base64, name }, i) => {
        const file = base64ToFile(base64, name);
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        const input = document.getElementById(`documents[${i}].file`) as HTMLInputElement;
        if (input) {
          input.files = dataTransfer.files;
          if (window.setFormValue) window.setFormValue(`documents[${i}].file`, file);
        }
      });
    }, 0);
  }, [documents]);

  return (
    <ViewContainer auth data-testid="procedures-url-form">
      <ViewContent auth>
        <Title>{i18next.t<string>('PROCEDURES_URL_FORM:PERSON_TITLE')}</Title>
        <Form
          initialValues={initialValues}
          mutators={{
            ...arrayMutators,
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value);
            },
          }}
          onSubmit={onSubmit}
          render={({
            form: {
              mutators: { push, remove, setValue },
            },
            handleSubmit,
            submitting,
            pristine,
            values,
          }) => {
            if (!window.setFormValue) window.setFormValue = setValue;
            return (
              <FormContent onSubmit={handleSubmit}>
                <FormFieldset disabled={!isValidUrlForm}>
                  <Field
                    label={i18next.t<string>('PROCEDURES_URL_FORM:PERSON_FIRST_NAME')}
                    name="firstName"
                    render={Input}
                    validate={requiredValidation}
                  />
                  <Field
                    label={i18next.t<string>('PROCEDURES_URL_FORM:PERSON_LAST_NAME')}
                    name="lastName"
                    render={Input}
                    validate={requiredValidation}
                  />
                  <Field
                    label={i18next.t<string>('PROCEDURES_URL_FORM:PERSON_LAST_NAME_MOTHER')}
                    name="lastNameMother"
                    parse={(value) => value}
                    render={Input}
                  />
                  <Field
                    label={i18next.t<string>('PROCEDURES_URL_FORM:PERSON_ID_CARD')}
                    name="idCard"
                    render={Input}
                    validate={requiredValidation}
                  />
                  <Field
                    label={i18next.t<string>('PROCEDURES_URL_FORM:PERSON_EMAIL')}
                    name="email"
                    parse={(value) => value}
                    render={Input}
                    type="email"
                    validate={emailValidation}
                  />
                  <Field
                    label={i18next.t<string>('PROCEDURES_URL_FORM:PERSON_PHONE')}
                    name="phone"
                    parse={(value) => value}
                    render={Input}
                    type="number"
                    validate={phoneNumberValidation}
                  />
                  <Field
                    label={i18next.t<string>('PROCEDURES_URL_FORM:PERSON_CIVIL_STATE')}
                    name="civilState"
                    options={civilStates}
                    render={SelectInput}
                    validate={requiredValidation}
                  />
                  <Field
                    label={i18next.t<string>('PROCEDURES_URL_FORM:PERSON_BIRTHDATE')}
                    name="birthdate"
                    render={Input}
                    type="date"
                    validate={requiredValidation}
                  />
                  <Field
                    label={i18next.t<string>('PROCEDURES_URL_FORM:PERSON_ADDRESS')}
                    name="address"
                    render={Input}
                    validate={requiredValidation}
                  />
                  <Field
                    label={i18next.t<string>('PROCEDURES_URL_FORM:PERSON_TRIBE')}
                    name="tribe"
                    options={tribes}
                    render={SelectInput}
                    validate={requiredValidation}
                  />
                  <Field
                    label={i18next.t<string>('PROCEDURES_URL_FORM:PERSON_COUNTRY')}
                    name="country"
                    render={Input}
                    validate={requiredValidation}
                  />
                  <Field
                    label={i18next.t<string>('PROCEDURES_URL_FORM:PERSON_IMAGE')}
                    name="image"
                    preview={files?.image}
                    render={Input}
                    type="file"
                    validate={fileValidation}
                  />
                  <Field
                    label={i18next.t<string>('PROCEDURES_URL_FORM:PERSON_ALIVE')}
                    name="alive"
                    render={Checkbox}
                  />
                  {!values.alive && (
                    <Field
                      label={i18next.t<string>('PROCEDURES_URL_FORM:PERSON_CEMENTERY')}
                      name="cementery"
                      parse={(value) => value}
                      render={Input}
                    />
                  )}
                  <FieldArray name="relations">
                    {({ fields }) =>
                      fields.map((name, i) => (
                        <div key={name}>
                          <Divider>
                            {`${i18next.t<string>('PROCEDURES_URL_FORM:PERSON_RELATION')} ${i + 1}`}
                            <IconButton onClick={() => remove('relations', i)}>
                              <DeleteOutlined />
                            </IconButton>
                          </Divider>
                          <FormRowContainer>
                            <Field
                              label={i18next.t<string>('PROCEDURES_URL_FORM:PERSON_STATE')}
                              name={`${name}.personState`}
                              options={personStates}
                              render={SelectInput}
                              validate={requiredValidation}
                            />
                            <Field
                              label={i18next.t<string>('PROCEDURES_URL_FORM:PERSON')}
                              name={`${name}.person`}
                              render={(customProps) => (
                                <AutoComplete
                                  {...customProps}
                                  apiCall={(search) => getAll(10, 1, search, 'firstName', 'asc')}
                                  apiCallKey="items"
                                  itemLabel={(item: IPerson) =>
                                    `${item.firstName} ${item.lastName} (${item.idCard})`
                                  }
                                  itemValue="id"
                                />
                              )}
                              validate={requiredValidation}
                            />
                          </FormRowContainer>
                        </div>
                      ))
                    }
                  </FieldArray>
                  <FormAddCombinationButton
                    onClick={() => push('relations', relationsField)}
                    variant="outlined"
                  >
                    {i18next.t<string>('PROCEDURES_URL_FORM:PERSON_RELATION_BUTTON')}
                  </FormAddCombinationButton>
                  <Title>{i18next.t<string>('PROCEDURES_URL_FORM:DOCUMENT_TITLE')}</Title>
                  <FieldArray name="documents">
                    {({ fields }) =>
                      fields.map((name, i) => (
                        <div key={name}>
                          <Divider>
                            {`${i18next.t<string>('PROCEDURES_URL_FORM:DOCUMENT')} ${i + 1}`}
                            <IconButton onClick={() => remove('documents', i)}>
                              <DeleteOutlined />
                            </IconButton>
                          </Divider>
                          <Field
                            label={i18next.t<string>('PROCEDURES_URL_FORM:DOCUMENT_NAME')}
                            name={`${name}.name`}
                            render={Input}
                            validate={requiredValidation}
                          />
                          <Field
                            label={i18next.t<string>('PROCEDURES_URL_FORM:DOCUMENT_DATE')}
                            name={`${name}.date`}
                            parse={(value) => value}
                            render={Input}
                            type="date"
                          />
                          <Field
                            label={i18next.t<string>('PROCEDURES_URL_FORM:DOCUMENT_TYPE')}
                            name={`${name}.documentTypeId`}
                            options={documentTypes}
                            render={SelectInput}
                            validate={requiredValidation}
                          />
                          <Field
                            label={i18next.t<string>('PROCEDURES_URL_FORM:DOCUMENT_FILE')}
                            name={`${name}.file`}
                            preview={documents[i]?.file}
                            previewFilename={
                              documents[i] &&
                              getBase64FilenameWithExtension(documents[i].file, documents[i].name)
                            }
                            render={Input}
                            type="file"
                            validate={composeValidators(requiredValidation, fileValidation)}
                          />
                          <FieldArray name={`${name}.persons`}>
                            {({ fields: documentPersonsFields }) =>
                              documentPersonsFields.map((documentPersonsName, j) => (
                                <div key={documentPersonsName}>
                                  <FormRowContainer>
                                    <Field
                                      label={`${i18next.t<string>('PROCEDURES_URL_FORM:PERSON')} ${
                                        j + 1
                                      }`}
                                      name={`${documentPersonsName}.person`}
                                      render={(customProps) => (
                                        <AutoComplete
                                          {...customProps}
                                          apiCall={(search) =>
                                            getAll(10, 1, search, 'firstName', 'asc')
                                          }
                                          apiCallKey="items"
                                          itemLabel={(item: IPerson) =>
                                            `${item.firstName} ${item.lastName} (${item.idCard})`
                                          }
                                          itemValue="id"
                                        />
                                      )}
                                      validate={requiredValidation}
                                    />
                                    <IconButton
                                      onClick={() => remove(`${name}.persons`, j)}
                                      style={{ height: 60 }}
                                    >
                                      <DeleteOutlined />
                                    </IconButton>
                                  </FormRowContainer>
                                </div>
                              ))
                            }
                          </FieldArray>
                          <FormAddCombinationButton
                            onClick={() => push(`${name}.persons`, documentPersonsField)}
                            variant="outlined"
                          >
                            {i18next.t<string>('PROCEDURES_URL_FORM:DOCUMENT_ADD_PERSON')}
                          </FormAddCombinationButton>
                        </div>
                      ))
                    }
                  </FieldArray>
                  <FormAddCombinationButton
                    onClick={() => push('documents', documentsField)}
                    variant="outlined"
                  >
                    {i18next.t<string>('PROCEDURES_URL_FORM:DOCUMENT_BUTTON')}
                  </FormAddCombinationButton>
                </FormFieldset>
                <FormButtonContainer>
                  <FormSubmitButton
                    disabled={!isValidUrlForm || pristine || submitting}
                    type="submit"
                  >
                    {i18next.t<string>('PROCEDURES_URL_FORM:SUBMIT_BUTTON')}
                  </FormSubmitButton>
                </FormButtonContainer>
              </FormContent>
            );
          }}
        />
      </ViewContent>
    </ViewContainer>
  );
};

export default ProceduresUrlForm;
