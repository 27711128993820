import { createSelector } from 'reselect';

import { IPermissionsState } from 'types/permissions.types';

const getItems = (state: { permissions: IPermissionsState }) => state.permissions.items;
const getSelectedItem = (state: { permissions: IPermissionsState }) =>
  state.permissions.selectedItem;

export const permissionsSelector = createSelector([getItems], (permissions) => permissions);

export const permissionsFormSelector = createSelector([getSelectedItem], (role) => ({
  name: role?.name || '',
  guardName: role?.guardName || '',
}));
