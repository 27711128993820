import React, { FunctionComponent } from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Divider, IconButton } from '@mui/material';
import { ArrowBackOutlined, DeleteOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import {
  emailValidation,
  fileValidation,
  phoneNumberValidation,
  requiredValidation,
} from 'helpers/validations';
import { getAll } from 'services/persons.services';
import { history } from 'helpers/history';

import Input from 'components/inputs/Input';
import SelectInput from 'components/inputs/SelectInput';
import Checkbox from 'components/inputs/Checkbox';
import AutoComplete from 'components/inputs/AutoComplete';

import { ViewContainer, ViewContent, TitleContainer, Title, CustomIconButton } from 'styles/view';
import {
  FormContent,
  FormButtonContainer,
  FormSubmitButton,
  FormRowContainer,
  FormAddCombinationButton,
} from 'styles/form';

import { IPerson } from 'types/persons.types';
import { IPersonsFormProps } from './types';
import './i18n';

const relationsField = {
  person: '',
  personState: '',
};

const PersonsForm: FunctionComponent<IPersonsFormProps> = (props: IPersonsFormProps) => {
  const { civilStates, files, initialValues, onSubmit, personStates, tribes } = props;

  return (
    <ViewContainer auth data-testid="persons-form">
      <ViewContent auth>
        <TitleContainer>
          <CustomIconButton onClick={history.goBack}>
            <ArrowBackOutlined />
          </CustomIconButton>
          <Title>{i18next.t<string>('PERSONS_FORM:TITLE')}</Title>
          <div />
        </TitleContainer>
        <Form
          initialValues={initialValues}
          mutators={{ ...arrayMutators }}
          onSubmit={onSubmit}
          render={({
            form: {
              mutators: { push, remove },
            },
            handleSubmit,
            submitting,
            pristine,
            values,
          }) => (
            <FormContent onSubmit={handleSubmit}>
              <Field
                label={i18next.t<string>('PERSONS_FORM:FIRST_NAME')}
                name="firstName"
                render={Input}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('PERSONS_FORM:LAST_NAME')}
                name="lastName"
                render={Input}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('PERSONS_FORM:LAST_NAME_MOTHER')}
                name="lastNameMother"
                parse={(value) => value}
                render={Input}
              />
              <Field
                label={i18next.t<string>('PERSONS_FORM:ID_CARD')}
                name="idCard"
                render={Input}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('PERSONS_FORM:EMAIL')}
                name="email"
                parse={(value) => value}
                render={Input}
                type="email"
                validate={emailValidation}
              />
              <Field
                label={i18next.t<string>('PERSONS_FORM:PHONE')}
                name="phone"
                parse={(value) => value}
                render={Input}
                type="number"
                validate={phoneNumberValidation}
              />
              <Field
                label={i18next.t<string>('PERSONS_FORM:CIVIL_STATE')}
                name="civilState"
                options={civilStates}
                render={SelectInput}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('PERSONS_FORM:BIRTHDATE')}
                name="birthdate"
                render={Input}
                type="date"
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('PERSONS_FORM:ADDRESS')}
                name="address"
                render={Input}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('PERSONS_FORM:TRIBE')}
                name="tribe"
                options={tribes}
                render={SelectInput}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('PERSONS_FORM:COUNTRY')}
                name="country"
                render={Input}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('PERSONS_FORM:IMAGE')}
                name="image"
                preview={files?.image}
                render={Input}
                type="file"
                validate={fileValidation}
              />
              <Field
                label={i18next.t<string>('PERSONS_FORM:COMMENTS')}
                name="comments"
                parse={(value) => value}
                render={Input}
                type="textarea"
              />
              <Field
                label={i18next.t<string>('PERSONS_FORM:ALIVE')}
                name="alive"
                render={Checkbox}
              />
              {!values.alive && (
                <Field
                  label={i18next.t<string>('PERSONS_FORM:CEMENTERY')}
                  name="cementery"
                  parse={(value) => value}
                  render={Input}
                />
              )}
              <FieldArray name="relations">
                {({ fields }) =>
                  fields.map((name, i) => (
                    <div key={name}>
                      <Divider>
                        {`${i18next.t<string>('PERSONS_FORM:PERSON_RELATION')} ${i + 1}`}
                        <IconButton onClick={() => remove('relations', i)}>
                          <DeleteOutlined />
                        </IconButton>
                      </Divider>
                      <FormRowContainer>
                        <Field
                          label={i18next.t<string>('PERSONS_FORM:PERSON_STATE')}
                          name={`${name}.personState`}
                          options={personStates}
                          render={SelectInput}
                          validate={requiredValidation}
                        />
                        <Field
                          label={i18next.t<string>('PERSONS_FORM:PERSON')}
                          name={`${name}.person`}
                          render={(customProps) => (
                            <AutoComplete
                              {...customProps}
                              apiCall={(search) =>
                                getAll(10, 1, search, 'firstName', 'asc', initialValues.id)
                              }
                              apiCallKey="items"
                              itemLabel={(item: IPerson) =>
                                `${item.firstName} ${item.lastName} (${item.idCard})`
                              }
                              itemValue="id"
                            />
                          )}
                          validate={requiredValidation}
                        />
                      </FormRowContainer>
                    </div>
                  ))
                }
              </FieldArray>
              <FormAddCombinationButton
                onClick={() => push('relations', relationsField)}
                variant="outlined"
              >
                {i18next.t<string>('PERSONS_FORM:PERSON_RELATION_BUTTON')}
              </FormAddCombinationButton>
              <FormButtonContainer>
                <FormSubmitButton disabled={pristine || submitting} type="submit">
                  {i18next.t<string>('PERSONS_FORM:SUBMIT_BUTTON')}
                </FormSubmitButton>
              </FormButtonContainer>
            </FormContent>
          )}
        />
      </ViewContent>
    </ViewContainer>
  );
};

export default PersonsForm;
