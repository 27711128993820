import { groupArrayByKey } from 'helpers/utils';
import { createSelector } from 'reselect';

import { IBlocksState } from 'types/blocks.types';
import { IGrave } from 'types/graves.types';

const getItems = (state: { blocks: IBlocksState }) => state.blocks.items;
const getSelectedItem = (state: { blocks: IBlocksState }) => state.blocks.selectedItem;
const getCategoryPricess = (state: { blocks: IBlocksState }) => state.blocks.categoryPrices;

export const blocksSelector = createSelector([getItems], (blocks) => blocks);

export const blocksFormSelector = createSelector([getSelectedItem], (block) => ({
  name: block?.name || '',
  categoryPriceId: block?.categoryPriceId?.toString() || '',
  cementeryId: block?.cementeryId || '',
}));

export const blocksCategoryPricesSelector = createSelector([getCategoryPricess], (categoryPrices) =>
  categoryPrices.map((categoryPrice) => ({
    label: `${categoryPrice.name} ($${categoryPrice.price})`,
    value: categoryPrice.id.toString(),
  })),
);

export const blocksGravesByColumnSelector = createSelector([getSelectedItem], (block) =>
  groupArrayByKey<IGrave>(block?.graves || [], 'column'),
);

export const blocksGravesByRowSelector = createSelector([getSelectedItem], (block) =>
  groupArrayByKey<IGrave>(block?.graves || [], 'row'),
);

export const blocksGravesColumnsSelector = createSelector(
  [blocksGravesByColumnSelector],
  (graves) => Object.keys(graves),
);

export const blocksGravesRowsSelector = createSelector([blocksGravesByRowSelector], (graves) =>
  Object.keys(graves),
);
