import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { onLogin } from 'redux/actions/auth.actions';

import LayoutContainer from 'containers/LayoutContainer';
import LoginView from 'views/LoginView';
import Loading from 'components/commons/Loading';

import { ILocationState } from 'types/router.types';
import { ILoginFormData } from 'views/LoginView/types';

import 'views/LoginView/i18n';

const LoginContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const loading = useTypedSelector((state) => state.auth.loading);
  const location = useLocation<ILocationState>();

  const onSubmit = (formData: ILoginFormData) =>
    dispatch(onLogin(formData, location?.state?.from?.pathname));

  return (
    <LayoutContainer title={i18next.t<string>('LOGIN_VIEW:TITLE')}>
      <LoginView onSubmit={onSubmit} />
      {loading && <Loading isModalMode message={i18next.t<string>('LOGIN_VIEW:LOADING')} />}
    </LayoutContainer>
  );
};

export default LoginContainer;
