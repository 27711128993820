import { createSelector } from 'reselect';

import { IDocumentsTypesState } from 'types/documentsTypes.types';

const getItems = (state: { documentsTypes: IDocumentsTypesState }) => state.documentsTypes.items;
const getSelectedItem = (state: { documentsTypes: IDocumentsTypesState }) =>
  state.documentsTypes.selectedItem;

export const documentsTypesSelector = createSelector(
  [getItems],
  (documentsTypes) => documentsTypes,
);

export const documentsTypesFormSelector = createSelector([getSelectedItem], (documentType) => ({
  name: documentType?.name || '',
}));
