import { Reducer } from 'redux';

import { IProceduresState } from 'types/procedures.types';
import * as authConstants from 'redux/constants/auth.constants';
import * as constants from 'redux/constants/procedures.constants';

export const defaultState: IProceduresState = {
  civilStates: {},
  count: 0,
  documentTypes: [],
  isValidUrlForm: false,
  items: [],
  loading: false,
  personStates: {},
  selectedItem: null,
  states: {},
  table: {
    page: 1,
    limit: 10,
    search: '',
    sortKey: 'name',
    sortType: 'asc',
  },
  tribes: {},
};

const proceduresReducer: Reducer = (state = defaultState, action) => {
  const { data, type } = action;
  switch (type) {
    case constants.PROCEDURES_ON_DELETE_REQUESTED:
    case constants.PROCEDURES_ON_GET_ALL_REQUESTED:
    case constants.PROCEDURES_ON_SAVE_REQUESTED:
    case constants.PROCEDURES_ON_SAVE_URL_FORM_REQUESTED:
    case constants.PROCEDURES_ON_VALIDATE_URL_FORM_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case constants.PROCEDURES_ON_GET_ONE_REQUESTED:
      return {
        ...state,
        loading: !!action.id,
        selectedItem: null,
      };
    case constants.PROCEDURES_ON_GET_ALL_SUCCEEDED:
      return {
        ...state,
        count: data.count,
        items: data.items,
        loading: false,
        table: data.table,
      };
    case constants.PROCEDURES_ON_GET_FORM_SUCCEEDED:
      return {
        ...state,
        loading: false,
        states: data.states,
      };
    case constants.PROCEDURES_ON_GET_ONE_SUCCEEDED:
      return {
        ...state,
        loading: false,
        selectedItem: data.item,
      };
    case constants.PROCEDURES_ON_VALIDATE_URL_FORM_SUCCEEDED:
      return {
        ...state,
        civilStates: data.civilStates,
        documentTypes: data.documentTypes,
        isValidUrlForm: true,
        loading: false,
        personStates: data.personStates,
        selectedItem: data.item,
        tribes: data.tribes,
      };
    case constants.PROCEDURES_ON_SAVE_URL_FORM_SUCCEEDED:
    case constants.PROCEDURES_ON_VALIDATE_URL_FORM_FAILED:
      return {
        ...state,
        civilStates: {},
        documentTypes: [],
        isValidUrlForm: false,
        loading: false,
        personStates: {},
        selectedItem: null,
        tribes: {},
      };
    case constants.PROCEDURES_ON_DELETE_SUCCEEDED:
    case constants.PROCEDURES_ON_SAVE_SUCCEEDED:
    case constants.PROCEDURES_ON_DELETE_FAILED:
    case constants.PROCEDURES_ON_GET_ALL_FAILED:
    case constants.PROCEDURES_ON_SAVE_FAILED:
    case constants.PROCEDURES_ON_SAVE_URL_FORM_FAILED:
      return {
        ...state,
        loading: false,
      };
    case constants.PROCEDURES_ON_GET_FORM_FAILED:
      return {
        ...state,
        loading: false,
        states: {},
      };
    case constants.PROCEDURES_ON_GET_ONE_FAILED:
      return {
        ...state,
        loading: false,
        selectedItem: null,
      };
    case authConstants.AUTH_ON_LOGOUT_SUCCEEDED:
      return defaultState;
    default:
      return state;
  }
};

export default proceduresReducer;
