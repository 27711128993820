import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'DASHBOARD_VIEW', {
  TITLE: 'Dashboard',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'DASHBOARD_VIEW', {
  TITLE: 'Dashboard',
});
