import i18next from 'i18next';

import { ENVIRONMENT } from './configs';

export const locales = {
  EN: 'en-US',
  ES: 'es',
};

const DEFAULT_LOCALE = ENVIRONMENT === 'test' ? locales.EN : locales.ES;

i18next.init({
  initImmediate: false,
  fallbackLng: DEFAULT_LOCALE,
  lng: DEFAULT_LOCALE,
  interpolation: {
    escapeValue: false,
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    format(value, format, lng) {
      if (format === 'uppercase') return value.toUpperCase();
      if (format === 'lowercase') return value.toLowerCase();
      if (format === 'capitalize') return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
      return value;
    },
  },
});
