import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'INPUT', {
  DOWNLOAD_FILE: 'Download file',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'INPUT', {
  DOWNLOAD_FILE: 'Descargar archivo',
});
