import { createSelector } from 'reselect';

import { IDocumentsState } from 'types/documents.types';

const getItems = (state: { documents: IDocumentsState }) => state.documents.items;
const getSelectedItem = (state: { documents: IDocumentsState }) => state.documents.selectedItem;
const getDocumentTypes = (state: { documents: IDocumentsState }) => state.documents.documentTypes;

export const documentsSelector = createSelector([getItems], (documents) => documents);

export const documentsFormSelector = createSelector([getSelectedItem], (document) => ({
  name: document?.name || '',
  date: document?.date || '',
  documentTypeId: document?.documentTypeId?.toString() || '',
  persons:
    document?.persons.map((person) => ({
      person: {
        label: `${person.firstName} ${person.lastName} (${person.idCard})`,
        value: person.id.toString(),
      },
    })) || [],
}));

export const documentsDocumentTypesSelector = createSelector([getDocumentTypes], (documentTypes) =>
  documentTypes.map((documentType) => ({
    label: documentType.name,
    value: documentType.id.toString(),
  })),
);

export const documentsFilesSelector = createSelector([getSelectedItem], (document) => ({
  file: document?.file || '',
}));
