import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { onDelete, onGetAll } from 'redux/actions/blocks.actions';
import { onGetOne as onGetOneCementery } from 'redux/actions/cementeries.actions';
import { blocksSelector } from 'redux/selectors/blocks.selector';
import { history } from 'helpers/history';
import { COLORS } from 'helpers/enums/colors';

import LayoutContainer from 'containers/LayoutContainer';
import BlocksView from 'views/BlocksView';
import ActionButtons from 'components/commons/Table/components/ActionButtons';
import Dialog from 'components/commons/Dialog';

import { IBlock } from 'types/blocks.types';
import { ITableUpdate } from 'components/commons/Table/types';

import 'views/BlocksView/i18n';

const BlocksContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [deleteItem, setDeleteItem] = useState<IBlock | undefined>();
  const { cementeryId = '' } = useParams<{ cementeryId?: string }>();
  const { count, loading, table } = useTypedSelector((state) => state.blocks);
  const cementery = useTypedSelector((state) => state.cementeries.selectedItem);
  const items = useTypedSelector(blocksSelector);

  useEffect(() => {
    if (cementeryId) dispatch(onGetOneCementery(cementeryId));
    return () => {
      dispatch(onGetOneCementery(''));
    };
  }, [dispatch, cementeryId]);

  const actions = [
    {
      label: i18next.t<string>('BLOCKS_VIEW:SHOW'),
      onClick: (id: string) => history.push(`/cementeries/${cementeryId}/blocks/${id}/show`),
    },
    {
      label: i18next.t<string>('BLOCKS_VIEW:EDIT'),
      onClick: (id: string) => history.push(`/cementeries/${cementeryId}/blocks/${id}/edit`),
    },
    {
      label: i18next.t<string>('BLOCKS_VIEW:DELETE'),
      onClick: (id: string) => setDeleteItem(items.find((item: IBlock) => item.id === Number(id))),
      color: COLORS.redMonza,
    },
  ];

  const columns = [
    {
      name: 'name',
      label: i18next.t<string>('BLOCKS_VIEW:NAME'),
    },
    {
      name: 'id',
      label: i18next.t<string>('BLOCKS_VIEW:ACTIONS'),
      options: {
        customBodyRender: (value: string) => <ActionButtons id={value} items={actions} />,
        sort: false,
      },
    },
  ];

  const onTableUpdate = useCallback(
    (data: ITableUpdate) => dispatch(onGetAll(cementeryId, data)),
    [cementeryId, dispatch],
  );

  const onDeleteClick = (isConfirmed = false) => {
    if (isConfirmed && deleteItem) dispatch(onDelete(cementeryId, deleteItem.id.toString()));
    setDeleteItem(undefined);
  };

  return (
    <LayoutContainer auth title={cementery?.name || i18next.t<string>('BLOCKS_VIEW:TITLE')}>
      <BlocksView
        columns={columns}
        count={count}
        data={items}
        loading={loading}
        onAddClick={() => history.push(`/cementeries/${cementeryId}/blocks/create`)}
        onTableUpdate={onTableUpdate}
        table={table}
      />
      <Dialog
        message={i18next.t<string>('BLOCKS_VIEW:DELETE_DIALOG_MESSAGE', {
          name: deleteItem?.name,
        })}
        onCancelClick={() => onDeleteClick()}
        onConfirmClick={() => onDeleteClick(true)}
        open={!!deleteItem}
        title={i18next.t<string>('BLOCKS_VIEW:DELETE_DIALOG_TITLE')}
      />
    </LayoutContainer>
  );
};

export default BlocksContainer;
