import { Middleware } from 'redux';

import * as authConstants from 'redux/constants/auth.constants';
import { removeUserCookies, saveUserCookies } from 'helpers/cookies';

const storageMiddleware: Middleware = () => (next) => (action) => {
  const { data, error, type } = action;
  switch (type) {
    case authConstants.AUTH_ON_LOGIN_SUCCEEDED:
      saveUserCookies(data.token);
      break;
    case authConstants.AUTH_ON_LOGOUT_SUCCEEDED:
    case authConstants.AUTH_ON_INITIALIZE_FAILED:
      removeUserCookies();
      break;
    default:
      if (error?.response?.status === 401) removeUserCookies();
      break;
  }
  return next(action);
};

export default storageMiddleware;
