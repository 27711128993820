import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'PERMISSIONS_VIEW', {
  TITLE: 'Permissions',
  NAME: 'Name',
  GUARD_NAME: 'Guard name',
  ACTIONS: 'Actions',
  EDIT: 'Edit',
  DELETE: 'Delete',
  DELETE_DIALOG_TITLE: 'Delete permission',
  DELETE_DIALOG_MESSAGE: 'Are you sure you want to delete the permission {{ name }}?',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'PERMISSIONS_VIEW', {
  TITLE: 'Permisos',
  NAME: 'Nombre',
  GUARD_NAME: 'Nombre de la guardia',
  ACTIONS: 'Acciones',
  EDIT: 'Editar',
  DELETE: 'Eliminar',
  DELETE_DIALOG_TITLE: 'Eliminar permiso',
  DELETE_DIALOG_MESSAGE: 'Estás seguro de querer eliminar el permiso {{ name }}?',
});
