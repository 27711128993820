import React, { FunctionComponent } from 'react';
import { ArrowBackOutlined, EditOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import { history } from 'helpers/history';

import {
  ViewContainer,
  ViewContent,
  TitleContainer,
  Title,
  TableContainer,
  CustomIconButton,
} from 'styles/view';

import { IDocumentsTypesShowProps } from './types';
import './i18n';

const DocumentsTypesShow: FunctionComponent<IDocumentsTypesShowProps> = (
  props: IDocumentsTypesShowProps,
) => {
  const { item } = props;

  return (
    <ViewContainer auth data-testid="documents-types-show">
      <ViewContent auth>
        <TitleContainer>
          <CustomIconButton onClick={history.goBack}>
            <ArrowBackOutlined />
          </CustomIconButton>
          <Title>{i18next.t<string>('DOCUMENTS_TYPES_SHOW:TITLE')}</Title>
          <CustomIconButton onClick={() => history.push(`/documents/types/${item?.id}/edit`)}>
            <EditOutlined />
          </CustomIconButton>
        </TitleContainer>
        {item && (
          <TableContainer>
            <tbody>
              <tr>
                <td>{i18next.t<string>('DOCUMENTS_TYPES_SHOW:ID')}</td>
                <td>{item?.id}</td>
              </tr>
              <tr>
                <td>{i18next.t<string>('DOCUMENTS_TYPES_SHOW:NAME')}</td>
                <td>{item?.name}</td>
              </tr>
            </tbody>
          </TableContainer>
        )}
      </ViewContent>
    </ViewContainer>
  );
};

export default DocumentsTypesShow;
