import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { API_BASE_URL } from 'configs/configs';
import { camelCaseKeys, snakeCaseKeys } from 'helpers/utils';
import { getCookie, saveUserCookies } from 'helpers/cookies';

export const api: AxiosInstance = axios.create({ baseURL: API_BASE_URL });

api.interceptors.request.use((request) => {
  if (request?.data) request.data = snakeCaseKeys(request.data);
  return request;
});

api.interceptors.response.use(
  (response) => {
    if (response?.data) response.data = camelCaseKeys(response.data);
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;
      return api
        .post('auth/refresh-token', { refreshToken: getCookie('refreshToken') })
        .then(({ data }) => {
          saveUserCookies(data.token);
          const accessToken = getCookie('accessToken');
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axios(originalRequest);
        });
    }
    // eslint-disable-next-line no-param-reassign
    if (error.response?.data) error.response.data = camelCaseKeys(error.response.data);
    return Promise.reject(error);
  },
);

const getOptions = () => {
  const accessToken = getCookie('accessToken');
  const options = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  };
  return options;
};

export const get = (url: string, headers = {}): Promise<AxiosResponse> =>
  api.get(url, { ...getOptions(), ...headers });

export const post = (url: string, params = {}, headers = {}): Promise<AxiosResponse> =>
  api.post(url, params, { ...getOptions(), ...headers });

export const put = (url: string, params = {}, headers = {}): Promise<AxiosResponse> =>
  api.put(url, params, { ...getOptions(), ...headers });

export const remove = (url: string, headers = {}): Promise<AxiosResponse> =>
  api.delete(url, { ...getOptions(), ...headers });
