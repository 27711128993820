import { AnyAction } from 'redux';

import * as constants from 'redux/constants/documents.constants';
import { ITableUpdate } from 'types/table.types';
import { IDocumentsFormData } from 'views/DocumentsView/components/DocumentsForm/types';

export const onDelete = (id: string): AnyAction => ({
  type: constants.DOCUMENTS_ON_DELETE_REQUESTED,
  id,
});

export const onGetAll = (data: ITableUpdate): AnyAction => ({
  type: constants.DOCUMENTS_ON_GET_ALL_REQUESTED,
  ...data,
});

export const onGetForm = (): AnyAction => ({
  type: constants.DOCUMENTS_ON_GET_FORM_REQUESTED,
});

export const onGetOne = (id: string): AnyAction => ({
  type: constants.DOCUMENTS_ON_GET_ONE_REQUESTED,
  id,
});

export const onSave = (formData: IDocumentsFormData, id?: string): AnyAction => ({
  type: constants.DOCUMENTS_ON_SAVE_REQUESTED,
  formData,
  id,
});
