import { AxiosResponse } from 'axios';

import { get, post, put, remove } from 'services/api';
import { ICementeriesFormData } from 'views/CementeriesView/components/CementeriesForm/types';

export const getAll = (
  limit: number,
  page: number,
  search: string,
  sortKey: string,
  sortType: string,
): Promise<AxiosResponse> =>
  get(
    `cementeries?limit=${limit}&page=${page}&search=${search}&sortKey=${sortKey}&sortType=${sortType}`,
  );

export const getOne = (id: string): Promise<AxiosResponse> => get(`cementeries/${id}`);

export const save = (formData: ICementeriesFormData, id: string): Promise<AxiosResponse> =>
  !id ? post('cementeries', formData) : put(`cementeries/${id}`, formData);

export const softDelete = (id: string): Promise<AxiosResponse> => remove(`cementeries/${id}`);
