import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import useTypedSelector from 'hooks/useTypedSelector';
import { isUserLoggedInSelector, userRolesSelector } from 'redux/selectors/auth.selector';
import { getCookie } from 'helpers/cookies';

interface IRouteContainerProps extends RouteProps {
  authenticated?: boolean;
  restricted?: boolean;
  roles?: Array<string>;
}

const RouteContainer: FunctionComponent<IRouteContainerProps> = ({
  component: Component,
  authenticated = false,
  restricted = false,
  roles = [],
  ...rest
}) => {
  const location = useLocation();
  const isUserLoggedIn = useTypedSelector(isUserLoggedInSelector);
  const userRoles = useTypedSelector(userRolesSelector);

  if (!Component) return null;

  if (restricted && (getCookie('accessToken') || isUserLoggedIn)) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  if (authenticated && !getCookie('accessToken') && !isUserLoggedIn) {
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }

  if (
    isUserLoggedIn &&
    roles.length > 0 &&
    !userRoles.some((userRole) => roles.includes(userRole))
  ) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default RouteContainer;
