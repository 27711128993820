import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { onGetOne as onGetOneCementery } from 'redux/actions/cementeries.actions';
import { onGetOne } from 'redux/actions/blocks.actions';
import {
  blocksGravesByRowSelector,
  blocksGravesColumnsSelector,
  blocksGravesRowsSelector,
} from 'redux/selectors/blocks.selector';

import LayoutContainer from 'containers/LayoutContainer';
import BlocksShow from 'views/BlocksView/components/BlocksShow';
import Loading from 'components/commons/Loading';

import 'views/BlocksView/components/BlocksShow/i18n';

const BlocksShowContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { cementeryId = '', id = '' } = useParams<{ cementeryId?: string; id?: string }>();
  const { loading, selectedItem: item } = useTypedSelector((state) => state.blocks);
  const cementery = useTypedSelector((state) => state.cementeries.selectedItem);
  const graves = useTypedSelector(blocksGravesByRowSelector);
  const columns = useTypedSelector(blocksGravesColumnsSelector);
  const rows = useTypedSelector(blocksGravesRowsSelector);

  useEffect(() => {
    if (cementeryId) dispatch(onGetOneCementery(cementeryId));
    return () => {
      dispatch(onGetOneCementery(''));
    };
  }, [dispatch, cementeryId]);

  useEffect(() => {
    if (id) dispatch(onGetOne(cementeryId, id));
    return () => {
      dispatch(onGetOne(cementeryId, ''));
    };
  }, [dispatch, cementeryId, id]);

  return (
    <LayoutContainer auth title={cementery?.name || i18next.t<string>('BLOCKS_SHOW:TITLE')}>
      <BlocksShow
        blockId={id}
        cementeryId={cementeryId}
        columns={columns}
        graves={graves}
        item={item}
        rows={rows}
      />
      {loading && <Loading isModalMode message={i18next.t<string>('BLOCKS_SHOW:LOADING')} />}
    </LayoutContainer>
  );
};

export default BlocksShowContainer;
