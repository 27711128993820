import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

export const GravesContainer = styled.table`
  display: block;
  margin-bottom: 20px;
  overflow: auto;
`;

export const GravesRowContainer = styled.tr`
  font-size: 12px;
`;

export const GraveContainer = styled.td`
  svg {
    fill: ${({ color }) => color || 'none'};
    stroke: ${COLORS.black};
    stroke-width: 2px;
  }
`;
