import React, { FunctionComponent, useEffect } from 'react';
import { DialogContent as DialogMessageContainer } from '@mui/material';
import i18next from 'i18next';

import { IDialogProps } from './types';
import {
  DialogContainer,
  DialogTitleContainer,
  DialogTitle,
  DialogCloseButton,
  DialogMessage,
  DialogButtonsContainer,
  DialogButton,
} from './styles';
import './i18n';

const Dialog: FunctionComponent<IDialogProps> = (props: IDialogProps) => {
  const {
    message,
    onCancelClick,
    onConfirmClick,
    open,
    title = i18next.t<string>('DIALOG:TITLE'),
  } = props;

  useEffect(() => {
    // 27: Escape key
    const handleKeyUp = (event: KeyboardEvent) =>
      event.keyCode === 27 && onCancelClick && onCancelClick();
    window.addEventListener('keyup', handleKeyUp, false);
    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
    };
  });

  return (
    <DialogContainer data-testid="dialog" fullWidth open={open}>
      <DialogTitleContainer>
        <DialogTitle>{title}</DialogTitle>
        {onCancelClick && <DialogCloseButton onClick={onCancelClick} />}
      </DialogTitleContainer>
      <DialogMessageContainer>
        <DialogMessage dangerouslySetInnerHTML={{ __html: message }} />
      </DialogMessageContainer>
      <DialogButtonsContainer>
        {onCancelClick && (
          <DialogButton
            color="primary"
            data-testid="dialog-cancel-button"
            onClick={onCancelClick}
            variant="outlined"
          >
            {i18next.t<string>('DIALOG:CANCEL')}
          </DialogButton>
        )}
        {onConfirmClick && (
          <DialogButton
            color="primary"
            data-testid="dialog-confirm-button"
            onClick={onConfirmClick}
            variant="contained"
          >
            {i18next.t<string>('DIALOG:CONFIRM')}
          </DialogButton>
        )}
      </DialogButtonsContainer>
    </DialogContainer>
  );
};

export default Dialog;
