import { createSelector } from 'reselect';

import { IProceduresState } from 'types/procedures.types';
import { IPerson } from 'types/persons.types';

const getItems = (state: { procedures: IProceduresState }) => state.procedures.items;
const getSelectedItem = (state: { procedures: IProceduresState }) => state.procedures.selectedItem;
const getStates = (state: { procedures: IProceduresState }) => state.procedures.states;
const getCivilStates = (state: { procedures: IProceduresState }) => state.procedures.civilStates;
const getDocumentTypes = (state: { procedures: IProceduresState }) =>
  state.procedures.documentTypes;
const getPersonStates = (state: { procedures: IProceduresState }) => state.procedures.personStates;
const getTribes = (state: { procedures: IProceduresState }) => state.procedures.tribes;

export const proceduresSelector = createSelector([getItems], (procedures) => procedures);

export const proceduresFormSelector = createSelector([getSelectedItem], (procedure) => ({
  name: procedure?.name || '',
  state: procedure?.state || '',
}));

export const proceduresStatesSelector = createSelector([getStates], (states) =>
  Object.keys(states).map((key) => ({
    label: states[key],
    value: key,
  })),
);

export const proceduresCivilStatesSelector = createSelector([getCivilStates], (civilStates) =>
  Object.keys(civilStates).map((key) => ({
    label: civilStates[key],
    value: key,
  })),
);

export const proceduresDocumentTypesSelector = createSelector([getDocumentTypes], (documentTypes) =>
  documentTypes.map((documentType) => ({
    label: documentType.name,
    value: documentType.id.toString(),
  })),
);

export const proceduresPersonStatesSelector = createSelector([getPersonStates], (personStates) =>
  Object.keys(personStates).map((key) => ({
    label: personStates[key],
    value: key,
  })),
);

export const proceduresTribesSelector = createSelector([getTribes], (tribes) =>
  Object.keys(tribes).map((key) => ({
    label: tribes[key],
    value: key,
  })),
);

export const proceduresFormUrlSelector = createSelector([getSelectedItem], (procedure) => ({
  id: procedure?.id,
  firstName: procedure?.person?.firstName || '',
  lastName: procedure?.person?.lastName || '',
  lastNameMother: procedure?.person?.lastNameMother || '',
  idCard: procedure?.person?.idCard || '',
  email: procedure?.person?.email || '',
  phone: procedure?.person?.phone || '',
  civilState: procedure?.person?.civilState || '',
  birthdate: procedure?.person?.birthdate || '',
  address: procedure?.person?.address || '',
  tribe: procedure?.person?.tribe || '',
  country: procedure?.person?.country || '',
  alive: procedure?.person?.alive ?? true,
  cementery: procedure?.person?.cementery || '',
  relations: [
    ...(procedure?.person?.relations1?.map((relation) => ({
      person: {
        label: `${relation.person2?.firstName} ${relation.person2?.lastName} (${relation.person2?.idCard})`,
        value: relation.person2Id.toString(),
      },
      personState: relation.personState,
    })) || []),
  ],
  documents: [
    ...(procedure?.documents?.map((document) => ({
      name: document?.name || '',
      date: document?.date || '',
      documentTypeId: document?.documentTypeId?.toString() || '',
      persons:
        document?.persons
          ?.filter((person) => person.id.toString() !== procedure?.person?.id?.toString())
          ?.map((person) => ({
            person: {
              label: `${person.firstName} ${person.lastName} (${person.idCard})`,
              value: person.id.toString(),
            },
          })) || [],
    })) || []),
  ],
}));

export const proceduresFilesSelector = createSelector([getSelectedItem], (procedure) => ({
  image: procedure?.person?.image || '',
}));

export const proceduresShowSelector = createSelector(
  [getSelectedItem],
  (procedure) =>
    procedure && {
      ...procedure,
      ...(procedure.person && {
        person: {
          ...procedure?.person,
          siblings: procedure.person?.parents
            ?.reduce((a: Array<IPerson>, b: IPerson) => [...a, ...(b?.childs ?? [])], [])
            .filter(({ id }) => procedure.person && id !== procedure.person.id),
          grandParents: procedure.person?.parents?.reduce(
            (a: Array<IPerson>, b: IPerson) => [...a, ...(b?.parents ?? [])],
            [],
          ),
        },
      }),
    },
);
