import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'AUTO_COMPLETE', {
  NO_OPTIONS: 'No options',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'AUTO_COMPLETE', {
  NO_OPTIONS: 'No se encontraron opciones',
});
