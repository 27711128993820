import { AnyAction } from 'redux';

import * as constants from 'redux/constants/procedures.constants';
import { ITableUpdate } from 'types/table.types';
import { IProceduresFormData } from 'views/ProceduresView/components/ProceduresForm/types';
import { IProceduresUrlFormData } from 'views/ProceduresView/components/ProceduresUrlForm/types';

export const onDelete = (id: string): AnyAction => ({
  type: constants.PROCEDURES_ON_DELETE_REQUESTED,
  id,
});

export const onGetAll = (data: ITableUpdate): AnyAction => ({
  type: constants.PROCEDURES_ON_GET_ALL_REQUESTED,
  ...data,
});

export const onGetForm = (): AnyAction => ({
  type: constants.PROCEDURES_ON_GET_FORM_REQUESTED,
});

export const onGetOne = (id: string): AnyAction => ({
  type: constants.PROCEDURES_ON_GET_ONE_REQUESTED,
  id,
});

export const onSave = (formData: IProceduresFormData, id?: string): AnyAction => ({
  type: constants.PROCEDURES_ON_SAVE_REQUESTED,
  formData,
  id,
});

export const onSaveUrlForm = (
  id: string,
  expires: string,
  signature: string,
  formData: IProceduresUrlFormData,
): AnyAction => ({
  type: constants.PROCEDURES_ON_SAVE_URL_FORM_REQUESTED,
  id,
  expires,
  signature,
  formData,
});

export const onValidateUrlForm = (id: string, expires: string, signature: string): AnyAction => ({
  type: constants.PROCEDURES_ON_VALIDATE_URL_FORM_REQUESTED,
  id,
  expires,
  signature,
});
