import React, { ChangeEvent, FunctionComponent } from 'react';
import { FormControlLabel, Checkbox as MUICheckbox } from '@mui/material';

import { FormGroup, ErrorBlock } from 'styles/input';

import { ICheckboxProps } from './types';

const Checkbox: FunctionComponent<ICheckboxProps> = (props: ICheckboxProps) => {
  const {
    disabled = false,
    input,
    className = '',
    label = '',
    labelPlacement = 'start',
    meta: { touched, error },
    size = 'medium',
  } = props;
  const { name, onChange, value } = input;
  const hasError = !!touched && !!error;

  return (
    <FormGroup className={className}>
      <FormControlLabel
        control={
          <MUICheckbox
            {...input}
            checked={!!value}
            data-testid="custom-checkbox"
            disabled={disabled}
            id={name}
            inputProps={{ 'aria-label': name }}
            name={name}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)}
            size={size}
            value={value}
          />
        }
        label={label}
        labelPlacement={labelPlacement}
      />
      {hasError && <ErrorBlock data-testid="error-block">{error}</ErrorBlock>}
    </FormGroup>
  );
};

export default Checkbox;
