import { Reducer } from 'redux';

import { IAppState } from 'types/app.types';
import * as constants from 'redux/constants/app.constants';
import { SIZES } from 'helpers/enums/sizes';

export const defaultState: IAppState = {
  isMenuOpened: window.innerWidth >= SIZES.bigTablet,
};

const appReducer: Reducer = (state = defaultState, action) => {
  switch (action.type) {
    case constants.APP_ON_TOGGLE_MENU_REQUESTED:
      return {
        ...state,
        isMenuOpened: action.isMenuOpened,
      };
    default:
      return state;
  }
};

export default appReducer;
