import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import * as constants from 'redux/constants/graves.constants';
import { getForm, getOne, save, softDelete } from 'services/graves.services';

export function* gravesDelete(action: AnyAction): Generator {
  try {
    const { cementeryId, blockId, id }: any = action;
    const { data }: any = yield call(softDelete, cementeryId, blockId, id);
    yield put({
      type: constants.GRAVES_ON_DELETE_SUCCEEDED,
      data: { ...data, cementeryId, blockId, id },
    });
  } catch (error) {
    yield put({ type: constants.GRAVES_ON_DELETE_FAILED, error });
  }
}

export function* gravesGetForm(action: AnyAction): Generator {
  try {
    const { cementeryId, blockId }: any = action;
    const { data }: any = yield call(getForm, cementeryId, blockId);
    yield put({ type: constants.GRAVES_ON_GET_FORM_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.GRAVES_ON_GET_FORM_FAILED, error });
  }
}

export function* gravesGetOne(action: AnyAction): Generator {
  try {
    const { cementeryId, blockId, id }: any = action;
    if (id) {
      const { data }: any = yield call(getOne, cementeryId, blockId, id);
      yield put({
        type: constants.GRAVES_ON_GET_ONE_SUCCEEDED,
        data: { ...data, cementeryId, blockId },
      });
    }
  } catch (error) {
    yield put({ type: constants.GRAVES_ON_GET_ONE_FAILED, error });
  }
}

export function* gravesSave(action: AnyAction): Generator {
  try {
    const { cementeryId, blockId, formData, id } = action;
    const { data }: any = yield call(save, cementeryId, blockId, formData, id);
    yield put({
      type: constants.GRAVES_ON_SAVE_SUCCEEDED,
      data: { ...data, cementeryId, blockId },
    });
  } catch (error) {
    yield put({ type: constants.GRAVES_ON_SAVE_FAILED, error });
  }
}

export function* watchGraves(): Generator {
  yield all([
    takeLatest(constants.GRAVES_ON_DELETE_REQUESTED, gravesDelete),
    takeLatest(constants.GRAVES_ON_GET_FORM_REQUESTED, gravesGetForm),
    takeLatest(constants.GRAVES_ON_GET_ONE_REQUESTED, gravesGetOne),
    takeLatest(constants.GRAVES_ON_SAVE_REQUESTED, gravesSave),
  ]);
}
