import { createSelector } from 'reselect';

import { ICementeriesState } from 'types/cementeries.types';

const getItems = (state: { cementeries: ICementeriesState }) => state.cementeries.items;
const getSelectedItem = (state: { cementeries: ICementeriesState }) =>
  state.cementeries.selectedItem;

export const cementeriesSelector = createSelector([getItems], (cementeries) => cementeries);

export const cementeriesFormSelector = createSelector([getSelectedItem], (cementery) => ({
  name: cementery?.name || '',
  blocks: '',
}));
