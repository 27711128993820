import React, { FunctionComponent } from 'react';
import { Form, Field } from 'react-final-form';
import { ArrowBackOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import { history } from 'helpers/history';
import { requiredArrayValidation, requiredValidation } from 'helpers/validations';

import Input from 'components/inputs/Input';
import SelectInput from 'components/inputs/SelectInput';

import { ViewContainer, ViewContent, TitleContainer, Title, CustomIconButton } from 'styles/view';
import { FormContent, FormButtonContainer, FormSubmitButton } from 'styles/form';

import { IRolesFormProps } from './types';
import './i18n';

const RolesForm: FunctionComponent<IRolesFormProps> = (props: IRolesFormProps) => {
  const { initialValues, onSubmit, permissions } = props;

  return (
    <ViewContainer auth data-testid="roles-form">
      <ViewContent auth>
        <TitleContainer>
          <CustomIconButton onClick={history.goBack}>
            <ArrowBackOutlined />
          </CustomIconButton>
          <Title>{i18next.t<string>('ROLES_FORM:TITLE')}</Title>
          <div />
        </TitleContainer>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, pristine }) => (
            <FormContent onSubmit={handleSubmit}>
              <Field
                label={i18next.t<string>('ROLES_FORM:NAME')}
                name="name"
                render={Input}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('ROLES_FORM:GUARD_NAME')}
                name="guardName"
                render={Input}
                validate={requiredValidation}
              />
              <Field
                label={i18next.t<string>('ROLES_FORM:PERMISSIONS')}
                multiple
                name="permissions"
                options={permissions}
                render={SelectInput}
                validate={requiredArrayValidation}
              />
              <FormButtonContainer>
                <FormSubmitButton disabled={pristine || submitting} type="submit">
                  {i18next.t<string>('ROLES_FORM:SUBMIT_BUTTON')}
                </FormSubmitButton>
              </FormButtonContainer>
            </FormContent>
          )}
        />
      </ViewContent>
    </ViewContainer>
  );
};

export default RolesForm;
