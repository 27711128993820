import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'PROCEDURES_SHOW', {
  TITLE: 'Procedures',
  NAME: 'Name',
  STATE: 'State',
  PERSON_TITLE: 'Person information',
  PERSON_FIRST_NAME: 'First name',
  PERSON_LAST_NAME: 'Last name',
  PERSON_LAST_NAME_MOTHER: "Mother's last name",
  PERSON_ID_CARD: 'ID card',
  PERSON_EMAIL: 'Email',
  PERSON_PHONE: 'Phone',
  PERSON_CIVIL_STATE: 'Civil state',
  PERSON_BIRTHDATE: 'Birthdate',
  PERSON_ADDRESS: 'Address',
  PERSON_TRIBE: 'Tribe',
  PERSON_COUNTRY: 'Country',
  PERSON_IMAGE: 'Image',
  PERSON_COMMENTS: 'Comments',
  PERSON_ALIVE: 'Is alive?',
  PERSON_CEMENTERY: 'Cementery',
  YES: 'Yes',
  NO: 'No',
  PERSONS_RELATIONS: 'Personal relations',
  PERSON: 'Person',
  PERSON_STATE: 'Person state',
  PERSON_PARENTS: 'Parents',
  PERSON_CHILDS: 'Childs',
  PERSON_SIBLINGS: 'Siblings',
  PERSON_GRAND_PARENTS: 'Grand parents',
  DOCUMENT_TITLE: 'Document information',
  DOCUMENTS: 'Documents',
  DOCUMENT_NAME: 'Name',
  DOCUMENT_DATE: 'Date',
  DOCUMENT_TYPE: 'Document type',
  ACTION: 'Action',
  EDIT_DOCUMENT: 'Edit document',
  LOADING: 'Loading...',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'PROCEDURES_SHOW', {
  TITLE: 'Trámites',
  NAME: 'Nombre',
  STATE: 'Estado',
  PERSON_TITLE: 'Información de la persona',
  PERSON_FIRST_NAME: 'Nombre',
  PERSON_LAST_NAME: 'Apellido',
  PERSON_LAST_NAME_MOTHER: 'Apellido materno',
  PERSON_ID_CARD: 'DNI',
  PERSON_EMAIL: 'Email',
  PERSON_PHONE: 'Celular',
  PERSON_CIVIL_STATE: 'Estado civil',
  PERSON_BIRTHDATE: 'Fecha de nacimiento',
  PERSON_ADDRESS: 'Domicilio',
  PERSON_TRIBE: 'Tribu',
  PERSON_COUNTRY: 'País',
  PERSON_IMAGE: 'Imagen',
  PERSON_COMMENTS: 'Observaciones',
  PERSON_ALIVE: 'Está vivo?',
  PERSON_CEMENTERY: 'Cementerio',
  YES: 'Si',
  NO: 'No',
  PERSONS_RELATIONS: 'Relaciones personales',
  PERSON: 'Persona',
  PERSON_STATE: 'Estado de la persona',
  PERSON_PARENTS: 'Padre/Madre',
  PERSON_CHILDS: 'Hijos/as',
  PERSON_SIBLINGS: 'Hermanos/as',
  PERSON_GRAND_PARENTS: 'Abuelos/as',
  DOCUMENT_TITLE: 'Información de los documentos',
  DOCUMENTS: 'Documentos',
  DOCUMENT_NAME: 'Nombre',
  DOCUMENT_DATE: 'Fecha',
  DOCUMENT_TYPE: 'Tipo de documento',
  ACTION: 'Acción',
  EDIT_DOCUMENT: 'Editar documento',
  LOADING: 'Cargando...',
});
