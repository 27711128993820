import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'LOGIN_FORM', {
  EMAIL: 'Email',
  PASSWORD: 'Password',
  SUBMIT_BUTTON: 'Log in',
  FORGOT_PASSWORD: 'Forgot password?',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'LOGIN_FORM', {
  EMAIL: 'Email',
  PASSWORD: 'Contraseña',
  SUBMIT_BUTTON: 'Iniciar sesión',
  FORGOT_PASSWORD: 'Olvidaste tu contraseña?',
});
