import i18next from 'i18next';
import { locales } from 'configs/i18n';

/* ------------------------------------- English ------------------------------------- */

i18next.addResources(locales.EN, 'CATEGORY_PRICES_FORM', {
  TITLE: 'Category prices',
  NAME: 'Name',
  PRICE: 'Price',
  SUBMIT_BUTTON: 'Save',
  LOADING: 'Loading...',
});

/* ------------------------------------- Spanish ------------------------------------- */

i18next.addResources(locales.ES, 'CATEGORY_PRICES_FORM', {
  TITLE: 'Precios de categoría',
  NAME: 'Nombre',
  PRICE: 'Precio',
  SUBMIT_BUTTON: 'Guardar',
  LOADING: 'Cargando...',
});
