import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import { useQuery } from 'hooks/useQuery';
import { history } from 'helpers/history';

import LayoutContainer from 'containers/LayoutContainer';
import CheckImg from 'views/ProceduresView/components/ProceduresUrlFormSuccess/assets/check.png';

import { ViewContainer } from 'styles/view';

import { SuccessTitle, SuccessImage, SuccessButton } from './styles';

import './i18n';

const ProceduresUrlFormSuccess: FunctionComponent = () => {
  const { id = '' } = useParams<{ id?: string }>();
  const query = useQuery();

  return (
    <LayoutContainer title={i18next.t<string>('PROCEDURE_URL_FORM_SUCCESS_VIEW:TITLE')}>
      <ViewContainer data-testid="procedure-url-form-success-view">
        <SuccessImage src={CheckImg} alt="check" />
        <SuccessTitle>{i18next.t<string>('PROCEDURE_URL_FORM_SUCCESS_VIEW:TITLE')}</SuccessTitle>
        <SuccessButton
          color="success"
          onClick={() =>
            history.push(
              `/procedures/url/${id}?expires=${query.get('expires')}&signature=${query.get(
                'signature',
              )}`,
            )
          }
        >
          {i18next.t<string>('PROCEDURE_URL_FORM_SUCCESS_VIEW:BUTTON')}
        </SuccessButton>
      </ViewContainer>
    </LayoutContainer>
  );
};

export default ProceduresUrlFormSuccess;
