import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { COLORS } from 'helpers/enums/colors';

export const LogoLink = styled(Link)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${COLORS.blueRibbon};
    font-size: 20px;

    span {
      margin-left: 10px;
      font-weight: 700;
    }
  }
`;

export const LogoImage = styled.img`
  height: 35px;
`;
