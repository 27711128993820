import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import * as constants from 'redux/constants/cementeries.constants';
import { getAll, getOne, save, softDelete } from 'services/cementeries.services';

export function* cementeriesDelete(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    const { data }: any = yield call(softDelete, id);
    yield put({
      type: constants.CEMENTERIES_ON_DELETE_SUCCEEDED,
      data: { ...data, id },
    });
  } catch (error) {
    yield put({ type: constants.CEMENTERIES_ON_DELETE_FAILED, error });
  }
}

export function* cementeriesGetAll(action: AnyAction): Generator {
  try {
    const { limit, page, search, sortKey, sortType } = action;
    const { data }: any = yield call(getAll, limit, page, search, sortKey, sortType);
    yield put({ type: constants.CEMENTERIES_ON_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.CEMENTERIES_ON_GET_ALL_FAILED, error });
  }
}

export function* cementeriesGetOne(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    if (id) {
      const { data }: any = yield call(getOne, id);
      yield put({ type: constants.CEMENTERIES_ON_GET_ONE_SUCCEEDED, data });
    }
  } catch (error) {
    yield put({ type: constants.CEMENTERIES_ON_GET_ONE_FAILED, error });
  }
}

export function* cementeriesSave(action: AnyAction): Generator {
  try {
    const { formData, id } = action;
    const { data }: any = yield call(save, formData, id);
    yield put({ type: constants.CEMENTERIES_ON_SAVE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.CEMENTERIES_ON_SAVE_FAILED, error });
  }
}

export function* cementeriesRefresh(): Generator {
  const table: any = yield select((state) => state.cementeries.table);
  yield put({ type: constants.CEMENTERIES_ON_GET_ALL_REQUESTED, ...table });
}

export function* watchCementeries(): Generator {
  yield all([
    takeLatest(constants.CEMENTERIES_ON_DELETE_REQUESTED, cementeriesDelete),
    takeLatest(constants.CEMENTERIES_ON_GET_ALL_REQUESTED, cementeriesGetAll),
    takeLatest(constants.CEMENTERIES_ON_GET_ONE_REQUESTED, cementeriesGetOne),
    takeLatest(constants.CEMENTERIES_ON_SAVE_REQUESTED, cementeriesSave),
    takeLatest(
      [constants.CEMENTERIES_ON_DELETE_SUCCEEDED, constants.CEMENTERIES_ON_SAVE_SUCCEEDED],
      cementeriesRefresh,
    ),
  ]);
}
