import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { PersonOutlined, LogoutOutlined } from '@mui/icons-material';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { onToggleMenu } from 'redux/actions/app.actions';
import { onLogout } from 'redux/actions/auth.actions';
import { userSelector } from 'redux/selectors/auth.selector';
import { history } from 'helpers/history';

import Navbar from 'components/main/Navbar';

import 'components/main/Navbar/i18n';

export interface INavbarContainerProps {
  title: string;
}

const NavbarContainer: FunctionComponent<INavbarContainerProps> = (
  props: INavbarContainerProps,
) => {
  const { title } = props;
  const dispatch = useDispatch();
  const user = useTypedSelector(userSelector);
  const initials = user ? `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}` : '';

  const onOpenMenu = () => dispatch(onToggleMenu(true));

  const items = [
    {
      Icon: PersonOutlined,
      key: 'edit-profile',
      label: i18next.t<string>('NAVBAR:EDIT_PROFILE'),
      onClick: () => history.push(`/users/${user?.id}/edit`),
    },
    {
      Icon: LogoutOutlined,
      key: 'log-out',
      label: i18next.t<string>('NAVBAR:LOG_OUT'),
      onClick: () => dispatch(onLogout()),
    },
  ];

  return <Navbar initials={initials} items={items} onOpenMenu={onOpenMenu} title={title} />;
};

export default NavbarContainer;
