import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import * as constants from 'redux/constants/procedures.constants';
import {
  getAll,
  getForm,
  getOne,
  save,
  saveUrlForm,
  softDelete,
  validateUrlForm,
} from 'services/procedures.services';

export function* proceduresDelete(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    const { data }: any = yield call(softDelete, id);
    yield put({
      type: constants.PROCEDURES_ON_DELETE_SUCCEEDED,
      data: { ...data, id },
    });
  } catch (error) {
    yield put({ type: constants.PROCEDURES_ON_DELETE_FAILED, error });
  }
}

export function* proceduresGetAll(action: AnyAction): Generator {
  try {
    const { limit, page, search, sortKey, sortType } = action;
    const { data }: any = yield call(getAll, limit, page, search, sortKey, sortType);
    yield put({ type: constants.PROCEDURES_ON_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.PROCEDURES_ON_GET_ALL_FAILED, error });
  }
}

export function* proceduresGetForm(): Generator {
  try {
    const { data }: any = yield call(getForm);
    yield put({ type: constants.PROCEDURES_ON_GET_FORM_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.PROCEDURES_ON_GET_FORM_FAILED, error });
  }
}

export function* proceduresGetOne(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    if (id) {
      const { data }: any = yield call(getOne, id);
      yield put({ type: constants.PROCEDURES_ON_GET_ONE_SUCCEEDED, data });
    }
  } catch (error) {
    yield put({ type: constants.PROCEDURES_ON_GET_ONE_FAILED, error });
  }
}

export function* proceduresSave(action: AnyAction): Generator {
  try {
    const { formData, id } = action;
    const { data }: any = yield call(save, formData, id);
    yield put({ type: constants.PROCEDURES_ON_SAVE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.PROCEDURES_ON_SAVE_FAILED, error });
  }
}

export function* proceduresSaveUrlForm(action: AnyAction): Generator {
  try {
    const { id, expires, signature, formData } = action;
    const { data }: any = yield call(saveUrlForm, id, expires, signature, formData);
    yield put({
      type: constants.PROCEDURES_ON_SAVE_URL_FORM_SUCCEEDED,
      data: { ...data, procedureId: id, expires, signature },
    });
  } catch (error) {
    yield put({ type: constants.PROCEDURES_ON_SAVE_URL_FORM_FAILED, error });
  }
}

export function* proceduresValidateUrlForm(action: AnyAction): Generator {
  try {
    const { id, expires, signature } = action;
    const { data }: any = yield call(validateUrlForm, id, expires, signature);
    yield put({ type: constants.PROCEDURES_ON_VALIDATE_URL_FORM_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.PROCEDURES_ON_VALIDATE_URL_FORM_FAILED, error });
  }
}

export function* proceduresRefresh(): Generator {
  const table: any = yield select((state) => state.procedures.table);
  yield put({ type: constants.PROCEDURES_ON_GET_ALL_REQUESTED, ...table });
}

export function* watchProcedures(): Generator {
  yield all([
    takeLatest(constants.PROCEDURES_ON_DELETE_REQUESTED, proceduresDelete),
    takeLatest(constants.PROCEDURES_ON_GET_ALL_REQUESTED, proceduresGetAll),
    takeLatest(constants.PROCEDURES_ON_GET_FORM_REQUESTED, proceduresGetForm),
    takeLatest(constants.PROCEDURES_ON_GET_ONE_REQUESTED, proceduresGetOne),
    takeLatest(constants.PROCEDURES_ON_SAVE_REQUESTED, proceduresSave),
    takeLatest(constants.PROCEDURES_ON_SAVE_URL_FORM_REQUESTED, proceduresSaveUrlForm),
    takeLatest(constants.PROCEDURES_ON_VALIDATE_URL_FORM_REQUESTED, proceduresValidateUrlForm),
    takeLatest(
      [constants.PROCEDURES_ON_DELETE_SUCCEEDED, constants.PROCEDURES_ON_SAVE_SUCCEEDED],
      proceduresRefresh,
    ),
  ]);
}
