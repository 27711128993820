import { AnyAction } from 'redux';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as appConstants from 'redux/constants/app.constants';
import * as constants from 'redux/constants/auth.constants';
import { initialize, login, logout } from 'services/auth.services';
import { getCookie } from 'helpers/cookies';

export function* authInitialize(): Generator {
  try {
    if (getCookie('accessToken')) {
      const { data }: any = yield call(initialize);
      yield put({ type: constants.AUTH_ON_INITIALIZE_SUCCEEDED, data });
    }
  } catch (error) {
    yield put({ type: constants.AUTH_ON_INITIALIZE_FAILED, error });
  }
}

export function* authLogin(action: AnyAction): Generator {
  try {
    const { formData, pathname } = action;
    const { data }: any = yield call(login, formData);
    yield put({ type: constants.AUTH_ON_LOGIN_SUCCEEDED, data, pathname });
  } catch (error) {
    yield put({ type: constants.AUTH_ON_LOGIN_FAILED, error });
  }
}

export function* authLogout(): Generator {
  try {
    yield call(logout);
    yield put({ type: constants.AUTH_ON_LOGOUT_SUCCEEDED });
  } catch (error) {
    yield put({ type: constants.AUTH_ON_LOGOUT_FAILED, error });
  }
}

export function* watchAuth(): Generator {
  yield all([
    takeLatest(appConstants.APP_ON_INITIALIZE_REQUESTED, authInitialize),
    takeLatest(constants.AUTH_ON_LOGIN_REQUESTED, authLogin),
    takeLatest(constants.AUTH_ON_LOGOUT_REQUESTED, authLogout),
  ]);
}
