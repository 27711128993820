import React, { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { onDelete, onGetAll } from 'redux/actions/procedures.actions';
import { proceduresSelector } from 'redux/selectors/procedures.selector';
import { history } from 'helpers/history';
import { COLORS } from 'helpers/enums/colors';
import { showSuccessNotification } from 'helpers/notifications';

import LayoutContainer from 'containers/LayoutContainer';
import ProceduresView from 'views/ProceduresView';
import ActionButtons from 'components/commons/Table/components/ActionButtons';
import Dialog from 'components/commons/Dialog';

import { IProcedure } from 'types/procedures.types';
import { ITableUpdate } from 'components/commons/Table/types';

import 'views/ProceduresView/i18n';

const ProceduresContainer: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [deleteItem, setDeleteItem] = useState<IProcedure | undefined>();
  const { count, loading, table } = useTypedSelector((state) => state.procedures);
  const items = useTypedSelector(proceduresSelector);

  const actions = [
    {
      label: i18next.t<string>('PROCEDURES_VIEW:SHOW'),
      onClick: (id: string) => history.push(`/procedures/${id}/show`),
    },
    {
      label: i18next.t<string>('PROCEDURES_VIEW:EDIT'),
      onClick: (id: string) => history.push(`/procedures/${id}/edit`),
    },
    {
      label: i18next.t<string>('PROCEDURES_VIEW:DELETE'),
      onClick: (id: string) =>
        setDeleteItem(items.find((item: IProcedure) => item.id === Number(id))),
      color: COLORS.redMonza,
    },
  ];

  const columns = [
    {
      name: 'name',
      label: i18next.t<string>('PROCEDURES_VIEW:NAME'),
    },
    {
      name: 'stateName',
      label: i18next.t<string>('PROCEDURES_VIEW:STATE'),
    },
    {
      name: 'url',
      label: i18next.t<string>('PROCEDURES_VIEW:URL'),
      options: {
        customBodyRender: (value: string) => {
          const onClick = () => {
            navigator.clipboard.writeText(value);
            showSuccessNotification('Link copiado con éxito');
          };
          return (
            <span
              onClick={onClick}
              role="presentation"
              style={{ color: COLORS.blueRibbon, cursor: 'pointer' }}
            >
              {i18next.t<string>('PROCEDURES_VIEW:COPY_URL')}
            </span>
          );
        },
        sort: false,
      },
    },
    {
      name: 'id',
      label: i18next.t<string>('PROCEDURES_VIEW:ACTIONS'),
      options: {
        customBodyRender: (value: string) => <ActionButtons id={value} items={actions} />,
        sort: false,
      },
    },
  ];

  const onTableUpdate = useCallback((data: ITableUpdate) => dispatch(onGetAll(data)), [dispatch]);

  const onDeleteClick = (isConfirmed = false) => {
    if (isConfirmed && deleteItem) dispatch(onDelete(deleteItem.id.toString()));
    setDeleteItem(undefined);
  };

  return (
    <LayoutContainer auth title={i18next.t<string>('PROCEDURES_VIEW:TITLE')}>
      <ProceduresView
        columns={columns}
        count={count}
        data={items}
        loading={loading}
        onAddClick={() => history.push('/procedures/create')}
        onTableUpdate={onTableUpdate}
        table={table}
      />
      <Dialog
        message={i18next.t<string>('PROCEDURES_VIEW:DELETE_DIALOG_MESSAGE', {
          name: deleteItem?.name,
        })}
        onCancelClick={() => onDeleteClick()}
        onConfirmClick={() => onDeleteClick(true)}
        open={!!deleteItem}
        title={i18next.t<string>('PROCEDURES_VIEW:DELETE_DIALOG_TITLE')}
      />
    </LayoutContainer>
  );
};

export default ProceduresContainer;
