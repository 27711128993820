import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'redux';

import * as constants from 'redux/constants/categoryPrices.constants';
import { getAll, getOne, save, softDelete } from 'services/categoryPrices.services';

export function* categoryPricesDelete(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    const { data }: any = yield call(softDelete, id);
    yield put({
      type: constants.CATEGORY_PRICES_ON_DELETE_SUCCEEDED,
      data: { ...data, id },
    });
  } catch (error) {
    yield put({ type: constants.CATEGORY_PRICES_ON_DELETE_FAILED, error });
  }
}

export function* categoryPricesGetAll(action: AnyAction): Generator {
  try {
    const { limit, page, search, sortKey, sortType } = action;
    const { data }: any = yield call(getAll, limit, page, search, sortKey, sortType);
    yield put({ type: constants.CATEGORY_PRICES_ON_GET_ALL_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.CATEGORY_PRICES_ON_GET_ALL_FAILED, error });
  }
}

export function* categoryPricesGetOne(action: AnyAction): Generator {
  try {
    const { id }: any = action;
    if (id) {
      const { data }: any = yield call(getOne, id);
      yield put({ type: constants.CATEGORY_PRICES_ON_GET_ONE_SUCCEEDED, data });
    }
  } catch (error) {
    yield put({ type: constants.CATEGORY_PRICES_ON_GET_ONE_FAILED, error });
  }
}

export function* categoryPricesSave(action: AnyAction): Generator {
  try {
    const { formData, id } = action;
    const { data }: any = yield call(save, formData, id);
    yield put({ type: constants.CATEGORY_PRICES_ON_SAVE_SUCCEEDED, data });
  } catch (error) {
    yield put({ type: constants.CATEGORY_PRICES_ON_SAVE_FAILED, error });
  }
}

export function* categoryPricesRefresh(): Generator {
  const table: any = yield select((state) => state.categoryPrices.table);
  yield put({ type: constants.CATEGORY_PRICES_ON_GET_ALL_REQUESTED, ...table });
}

export function* watchCategoryPrices(): Generator {
  yield all([
    takeLatest(constants.CATEGORY_PRICES_ON_DELETE_REQUESTED, categoryPricesDelete),
    takeLatest(constants.CATEGORY_PRICES_ON_GET_ALL_REQUESTED, categoryPricesGetAll),
    takeLatest(constants.CATEGORY_PRICES_ON_GET_ONE_REQUESTED, categoryPricesGetOne),
    takeLatest(constants.CATEGORY_PRICES_ON_SAVE_REQUESTED, categoryPricesSave),
    takeLatest(
      [constants.CATEGORY_PRICES_ON_DELETE_SUCCEEDED, constants.CATEGORY_PRICES_ON_SAVE_SUCCEEDED],
      categoryPricesRefresh,
    ),
  ]);
}
