import { AxiosResponse } from 'axios';

import { get, post, put, remove } from 'services/api';
import { IDocumentsTypesFormData } from 'views/DocumentsTypesView/components/DocumentsTypesForm/types';

export const getAll = (
  limit: number,
  page: number,
  search: string,
  sortKey: string,
  sortType: string,
): Promise<AxiosResponse> =>
  get(
    `documents-types?limit=${limit}&page=${page}&search=${search}&sortKey=${sortKey}&sortType=${sortType}`,
  );

export const getOne = (id: string): Promise<AxiosResponse> => get(`documents-types/${id}`);

export const save = (formData: IDocumentsTypesFormData, id: string): Promise<AxiosResponse> =>
  !id ? post('documents-types', formData) : put(`documents-types/${id}`, formData);

export const softDelete = (id: string): Promise<AxiosResponse> => remove(`documents-types/${id}`);
