export const PROCEDURES_ON_DELETE_REQUESTED = 'PROCEDURES_ON_DELETE_REQUESTED';
export const PROCEDURES_ON_DELETE_SUCCEEDED = 'PROCEDURES_ON_DELETE_SUCCEEDED';
export const PROCEDURES_ON_DELETE_FAILED = 'PROCEDURES_ON_DELETE_FAILED';

export const PROCEDURES_ON_GET_ALL_REQUESTED = 'PROCEDURES_ON_GET_ALL_REQUESTED';
export const PROCEDURES_ON_GET_ALL_SUCCEEDED = 'PROCEDURES_ON_GET_ALL_SUCCEEDED';
export const PROCEDURES_ON_GET_ALL_FAILED = 'PROCEDURES_ON_GET_ALL_FAILED';

export const PROCEDURES_ON_GET_FORM_REQUESTED = 'PROCEDURES_ON_GET_FORM_REQUESTED';
export const PROCEDURES_ON_GET_FORM_SUCCEEDED = 'PROCEDURES_ON_GET_FORM_SUCCEEDED';
export const PROCEDURES_ON_GET_FORM_FAILED = 'PROCEDURES_ON_GET_FORM_FAILED';

export const PROCEDURES_ON_GET_ONE_REQUESTED = 'PROCEDURES_ON_GET_ONE_REQUESTED';
export const PROCEDURES_ON_GET_ONE_SUCCEEDED = 'PROCEDURES_ON_GET_ONE_SUCCEEDED';
export const PROCEDURES_ON_GET_ONE_FAILED = 'PROCEDURES_ON_GET_ONE_FAILED';

export const PROCEDURES_ON_SAVE_REQUESTED = 'PROCEDURES_ON_SAVE_REQUESTED';
export const PROCEDURES_ON_SAVE_SUCCEEDED = 'PROCEDURES_ON_SAVE_SUCCEEDED';
export const PROCEDURES_ON_SAVE_FAILED = 'PROCEDURES_ON_SAVE_FAILED';

export const PROCEDURES_ON_SAVE_URL_FORM_REQUESTED = 'PROCEDURES_ON_SAVE_URL_FORM_REQUESTED';
export const PROCEDURES_ON_SAVE_URL_FORM_SUCCEEDED = 'PROCEDURES_ON_SAVE_URL_FORM_SUCCEEDED';
export const PROCEDURES_ON_SAVE_URL_FORM_FAILED = 'PROCEDURES_ON_SAVE_URL_FORM_FAILED';

export const PROCEDURES_ON_VALIDATE_URL_FORM_REQUESTED =
  'PROCEDURES_ON_VALIDATE_URL_FORM_REQUESTED';
export const PROCEDURES_ON_VALIDATE_URL_FORM_SUCCEEDED =
  'PROCEDURES_ON_VALIDATE_URL_FORM_SUCCEEDED';
export const PROCEDURES_ON_VALIDATE_URL_FORM_FAILED = 'PROCEDURES_ON_VALIDATE_URL_FORM_FAILED';
