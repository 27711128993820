import { FieldValidator } from 'final-form';
import i18next from 'i18next';

import {
  EMAIL_REGEX,
  FILE_TYPES_ARRAY,
  PASSWORD_REGEX,
  PASSWORD_MINIMUM_LENGTH,
  PHONE_NUMBER_REGEX,
} from './constants';
import './i18n';

interface IAllValues {
  email?: string;
  name?: string;
  password?: string;
  passwordConfirmation?: string;
  time?: string;
}

export const composeValidators = (
  ...validators: FieldValidator<string>[]
): FieldValidator<string> | undefined => {
  return (value: string, allValues: IAllValues) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined);
};

export const requiredValidation = (value: string): string | undefined => {
  return value ? undefined : i18next.t<string>('VALIDATIONS:REQUIRED_FIELD');
};

export const requiredArrayValidation = (value: string): string | undefined => {
  return value?.length ? undefined : i18next.t<string>('VALIDATIONS:REQUIRED_FIELD');
};

export const dateValidation = (value: string, allValues?: IAllValues): string | undefined => {
  if (!value) return undefined;

  const timeString = allValues?.time || '12:00';
  const [time, modifier] = timeString.split(' ');
  const isPM = modifier === 'PM';
  const formattedTimeString = time
    .split(':')
    .map((ts, idx) => (isPM && !idx ? ts + 12 : ts))
    .filter((ts) => ts)
    .join(':');

  const dateTime = new Date(`${value}T${formattedTimeString}`).getTime();
  const nowTime = new Date().getTime();
  const isInvalid = dateTime <= nowTime;

  return isInvalid ? i18next.t<string>('VALIDATIONS:INVALID_DATE') : undefined;
};

export const emailValidation = (value: string): string | undefined => {
  return !!value && !EMAIL_REGEX.test(value)
    ? i18next.t<string>('VALIDATIONS:INVALID_FIELD', {
        field: i18next.t<string>('FIELDS:EMAIL').toLocaleLowerCase(),
      })
    : undefined;
};

export const emailListValidation = (value: string): string | undefined => {
  if (value) {
    const emailArray = value.split(',');
    const isAnyEmailInvalid = emailArray.some((email) => !!emailValidation(email.trim()));
    return isAnyEmailInvalid
      ? i18next.t<string>('VALIDATIONS:INVALID_FIELD', {
          field: i18next.t<string>('FIELDS:EMAIL').toLocaleLowerCase(),
        })
      : undefined;
  }
  return undefined;
};

export const fileValidation = (value: string | File): string | undefined => {
  if (!value || typeof value === 'string') return undefined;

  return FILE_TYPES_ARRAY.includes(value.type)
    ? undefined
    : i18next.t<string>('VALIDATIONS:INVALID_FILE_TYPE');
};

export const passwordValidation = (value: string): string | undefined => {
  if (!value) return undefined;

  if (value.length < PASSWORD_MINIMUM_LENGTH) {
    return i18next.t<string>('VALIDATIONS:INVALID_FIELD_LENGTH', {
      field: i18next.t<string>('FIELDS:PASSWORD'),
      length: PASSWORD_MINIMUM_LENGTH,
    });
  }

  return !PASSWORD_REGEX.test(value)
    ? i18next.t<string>('VALIDATIONS:INVALID_FIELD_SPECIAL_CHARACTERS', {
        field: i18next.t<string>('FIELDS:PASSWORD'),
      })
    : undefined;
};

export const passwordConfirmationValidation = (
  value: string,
  allValues?: IAllValues,
): string | undefined => {
  if (value !== allValues?.password) {
    return i18next.t<string>('VALIDATIONS:FIELD_NOT_MATCH', {
      field: i18next.t<string>('FIELDS:PASSWORD'),
    });
  }
  return passwordValidation(value);
};

export const phoneNumberValidation = (value: string): string | undefined => {
  return !!value && !PHONE_NUMBER_REGEX.test(value)
    ? i18next.t<string>('VALIDATIONS:INVALID_FIELD', {
        field: i18next.t<string>('FIELDS:PHONE_NUMBER').toLocaleLowerCase(),
      })
    : undefined;
};
