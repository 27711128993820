import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import useTypedSelector from 'hooks/useTypedSelector';
import { IDocumentsFormData } from 'views/DocumentsView/components/DocumentsForm/types';
import { onDelete, onGetForm, onGetOne, onSave } from 'redux/actions/documents.actions';
import {
  documentsFormSelector,
  documentsDocumentTypesSelector,
  documentsFilesSelector,
} from 'redux/selectors/documents.selector';

import LayoutContainer from 'containers/LayoutContainer';
import DocumentsForm from 'views/DocumentsView/components/DocumentsForm';
import Dialog from 'components/commons/Dialog';
import Loading from 'components/commons/Loading';

import 'views/DocumentsView/components/DocumentsForm/i18n';

const DocumentsFormContainer: FunctionComponent = () => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { id = '' } = useParams<{ id?: string }>();
  const initialValues = useTypedSelector(documentsFormSelector);
  const { loading, selectedItem: item } = useTypedSelector((state) => state.documents);
  const documentTypes = useTypedSelector(documentsDocumentTypesSelector);
  const files = useTypedSelector(documentsFilesSelector);
  const isEditing = !!item?.id;

  useEffect(() => {
    dispatch(onGetForm());
    if (id) dispatch(onGetOne(id));
    return () => {
      dispatch(onGetOne(''));
    };
  }, [dispatch, id]);

  const onSubmit = (formData: IDocumentsFormData) => dispatch(onSave(formData, id));

  const onDeleteClick = (isConfirmed = false) => {
    if (isConfirmed && isEditing) dispatch(onDelete(id));
    setShowDeleteModal(false);
  };

  return (
    <LayoutContainer auth title={i18next.t<string>('DOCUMENTS_FORM:TITLE')}>
      <DocumentsForm
        documentTypes={documentTypes}
        files={files}
        initialValues={initialValues}
        isEditing={isEditing}
        onDelete={() => setShowDeleteModal(true)}
        onSubmit={onSubmit}
      />
      <Dialog
        message={i18next.t<string>('DOCUMENTS_FORM:DELETE_DIALOG_MESSAGE', {
          name: item?.name,
        })}
        onCancelClick={() => onDeleteClick()}
        onConfirmClick={() => onDeleteClick(true)}
        open={showDeleteModal}
        title={i18next.t<string>('DOCUMENTS_FORM:DELETE_DIALOG_TITLE')}
      />
      {loading && <Loading isModalMode message={i18next.t<string>('DOCUMENTS_FORM:LOADING')} />}
    </LayoutContainer>
  );
};

export default DocumentsFormContainer;
