import styled from 'styled-components';
import { FormControl, TextField } from '@mui/material';

import { COLORS } from 'helpers/enums/colors';

export const CustomFormControl = styled(FormControl)`
  && {
    width: 100%;
    height: fit-content;
    margin-bottom: 0px !important;

    label {
      display: flex;
      font-size: 16px;

      span {
        display: none;
      }
    }

    .MuiFormLabel-root.Mui-focused {
      color: ${COLORS.black};
    }

    .MuiInputBase-input {
      font-size: 16px;
      color: ${COLORS.black};
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${COLORS.black};
    }

    .MuiInput-underline:after {
      border-bottom-color: ${COLORS.black};
    }

    .MuiInputBase-input.Mui-disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;

export const CustomInput = styled(TextField)`
  && {
    width: 100%;
    height: 100%;
    margin-bottom: 0px !important;

    label {
      font-family: 'Montserrat', Helvetica, Arial, serif;
      font-size: 18px;
      font-weight: 700;
      left: 10px;
      padding-right: 50px;
    }

    .MuiFormLabel-root.Mui-focused,
    .MuiFormLabel-filled {
      color: ${({ error }) => (error ? COLORS.redPersian : COLORS.grayLynch)};
      font-size: 12px;
      font-weight: 700;
    }

    .MuiInputBase-input {
      padding: 18px 20px 8px !important;
      color: ${COLORS.grayCloudBurst};
      font-family: 'Montserrat', Helvetica, Arial, serif;
      font-size: 18px;
      font-weight: 700;
      border-radius: 6px;

      &:-webkit-autofill,
      &:-webkit-autofill:active,
      &:-webkit-autofill:hover,
      &:-webkit-autofill::first-line,
      &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px white inset !important;
        background-color: ${COLORS.white} !important;
        background-clip: content-box !important;
      }
    }

    .MuiFilledInput-root {
      padding: 0 !important;
      background-color: ${COLORS.white};
      border: 2px solid ${({ error }) => (error ? COLORS.redPersian : COLORS.grayCadetBlue)};
      border-radius: 6px;
      height: 100%;

      :before,
      :after {
        content: none;
      }
    }

    .MuiInputBase-input.Mui-disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;
