import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import { ViewContainer, ViewContent, Title } from 'styles/view';

import './i18n';

const DashboardView: FunctionComponent = () => (
  <ViewContainer auth data-testid="dashboard-view">
    <ViewContent auth>
      <Title>{i18next.t<string>('DASHBOARD_VIEW:TITLE')}</Title>
    </ViewContent>
  </ViewContainer>
);

export default DashboardView;
