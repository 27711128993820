import React, { FunctionComponent } from 'react';
import i18next from 'i18next';

import Table from 'components/commons/Table';

import { ViewContainer, ViewContent } from 'styles/view';

import { ICategoryPricesViewProps } from './types';

import './i18n';

const CategoryPricesView: FunctionComponent<ICategoryPricesViewProps> = (
  props: ICategoryPricesViewProps,
) => {
  const { columns, count, data, loading, onAddClick, onTableUpdate, table } = props;

  return (
    <ViewContainer auth data-testid="category-prices-view">
      <ViewContent auth>
        <Table
          columns={columns}
          count={count}
          data={data}
          loading={loading}
          onAddClick={onAddClick}
          onTableUpdate={onTableUpdate}
          title={i18next.t<string>('CATEGORY_PRICES_VIEW:TITLE')}
          table={table}
        />
      </ViewContent>
    </ViewContainer>
  );
};

export default CategoryPricesView;
