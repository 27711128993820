export const CEMENTERIES_ON_DELETE_REQUESTED = 'CEMENTERIES_ON_DELETE_REQUESTED';
export const CEMENTERIES_ON_DELETE_SUCCEEDED = 'CEMENTERIES_ON_DELETE_SUCCEEDED';
export const CEMENTERIES_ON_DELETE_FAILED = 'CEMENTERIES_ON_DELETE_FAILED';

export const CEMENTERIES_ON_GET_ALL_REQUESTED = 'CEMENTERIES_ON_GET_ALL_REQUESTED';
export const CEMENTERIES_ON_GET_ALL_SUCCEEDED = 'CEMENTERIES_ON_GET_ALL_SUCCEEDED';
export const CEMENTERIES_ON_GET_ALL_FAILED = 'CEMENTERIES_ON_GET_ALL_FAILED';

export const CEMENTERIES_ON_GET_ONE_REQUESTED = 'CEMENTERIES_ON_GET_ONE_REQUESTED';
export const CEMENTERIES_ON_GET_ONE_SUCCEEDED = 'CEMENTERIES_ON_GET_ONE_SUCCEEDED';
export const CEMENTERIES_ON_GET_ONE_FAILED = 'CEMENTERIES_ON_GET_ONE_FAILED';

export const CEMENTERIES_ON_SAVE_REQUESTED = 'CEMENTERIES_ON_SAVE_REQUESTED';
export const CEMENTERIES_ON_SAVE_SUCCEEDED = 'CEMENTERIES_ON_SAVE_SUCCEEDED';
export const CEMENTERIES_ON_SAVE_FAILED = 'CEMENTERIES_ON_SAVE_FAILED';
