import { AxiosResponse } from 'axios';

import { get, post, put, remove } from 'services/api';
import { IRolesFormData } from 'views/RolesView/components/RolesForm/types';

export const getAll = (
  limit: number,
  page: number,
  search: string,
  sortKey: string,
  sortType: string,
): Promise<AxiosResponse> =>
  get(`roles?limit=${limit}&page=${page}&search=${search}&sortKey=${sortKey}&sortType=${sortType}`);

export const getForm = (): Promise<AxiosResponse> => get('roles/form');

export const getOne = (id: string): Promise<AxiosResponse> => get(`roles/${id}`);

export const save = (formData: IRolesFormData, id: string): Promise<AxiosResponse> =>
  !id ? post('roles', formData) : put(`roles/${id}`, formData);

export const softDelete = (id: string): Promise<AxiosResponse> => remove(`roles/${id}`);
